import React, { useEffect, useState, useMemo, useRef } from 'react';
import styles from "./wordGeneratorRegion.module.less";
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import WordGeneratorRegionContent from '@/base/ElementData/WordGeneratorRegionContent';
import { Button, message } from 'antd';
import LLMCallRequestData from '@/base/LLMCallRequestData';
import { requestLLMCall, requestLLMCallV2 } from '@/services/requestLLMCall';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { Slider } from 'antd';

interface ProbabilityItem {
    token: string;
    prob: number;
}

const WordGeneratorRegion: React.FC<IntrinsicElementProps<WordGeneratorRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
}) => {
    const { userInfoStore } = useStores();
    const [inputText, setInputText] = useState(elementData.content.defaultText);
    const [temperature, setTemperature] = useState(elementData.content.temperature);
    const [isFetching, setIsFetching] = useState(false);
    const [probabilities, setProbabilities] = useState<ProbabilityItem[]>([]);
    const [isGenerated, setIsGenerated] = useState(false);

    useEffect(() => {
        setInputText(elementData.content.defaultText);
    }, [elementData.content.defaultText])

    const handleGenerate = async () => {
        if (inputText.trim() === '') {
            message.error('请输入内容');
            return;
        }
        try {
            const requestParams = LLMCallRequestData.genLLMCallParams(
                inputText,
                userInfoStore.userInfoData.id,
                userInfoStore.userInfoData.name,
                false,
                [],
                "用户会给你一小段文字，请你预测最有可能的下一个字。请只输出最有可能的的下一个字，不要输出别的",
                "",
                "Deepseek",
                "deepseek-chat",
                temperature,
                true,
                5,
            )
            setIsFetching(true);
            setIsGenerated(true);
            const res: any = await requestLLMCallV2(requestParams, 0);
            setProbabilities(res.logprobs_results)
            setIsFetching(false);
        } catch (error) {
            console.error('API Error:', error);
            setIsFetching(false);
            setIsGenerated(false);
        }
    };

    // 归一化概率计算
    const normalizedProbabilities = useMemo(() => {
        if (!probabilities || probabilities.length === 0) return [];

        return probabilities.map((item, index) => ({
            ...item,
            normalizedProb: Math.exp(item.prob) // / sumExps
        }));
    }, [probabilities]);

    const handleClear = () => {
        setInputText('');
        setProbabilities([]);
        setIsGenerated(false);
    }

    const handleSelectToken = (token: string) => {
        setInputText(prevText => prevText + token);
        setProbabilities([]);
    };

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.wordGeneratorBox}>
                    <div className={styles.inputBox}>
                        <div className={styles.inputArea}>
                            {!isGenerated && (
                                <textarea
                                    className={styles.input}
                                    placeholder="请输入内容"
                                    value={inputText}
                                    onChange={e => setInputText(e.target.value)}
                                />
                            )}
                            {isGenerated && (
                                <div className={styles.generatedText}>
                                    {inputText}<span className={styles.blinker}>_____</span>
                                </div>
                            )}
                        </div>
                        {/* <div className={styles.temperatureBox}>
                            <span className={styles.temperatureTitle}>
                                temperature:
                            </span>
                            <Slider
                                min={0}
                                max={2}
                                step={0.1}
                                value={temperature}
                                onChange={setTemperature}
                                className={styles.temperatureSlider}
                            />
                            <span className={styles.temperatureValue}>
                                {temperature.toFixed(1)}
                            </span>
                        </div> */}
                        <div className={styles.btnBox}>
                            <Button
                                className={styles.btn}
                                onClick={handleClear}
                            >
                                清空
                            </Button>
                            <Button
                                className={`${styles.btn}${probabilities.length === 0 && inputText.trim() !== "" ? ` ${styles.btnActive}` : ''}`}
                                loading={isFetching}
                                onClick={handleGenerate}
                            >
                                预测下一个词
                            </Button>
                        </div>
                    </div>
                    <div className={styles.probabilityBox}>
                        <div className={styles.probabilityTitle}>
                            预测结果
                        </div>
                        <div className={styles.resultBox}>
                            {normalizedProbabilities.length > 0 && (
                                <div className={styles.probabilityItem}>
                                    <span className={styles.wordTitle}>
                                        下一个可能的token
                                    </span>
                                    <div className={styles.probabilityBarTitle}>
                                    </div>
                                    <span className={styles.probabilityValueTitle}>
                                        概率
                                    </span>
                                </div>
                            )}
                            {normalizedProbabilities.map((item, index) => (
                                <div key={index} className={styles.probabilityItem}>
                                    {
                                        elementData.content.type === 'basic' && (
                                            <span className={styles.word}>
                                                {item.token}
                                            </span>
                                        )
                                    }
                                    {elementData.content.type === 'selectable' && (
                                        <span
                                            className={styles.wordSelectable}
                                            onClick={() => handleSelectToken(item.token)}
                                        >
                                            {item.token}
                                        </span>
                                    )}
                                    <div className={styles.probabilityBar}>
                                        <div
                                            style={{ width: `${item.normalizedProb * 100}%` }}
                                            className={styles.barFill}
                                        />
                                    </div>
                                    <span className={styles.probabilityValue}>
                                        {/* 显示百分比并优化显示格式 */}
                                        {item.normalizedProb >= 0.01
                                            ? `${(item.normalizedProb * 100).toFixed(0)}%`  // 转换为百分比并移除小数  
                                            : `${(item.normalizedProb * 100).toFixed(2)}%`}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default observer(WordGeneratorRegion);
