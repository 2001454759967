import { useState } from 'react';
import { InputNumber, Button, Select, Checkbox, Radio, Switch } from 'antd';
import styles from './btnTextGroupModifier.module.less';
import ButtonRegionModifier from '../ButtonRegionModifier/ButtonRegionModifier';
import MediaFileSelector from '@/components/MediaFileSelector/MediaFileSelector';
import TextToSpeech from '@/components/TextToSpeech/TextToSpeech';
import { ElementData } from '@/base/ElementData/ElementData';
import BtnTextGroupRegionContent from '@/base/ElementData/BtnTextGroupRegionContent';
import ButtonRegionContent from '@/base/ElementData/ButtonRegionContent';
import { simplifyFileName } from '@/utils/utils';
import useCommonFunction from '@/hooks/useCommonFunction';
import { cloneDeep } from 'lodash';
import UploadMedia from '@/components/UploadMedia/UploadMedia';

type ElementIdOption = { value: any, label: any };

interface BtnTextGroupModifierProps {
    focusedItem: ElementData<BtnTextGroupRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    onAddOption: (id: number, num: number) => void;
    changeZIndex: (id: number, type: string) => void;
    containerRef: any;
    onSaveAsTemplate: (itemId: number, url: string) => void;
}

const BtnTextGroupModifier: React.FC<BtnTextGroupModifierProps> = ({
    focusedItem,
    onInputChange,
    onAddOption,
    changeZIndex,
    containerRef,
    onSaveAsTemplate,
}) => {
    const { Option } = Select;
    const [fileSelectorOpenCorrect, setFileSelectorOpenCorrect] = useState(false);
    const [fileSelectorOpenWrong, setFileSelectorOpenWrong] = useState(false);
    // 设置状态来存储列和按钮个数的值
    const [col, setCol] = useState(focusedItem.content.col || 1); // 初始值为 focusedItem.content.col
    const [btnNum, setBtnNum] = useState(focusedItem.content.btnNum || 1); // 初始值为 focusedItem.content.btn_num
    let oldBtnWidth = (focusedItem.content.width - (10 * (focusedItem.content.col - 1))) / focusedItem.content.col;
    let oldBtnHeight = (focusedItem.content.height - (10 * (focusedItem.content.row - 1))) / focusedItem.content.row;
    const { generateSelectOptionsData, generateElementIdOptions } = useCommonFunction();
    const optionsData = generateSelectOptionsData();
    const elementIdOptions: ElementIdOption[] = generateElementIdOptions();

    const handleColChange = (value: number | null) => {
        if (value !== null) {
            setCol(value);
        }
    };

    const handleBtnNumChange = (value: number | null) => {
        if (value !== null) {
            setBtnNum(value);
        }
    }

    const changeFuncName = (value: string) => {
        const newFunc = { ...focusedItem.content.postFunctionCall, funcName: value };
        onInputChange(focusedItem.id, 'postFunctionCall', newFunc);
    }

    const updateDoneCallFuncParams = (key: string, value: any) => {
        const newDoneCallFunc = cloneDeep(focusedItem?.content?.postFunctionCall || null);
        if (newDoneCallFunc !== null) {
            const funcParams = newDoneCallFunc.funcParams || {};
            const newFuncParams = { ...funcParams, [key]: value };
            newDoneCallFunc.funcParams = newFuncParams;
        }
        onInputChange(focusedItem.id, 'postFunctionCall', newDoneCallFunc);

    }

    // 获取行和列的值
    const handleSubmit = () => {
        //row是通过按钮个数和列数计算得到的
        let row = Math.ceil(btnNum / col);
        onInputChange(focusedItem.id, 'row', row);
        onInputChange(focusedItem.id, 'col', col);
        onInputChange(focusedItem.id, 'btnNum', btnNum);
        onAddOption(focusedItem.id, btnNum);
        let newWidth = oldBtnWidth * col + 10 * (col - 1);
        let newHeight = oldBtnHeight * row + 10 * (row - 1);
        onInputChange(focusedItem.id, 'width', newWidth);
        onInputChange(focusedItem.id, 'height', newHeight);
    }

    // 保存为模板
    const successCallBack = (url: string, itemId: number) => {
        onSaveAsTemplate!(itemId, url);

    };

    const propsUpload = {
        itemId: focusedItem.id,
    };
    return (
        <div>
            <UploadMedia
                successCallBack={successCallBack}
                icon={null}
                btn_text="存为模板 (先上传Thumbnail)"
                accept_str="image/png,image/jpg,image/jpeg,image/gif,image/svg"
                dir="images"
                isMultiple={false}
                uploadProps={propsUpload}
            />
            <h4>位置坐标</h4>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div>
                <label>单选还是多选</label>
                <Radio.Group
                    value={focusedItem.content.selectionType}
                    onChange={e => onInputChange(focusedItem.id, 'selectionType', e.target.value)}
                >
                    <Radio value='single'>单选</Radio>
                    <Radio value='multiple'>多选</Radio>
                </Radio.Group>
            </div>
            <div className={styles.modifyItem}>
                <span>正确答案Eval文本</span>
                <textarea
                    style={{ height: '200px' }}
                    value={focusedItem.content.evalTextCorrect || ''}
                    onChange={e => onInputChange(focusedItem.id, 'evalTextCorrect', e.target.value)}
                    className={styles.textareaLarge} />
            </div>
            <div className={styles.modifyItem}>
                <TextToSpeech
                    text={focusedItem.content.evalTextCorrect || ''}
                    btnText='生成正确答案音频'
                    successCallback={url => onInputChange(focusedItem.id, 'evalTextCorrectAudio', url)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>正确答案音频</div>
                <div className={styles.audioUrlBox}>
                    {focusedItem.content.evalTextCorrectAudio ? focusedItem.content.evalTextCorrectAudio : '暂无'}
                </div>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>正确答案音效</div>
                <Button type='primary' onClick={() => setFileSelectorOpenCorrect(true)}>选择音效</Button>
                <MediaFileSelector
                    mediaType="audios"
                    afterSelectionCallback={url => onInputChange(focusedItem.id, 'correctAnswerSoundUrl', url)}
                    fileSelectorOpen={fileSelectorOpenCorrect}
                    setFileSelectorOpen={setFileSelectorOpenCorrect}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>正确音效文件名</div>
                <div className={styles.fileName}>
                    {simplifyFileName(focusedItem.content.correctAnswerSoundUrl)}
                </div>
            </div>
            <br />
            <div className={styles.modifyItem}>
                <span>错误答案Eval文本</span>
                <textarea
                    style={{ height: '200px' }}
                    value={focusedItem.content.evalTextWrong || ''}
                    onChange={e => onInputChange(focusedItem.id, 'evalTextWrong', e.target.value)}
                    className={styles.textareaLarge} />
            </div>
            <div className={styles.modifyItem}>
                <TextToSpeech
                    text={focusedItem.content.evalTextWrong || ''}
                    btnText='生成错误答案音频'
                    successCallback={url => onInputChange(focusedItem.id, 'evalTextWrongAudio', url)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>错误答案音频</div>
                <div className={styles.audioUrlBox}>
                    {focusedItem.content.evalTextWrongAudio ? focusedItem.content.evalTextWrongAudio : '暂无'}
                </div>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>错误答案音效</div>
                <Button type='primary' onClick={() => setFileSelectorOpenWrong(true)}>选择音效</Button>
                <MediaFileSelector
                    mediaType="audios"
                    afterSelectionCallback={url => onInputChange(focusedItem.id, 'wrongAnswerSoundUrl', url)}
                    fileSelectorOpen={fileSelectorOpenWrong}
                    setFileSelectorOpen={setFileSelectorOpenWrong}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>错误音效文件名</div>
                <div className={styles.fileName}>
                    {simplifyFileName(focusedItem.content.wrongAnswerSoundUrl)}
                </div>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否自动播放评价文本</div>
                <Switch
                    checked={focusedItem.content.autoplayEvalText}
                    onChange={value => onInputChange(focusedItem.id, 'autoplayEvalText', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>Eval文本颜色</div>
                <input
                    type='color'
                    value={focusedItem.content.evalTextColor}
                    onChange={e => onInputChange(focusedItem.id, 'evalTextColor', e.target.value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>Eval文本字体粗细</div>
                <Select
                    value={focusedItem.content.evalTextFontWeight}
                    onChange={value => onInputChange(focusedItem.id, 'evalTextFontWeight', value)}
                >
                    <Option value={400}>400</Option>
                    <Option value={500}>500</Option>
                    <Option value={600}>600</Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>答对奖励的金币数量</div>
                <InputNumber
                    value={focusedItem.content.rewardedCoinsNum}
                    onChange={value => onInputChange(focusedItem.id, 'rewardedCoinsNum', value)}
                    className={styles.showSpeedWordInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>每页最大字数</div>
                <InputNumber
                    value={focusedItem.content.characterPerPage}
                    onChange={value => onInputChange(focusedItem.id, 'characterPerPage', value)}
                    className={styles.showSpeedWordInput}
                />
            </div>
            <br />
            <div>
                <label>是否展示输入框</label>
                <Checkbox
                    checked={focusedItem.content.inputable}
                    onChange={e => {
                        onInputChange(focusedItem.id, 'inputable', e.target.checked);
                        if (e.target.checked) {
                            onInputChange(focusedItem.id, 'correctAnswer', []);
                        }
                    }}
                />
            </div>
            <div>
                <label>是否展示提交按钮</label>
                <Checkbox
                    checked={focusedItem.content.showSubmitBtn}
                    onChange={e => onInputChange(focusedItem.id, 'showSubmitBtn', e.target.checked)}
                />
            </div>
            <div>
                <label>element是否展示</label>
                <Switch
                    checked={focusedItem.content.visible}
                    onChange={value => onInputChange(focusedItem.id, 'visible', value)}
                />
            </div>
            <div>
                <div>
                    <label>请输入列数</label>
                    <InputNumber
                        defaultValue={col}
                        // onBlur={handleSubmit}
                        onChange={handleColChange}
                        className={styles.colInput}
                    />
                </div>
                <div>
                    <label>按钮个数</label>
                    <InputNumber
                        defaultValue={btnNum}
                        // onBlur={handleSubmit}
                        onChange={handleBtnNumChange}
                        className={styles.btnNumInput}
                    />
                    <Button type='primary' onClick={handleSubmit}>确定</Button>
                </div>
            </div>
            <div>
                <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
            </div>
            <div>
                <label>宽度</label>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
            </div>
            <div>
                <label>高度</label>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div>
                <label>按钮组</label>
                <hr />
                <div>
                    {focusedItem.content.buttons.map((btn, index) => (
                        <div key={btn.btnObj!.id}>
                            <label>按钮{index + 1}</label>
                            <br></br>
                            <ButtonRegionModifier
                                key={index}
                                focusedItem={btn.btnObj! as ElementData<ButtonRegionContent>}
                                onInputChange={onInputChange}
                                changeZIndex={changeZIndex}
                                containerRef={containerRef}
                                useAsChild={true}
                            />
                            {
                                !focusedItem.content.inputable && (
                                    <div>
                                        <label>正确答案是否包含这一项</label>
                                        {/* 根据elementData的content的correct_answer数组，里面存储的是正确选项的下标*/}
                                        <Checkbox
                                            checked={focusedItem.content.correctAnswer.indexOf(index) !== -1}
                                            // 如果选中，就把当前选项的下标加入到correct_answer数组中,否则就删除,使用onInputChange方法
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    onInputChange(focusedItem.id, 'correctAnswer', [...focusedItem.content.correctAnswer, index]);
                                                } else {
                                                    onInputChange(focusedItem.id, 'correctAnswer', focusedItem.content.correctAnswer.filter(item => item !== index));
                                                }
                                            }
                                            }
                                        />
                                    </div>
                                )
                            }
                            <hr />
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.verticalLayout}>
                <label>Btn Group lecture var</label>
                <textarea
                    value={focusedItem.content.lectureVar || ''}
                    onChange={e => onInputChange(focusedItem.id, 'lectureVar', e.target.value)}
                    className={styles.textareaVar} />
            </div>
            <div className={styles.modifyItem}>
                <span>Post Prompt</span>
                <textarea
                    style={{ height: '200px' }}
                    value={focusedItem.content.postPrompt || ''}
                    onChange={e => onInputChange(focusedItem.id, 'postPrompt', e.target.value)}
                    className={styles.textareaLarge} />
            </div>
            <div>
                <label>绑定函数</label>
                <Select
                    value={focusedItem.content.postFunctionCall?.funcName}
                    onChange={value => changeFuncName(value)}
                    className={styles.functionSelect}
                >
                    {optionsData.map(option => (
                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                    ))}
                </Select>
            </div>
            {(focusedItem.content.postFunctionCall?.funcName === 'showSpecifiedElement' ||
                focusedItem.content.postFunctionCall?.funcName === 'showSpecifiedElementAndHide' ||
                focusedItem.content.postFunctionCall?.funcName === 'changeTeacherWords') && (
                    <div className={styles.modifyItemCol}>
                        <div className={styles.modifyItemTitle}>elementId</div>
                        <Select
                            value={focusedItem.content.postFunctionCall?.funcParams?.elementId}
                            onChange={value => updateDoneCallFuncParams('elementId', value)}
                            className={styles.functionSelect}
                        >
                            {elementIdOptions.map(option => (
                                <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                            ))}
                        </Select>
                    </div>
                )}
            {focusedItem.content.postFunctionCall?.funcName === 'changeTeacherWords' && (
                <>
                    <div className={styles.modifyItemCol}>
                        <div className={styles.modifyItemTitle}>参数:new words</div>
                        <textarea
                            value={focusedItem.content.postFunctionCall?.funcParams?.newWords}
                            onChange={e => updateDoneCallFuncParams('newWords', e.target.value)}
                            className={styles.textareaSmall}
                        />
                    </div>
                    <div className={styles.modifyItemCol}>
                        <div className={styles.modifyItemTitle}>参数:audio url</div>
                        <TextToSpeech
                            text={focusedItem.content.postFunctionCall?.funcParams?.newWords}
                            btnText='生成音频'
                            successCallback={url => updateDoneCallFuncParams('audioUrl', url)}
                        />
                    </div>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>参数音频链接:</div>
                        {focusedItem.content.postFunctionCall?.funcParams?.audioUrl ? focusedItem.content.postFunctionCall?.funcParams?.audioUrl : "暂无"}
                    </div>
                </>
            )}
        </div>
    )
}

export default BtnTextGroupModifier;