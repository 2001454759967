import React from 'react';
import styles from "./genCustomMusicModifier.module.less";
import { InputNumber, Button, Input, Select } from 'antd';

const { TextArea } = Input;

interface GenCustomMusicModifierProps {
    focusedItem: any;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
}

const GenCustomMusicModifier: React.FC<GenCustomMusicModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {
    let genreList = [
        { value: "流行", label: "流行" },
        { value: "民谣", label: "民谣" },
        { value: "电子", label: "电子" },
        { value: "摇滚", label: "摇滚" },
        { value: "儿歌", label: "儿歌" },
        { value: "情歌", label: "情歌" },
        { value: "古典", label: "古典" },
        { value: "爵士", label: "爵士" },
        { value: "嘻哈", label: "嘻哈" },
        { value: "节奏布鲁斯", label: "节奏布鲁斯" },
        { value: "说唱", label: "说唱" },
        { value: "金属", label: "金属" },
        { value: "蓝调", label: "蓝调" },
        { value: "古风", label: "古风" },
        { value: "中国风", label: "中国风" },
        { value: "戏曲", label: "戏曲" },
        { value: "校园", label: "校园" },
        { value: "Kpop", label: "Kpop" },
        { value: "ACG", label: "ACG" },
        { value: "宗教歌曲", label: "宗教歌曲" },
        { value: "Bossa Nova", label: "Bossa Nova" },
    ];
    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>模式</div>
                <Select
                    value={focusedItem.content.elementMode}
                    style={{ width: 180 }}
                    onChange={(value) => onInputChange(focusedItem.id, 'elementMode', value)}
                >
                    <Select.Option value="song_making">歌曲制作</Select.Option>
                    <Select.Option value="melody_making">旋律制作</Select.Option>
                </Select>
            </div>
            {focusedItem.content.elementMode === 'song_making' &&
                <>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>歌名</div>
                        <Input
                            value={focusedItem.content.songTitle}
                            onChange={e => onInputChange(focusedItem.id, 'songTitle', e.target.value)}
                        />
                    </div>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>歌词</div>
                        <textarea
                            value={focusedItem.content.songLyric}
                            onChange={e => onInputChange(focusedItem.id, 'songLyric', e.target.value)}
                            className={styles.textareaLarge}
                        />
                    </div>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>音乐风格</div>
                        <Select
                            value={focusedItem.content.songStyle}
                            style={{ width: 180 }}
                            onChange={(value) => onInputChange(focusedItem.id, 'songStyle', value)}
                        >
                            {genreList.map(item => (
                                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </>
            }
            {focusedItem.content.elementMode === 'melody_making' &&
                <>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>音乐描述</div>
                        <textarea
                            value={focusedItem.content.songDescription}
                            onChange={e => onInputChange(focusedItem.id, 'songDescription', e.target.value)}
                            className={styles.textareaLarge}
                        />
                    </div>
                </>
            }
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>set songUrl lectureVar</div>
                <TextArea
                    value={focusedItem.content.songUrlLectureVar}
                    onChange={e => onInputChange(focusedItem.id, 'songUrlLectureVar', e.target.value)}
                    className={styles.textareaVar} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>
        </div>
    )
}

export default GenCustomMusicModifier;
