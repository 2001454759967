import {ElementContent} from './ElementData';

class AudioRegionContent extends ElementContent {
    src: string;
    songCoverUrl: string;
    title: string;
    artist: string;
    lyrics: string;
    playerType: string;
  
    constructor({
        positionX = 0,
        positionY = 0,
        width = 200,
        height = 200,
        src = "",
        songCoverUrl = "",
        title = "歌曲名称",
        artist = "作者",
        lyrics = "歌词",
        playerType = "small",
    }: Partial<AudioRegionContent> = {}) { 
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'src', 'songCoverUrl', 'title', 'artist', 'lyrics', 'playerType'];
        this.src = src;
        this.songCoverUrl = songCoverUrl;
        this.title = title;
        this.artist = artist;
        this.lyrics = lyrics;
        this.playerType = playerType;        
    }
};

export default AudioRegionContent;