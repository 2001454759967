import { ElementContent } from './ElementData';
import DataDefinition from '../DataDefinition';

class Holding extends DataDefinition {
    name: string;
    imgUrl: string;
    number: number;

    constructor({
        name = '',
        imgUrl = '',
        number = 0,
    }: Partial<Holding> = {}) {
        super();
        this.allowedFields = ['name', 'imgUrl', 'number'];
        this.name = name;
        this.imgUrl = imgUrl;
        this.number = number;
    }
}

class MysteryBox extends DataDefinition {
    mysteryBoxId: number;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', Holding)
    holdingList: Holding[];

    constructor({
        mysteryBoxId = 0,
        holdingList = [],
    }: Partial<MysteryBox> = {}) {
        super();
        this.allowedFields = ['mysteryBoxId', 'holdingList'];
        this.mysteryBoxId = mysteryBoxId;
        this.holdingList = holdingList;
    }
}

class BlindBoxRegionContent extends ElementContent {
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', MysteryBox)
    mysteryBoxList: MysteryBox[];
    currentMysteryBoxId: number;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 800,
        height = 530,
        mysteryBoxList = [],
        currentMysteryBoxId = 2,
    }: Partial<BlindBoxRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex', 'mysteryBoxList', 'currentMysteryBoxId'];
        this.mysteryBoxList = mysteryBoxList;
        this.currentMysteryBoxId = currentMysteryBoxId;
    }
};

export default BlindBoxRegionContent;
export { MysteryBox, Holding };