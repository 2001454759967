import React, { useState, useEffect } from 'react';
import { Button, message, Select } from 'antd';
import styles from "./createAgent.module.less";
import { BlockTraceData } from '@/base/BlockData';
import { ElementData } from '@/base/ElementData/ElementData';
import AIChatAgentRegionContent from '@/base/ElementData/AIChatAgentRegionContent';
import { ChatMessage } from '@/base/ChatMessage';
import UploadMedia from '@/components/UploadMedia/UploadMedia';
import UploadAgentAvatarIcon from '@/assets/img/playground/chat_upload_avatar.svg';
import AddPresetQuestionIcon from '@/assets/img/chat_component/add_preset_question.svg';
import DelPresetQuestionIcon from '@/assets/img/playground/del_preset_question.svg';
import mammoth from 'mammoth';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { cloneDeep } from 'lodash';
import service from '@/services/axios';
import GoBackIcon from '@/assets/img/user_center/go_back.svg';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import DocumentData from '@/base/DocumentData';
import CancelIcon from '@/assets/img/chat_component/cancel_fill_in_blank.svg';
import { generateMD5 } from '@/utils/utils';
import { createUserWork } from '@/api/userWork';
import ChatAgentInfo from '@/base/ShareData/ChatAgentInfo';
import ChatAgentIcon from '@/assets/img/chat_component/chat_agent_icon.svg';
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import UploadIcon from '@/assets/img/chat_component/upload_icon.svg';

interface CreateAgentProps {
    editType: string;
    blockTraceData: BlockTraceData;
    elementData: ElementData<AIChatAgentRegionContent>;
    setIsEditing: (value: boolean) => void;
}

interface FirstStepContentProps {
    agentAvatar: string;
    setAgentAvatar: (value: string) => void;
    agentName: string;
    setAgentName: (value: string) => void;
}

interface SecondStepContentProps {
    agentRole: string;
    setAgentRole: (value: string) => void;
}

interface ThirdStepContentProps {
    agentSkill: string;
    setAgentSkill: (value: string) => void;
}

interface ForthStepContentProps {
    agentRule: string;
    setAgentRule: (value: string) => void;
}

interface FifthStepContentProps {
    documentList: DocumentData[];
    setDocumentList: (value: DocumentData[]) => void;
    currentDocIndex: number;
    setCurrentDocIndex: (value: number) => void;
    handleFinish: () => void;
    bindResourceObj: Record<string, any> | null;
    setAgentRole: (value: string) => void;
    setAgentSkill: (value: string) => void;
    setAgentRule: (value: string) => void;
    setHeaderText: (value: string) => void;
    setPresetQuestions: (value: ChatMessage[]) => void;
}

interface SixthStepContentProps {
    presetQuestions: ChatMessage[];
    setPresetQuestions: (value: ChatMessage[]) => void;
}

interface SeventhStepContentProps {
    headerText: string;
    setHeaderText: (value: string) => void;
    ttsVoice: string;
    setTtsVoice: (value: string) => void;
}

const voiceList = [
    { value: "BV700_streaming", name: "默认-灿灿" },
    { value: "BV705_streaming", name: "炀炀" },
    { value: "BV034_streaming", name: "知性姐姐" },
    { value: "BV102_streaming", name: "儒雅青年" },
    { value: "BV007_streaming", name: "亲切女生" },
    { value: "BV113_streaming", name: "甜宠少御" },
    { value: "BV056_streaming", name: "阳光男生" },
    { value: "BV051_streaming", name: "奶气萌娃" },
    { value: "BV005_streaming", name: "活泼女生" },
    { value: "BV701_streaming", name: "擎苍-有声阅读" },
    { value: "BV033_streaming", name: "温柔小哥" },
    { value: "BV001_streaming", name: "通用女声" },
    { value: "BV002_streaming", name: "通用男声" }
];

const CreateAgent: React.FC<CreateAgentProps> = ({
    editType,
    blockTraceData,
    elementData,
    setIsEditing
}) => {
    const { lectureStore, userInfoStore } = useStores();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [headerText, setHeaderText] = useState<string>('');
    const [agentName, setAgentName] = useState('');
    const [agentAvatar, setAgentAvatar] = useState('');
    const [presetQuestions, setPresetQuestions] = useState<ChatMessage[]>([]);
    const [ttsVoice, setTtsVoice] = useState('BV033_streaming');
    const [isStream, setIsStream] = useState(true);
    const [documentList, setDocumentList] = useState<DocumentData[]>([]);
    const [currentDocIndex, setCurrentDocIndex] = useState(0);
    const [agentRole, setAgentRole] = useState<string>('');
    const [agentSkill, setAgentSkill] = useState<string>('');
    const [agentRule, setAgentRule] = useState<string>('');
    const [bindResourceObj, setBindResourceObj] = useState<Record<string, any> | null>(null);

    const convertPromptToJson = () => {
        const data = {
            "角色": agentRole,
            "技能": agentSkill,
            "规范": agentRule
        };
        const prompt = JSON.stringify(data);
        return prompt;
    };

    const convertJsonToPrompt = (prompt: string) => {
        try {
            const data = JSON.parse(prompt);
            setAgentRole(data.角色);
            setAgentSkill(data.技能);
            setAgentRule(data.规范);
        } catch (error) {
            setAgentRole('');
            setAgentSkill('');
            setAgentRule('');
        }
    }


    useEffect(() => {
        if (editType === 'edit') {
            setAgentAvatar(elementData.content.agentAvatar || '');
            setAgentName(elementData.content.agentName || '');
            convertJsonToPrompt(elementData.content.systemPrompt);
            setDocumentList(elementData.content.documentList || []);
            setCurrentDocIndex(elementData.content.currentDocIndex || 0);
            setPresetQuestions(elementData.content.presetMessages || []);
            setHeaderText(elementData.content.headerText || '');
            setTtsVoice(elementData.content.ttsVoice || 'BV033_streaming');
            setIsStream(elementData.content.isStream);
            setBindResourceObj(elementData.content.bindResourceObj);
        } else {
            // 非编辑模式下，处理可能的绑定资源
            const docList = elementData.content.documentList || [];
            const currentIndex = elementData.content.currentDocIndex || 0;
            const currentDoc = docList[currentIndex];
            const currentDocName = currentDoc?.docName;
            const bindData = currentDocName ? elementData.content.bindResourceObj?.[currentDocName] : null;

            // 设置agentRole，优先变量模式，否则取绑定数据或原始数据
            const resolveAgentProp = (prop: string, bindProp?: string) => {
                return containsVariablePattern(prop)
                    ? rewriteText(prop)
                    : (bindProp ?? prop);
            };

            const defaultAvatar = 'https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/ee0bcaf6d6f58cc7e164eb456458c6af.png';

            setAgentAvatar(
                elementData.content.agentAvatar
                    ? containsVariablePattern(elementData.content.agentAvatar)
                        ? rewriteText(elementData.content.agentAvatar)
                        : elementData.content.agentAvatar
                    : defaultAvatar
            );

            setAgentName(containsVariablePattern(elementData.content.agentName)
                ? rewriteText(elementData.content.agentName)
                : elementData.content.agentName);

            setAgentRole(resolveAgentProp(
                elementData.content.agentRole,
                bindData?.role
            ));

            setAgentSkill(resolveAgentProp(
                elementData.content.agentSkill,
                bindData?.skill
            ));

            setAgentRule(resolveAgentProp(
                elementData.content.agentRule,
                bindData?.rule
            ));

            setHeaderText(resolveAgentProp(
                elementData.content.headerText,
                bindData?.welcomeMessage
            ));

            const presetMessages = bindData?.presetQuestions
                ? bindData.presetQuestions.map((content: string) => new ChatMessage({ rawContent: content }))
                : elementData.content.presetMessages || [];
            setPresetQuestions(presetMessages);

            // 设置其他状态
            setDocumentList(docList);
            setCurrentDocIndex(currentIndex);
            setTtsVoice(elementData.content.ttsVoice || 'BV033_streaming');
            setIsStream(elementData.content.isStream);
            setBindResourceObj(elementData.content.bindResourceObj);
        }

    }, [elementData.content.agentName, elementData.content.agentAvatar, elementData.content.presetMessages,
    elementData.content.ttsVoice, elementData.content.isStream, elementData.content.documentList,
    elementData.content.currentDocIndex, elementData.content.agentRole, elementData.content.agentSkill,
    elementData.content.agentRule, elementData.content.headerText, elementData.content.bindResourceObj]);

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    }

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    }
    const genShareLink = async (content: AIChatAgentRegionContent) => {
        let docContent = '';
        if (content.documentList.length > 0 && content.currentDocIndex < content.documentList.length) {
            docContent = content.documentList[content.currentDocIndex].docContent;
        }
        let shareContent = new ChatAgentInfo({
            systemPrompt: content.systemPrompt + docContent,
            headerText: content.headerText,
            agentAvatar: content.agentAvatar,
            presetMessageList: content.presetMessages,
            shareDescription: `${userInfoStore.userInfoData.name}创作的智能体`,
            shareScene: 'customAgent',
        });
        let concatenatedString = '';
        let timestamp = new Date().getTime();
        concatenatedString = `${userInfoStore.userInfoData.id}${shareContent.systemPrompt}${shareContent.headerText}${shareContent.agentAvatar}${shareContent.shareDescription}${shareContent.shareScene}${timestamp}`;
        const MD5 = generateMD5(concatenatedString);
        const shareLinkData = {
            id: 0,
            md5: MD5,
            user_id: userInfoStore.userInfoData.id,
            user_name: userInfoStore.userInfoData.name,
            work_type: "chatAgent",
            work_info: shareContent,
            create_time: 0
        };
        const shareLinkRes: any = await createUserWork(shareLinkData);
        if (shareLinkRes.status === 'success') {
            return `${import.meta.env.VITE_FE_URL}/share/${MD5}`;
        } else {
            return '';
        }
    }
    const handleFinish = async (isFinalStep: boolean = false) => {
        const newChatAgentContent = new AIChatAgentRegionContent({
            width: elementData.content.width,
            height: elementData.content.height,
            positionX: elementData.content.positionX,
            positionY: elementData.content.positionY,
            chatList: elementData.content.chatList,
            agentName: agentName,
            agentAvatar: agentAvatar,
            systemPrompt: convertPromptToJson(),
            agentRole: agentRole,
            agentSkill: agentSkill,
            agentRule: agentRule,
            documentList: documentList,
            currentDocIndex: currentDocIndex,
            presetMessages: presetQuestions,
            headerText: headerText,
            ttsVoice: ttsVoice,
            bindResourceObj: bindResourceObj,
            isStream: true,
            roleLectureVar: elementData.content.roleLectureVar,
            skillLectureVar: elementData.content.skillLectureVar,
            ruleLectureVar: elementData.content.ruleLectureVar,
            agentLinkLectureVar: elementData.content.agentLinkLectureVar,
            agentMode: 'advanced',
            isCreatedChatAgent: isFinalStep ? true : elementData.content.isCreatedChatAgent,
        });

        if (isFinalStep && elementData.content.agentLinkLectureVar) {
            newChatAgentContent.agentShareLink = await genShareLink(newChatAgentContent);
        }

        const newBlockTraceData = cloneDeep(blockTraceData);
        const currentElementData = newBlockTraceData.traceInfo.find(item => item.id === elementData.id);
        if (currentElementData) {
            currentElementData.content = newChatAgentContent;
        }

        try {
            const res: any = await service.put('/block_trace', newBlockTraceData);
            if (res.status === "success") {
                lectureStore.updateElement(blockTraceData.id, elementData.id, newChatAgentContent);
                if (isFinalStep && editType === 'edit') {
                    setIsEditing(false);
                }
            }
        } catch (error) {
            console.log(error);
            message.error(isFinalStep
                ? (editType === 'edit' ? '更新失败' : '创建失败')
                : '保存失败'
            );
        }
    }

    const steps = [
        {
            title: 'First',
            content: <FirstStepContent
                agentAvatar={agentAvatar}
                setAgentAvatar={setAgentAvatar}
                agentName={agentName}
                setAgentName={setAgentName}
            />,
        },
        {
            title: 'Second',
            content: <FifthStepContent
                documentList={documentList}
                setDocumentList={setDocumentList}
                currentDocIndex={currentDocIndex}
                setCurrentDocIndex={setCurrentDocIndex}
                handleFinish={() => handleFinish(false)}
                bindResourceObj={bindResourceObj}
                setAgentRole={setAgentRole}
                setAgentSkill={setAgentSkill}
                setAgentRule={setAgentRule}
                setHeaderText={setHeaderText}
                setPresetQuestions={setPresetQuestions}
            />,
        },
        {
            title: 'Third',
            content: <SecondStepContent
                agentRole={agentRole}
                setAgentRole={setAgentRole}
            />,
        },
        {
            title: 'Forth',
            content: <ThirdStepContent
                agentSkill={agentSkill}
                setAgentSkill={setAgentSkill}
            />,
        },
        {
            title: 'Fifth',
            content: <ForthStepContent
                agentRule={agentRule}
                setAgentRule={setAgentRule}
            />,
        },
        {
            title: 'Sixth',
            content: <SixthStepContent
                presetQuestions={presetQuestions}
                setPresetQuestions={setPresetQuestions}
            />,
        },
        {
            title: 'Seventh',
            content: <SeventhStepContent
                headerText={headerText}
                setHeaderText={setHeaderText}
                ttsVoice={ttsVoice}
                setTtsVoice={setTtsVoice}
            />,
        },
    ];
    return (
        <div className={styles.createAgentContainer}>
            {
                elementData.content.isCreatedChatAgent && (
                    <div className={styles.goBack} onClick={() => setIsEditing(false)}>
                        <img src={GoBackIcon} />
                    </div>
                )
            }
            <div className={styles.stepsContentBox}>
                {steps[currentStep].content}
            </div>
            <div className={styles.stepsAction}>
                {currentStep > 0 && (
                    <Button onClick={() => prevStep()} className={styles.prevBtn}>
                        上一步
                    </Button>
                )}
                {currentStep < steps.length - 1 && (
                    <Button type="primary" onClick={() => nextStep()} className={styles.nextBtn}>
                        下一步
                    </Button>
                )}
                {currentStep === steps.length - 1 && (
                    <Button type="primary" onClick={() => handleFinish(true)} className={styles.nextBtn}>
                        {editType === 'edit' ? '更新' : '创建'}
                    </Button>
                )}
            </div>
        </div>
    )
}

export default observer(CreateAgent);

const FirstStepContent: React.FC<FirstStepContentProps> = ({
    agentName, setAgentName,
    agentAvatar, setAgentAvatar
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.agentAvatarNameBox}>
                <div className={styles.agentAvatarNameTitle}>
                    {`智能体创建 ----> 头像和名称`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentAvatarNameEditBox}>
                    <div className={styles.agentAvatarBox}>
                        {agentAvatar ? <img src={agentAvatar} className={styles.agentAvatar} /> :
                            <div className={styles.agentAvatar}>
                                上传头像
                            </div>
                        }
                        <div className={styles.uploadAvatar}>
                            <UploadMedia
                                successCallBack={(url) => {
                                    setAgentAvatar(url);
                                }}
                                icon={UploadAgentAvatarIcon}
                                accept_str="image/*"
                                dir="avatar"
                                isMultiple={false}
                            />
                        </div>
                    </div>
                    <div className={styles.agentNameInputBox}>
                        <textarea
                            className={styles.agentNameInput}
                            placeholder="请输入智能体名称"
                            value={agentName}
                            onChange={(e) => setAgentName(e.target.value)}
                        />
                        <div className={styles.btn}>
                            <SpeechToText
                                inputMessage={agentName}
                                setInputMessage={setAgentName}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const SecondStepContent: React.FC<SecondStepContentProps> = ({
    agentRole, setAgentRole
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 角色描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>
                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入角色描述"
                        value={agentRole}
                        onChange={(e) => setAgentRole(e.target.value)}
                    />
                    <div className={styles.btn}>
                        <SpeechToText
                            inputMessage={agentRole}
                            setInputMessage={setAgentRole}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const ThirdStepContent: React.FC<ThirdStepContentProps> = ({
    agentSkill, setAgentSkill
}) => {

    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 技能描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>
                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入技能描述"
                        value={agentSkill}
                        onChange={(e) => setAgentSkill(e.target.value)}
                    />
                    <div className={styles.btnArea}>
                        <div className={styles.btn}>
                            <SpeechToText
                                inputMessage={agentSkill}
                                setInputMessage={setAgentSkill}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ForthStepContent: React.FC<ForthStepContentProps> = ({
    agentRule, setAgentRule
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 规范描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>

                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入规范描述"
                        value={agentRule}
                        onChange={(e) => setAgentRule(e.target.value)}
                    />
                    <div className={styles.btnArea}>
                        <div className={styles.btn}>
                            <SpeechToText
                                inputMessage={agentRule}
                                setInputMessage={setAgentRule}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FifthStepContent: React.FC<FifthStepContentProps> = ({
    documentList, setDocumentList,
    currentDocIndex, setCurrentDocIndex,
    handleFinish, bindResourceObj,
    setAgentRole, setAgentSkill, setAgentRule,
    setHeaderText, setPresetQuestions
}) => {
    const [isEditingDoc, setIsEditingDoc] = useState(false);
    const propsUpload = {
        isDoc: true,
    };
    const readFileContent = async (file: any, url: string) => {
        // 判断文件类型  
        const fileType = file.type;

        if (fileType === 'text/plain') {
            // 处理 .txt 文件  
            const text = await file.text();
            const newDocument = new DocumentData({
                docContent: text,
                docName: file.name,
                docUrl: url
            });
            setCurrentDocIndex(documentList.length);
            setDocumentList([...documentList, newDocument]);
            setIsEditingDoc(false);
        } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            // 处理 .docx 文件  
            const arrayBuffer = await file.arrayBuffer();
            const { value } = await mammoth.extractRawText({ arrayBuffer });
            const newDocument = new DocumentData({
                docContent: value,
                docName: file.name,
                docUrl: url
            });
            setCurrentDocIndex(documentList.length);
            setDocumentList([...documentList, newDocument]);
            setIsEditingDoc(false);
        } else {
            message.error('文件类型不支持');
        }
    };

    const handleDocItemClick = (index: number, docName: string) => {
        setCurrentDocIndex(index);
        setIsEditingDoc(false);
        if (bindResourceObj && bindResourceObj[docName]) {
            const selectedDocData = bindResourceObj[docName];
            if (selectedDocData.role) {
                setAgentRole(selectedDocData.role);
            }
            if (selectedDocData.skill) {
                setAgentSkill(selectedDocData.skill);
            }
            if (selectedDocData.rule) {
                setAgentRule(selectedDocData.rule);
            }
            if (selectedDocData.welcomeMessage) {
                setHeaderText(selectedDocData.welcomeMessage);
            }
            if (selectedDocData.presetQuestions) {
                setPresetQuestions(selectedDocData.presetQuestions.map((content: string) => new ChatMessage({ rawContent: content })));
            }
        }
    };

    return (
        <div className={styles.stepContent}>
            <div className={styles.uploadDocBox}>
                <div className={styles.uploadDocTitle}>
                    {`智能体创建 ----> 知识库文档`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>

                <div className={styles.docListTab}>
                    <div className={styles.docList}>
                        {documentList.map((item, index) => (
                            <div
                                key={item.id}
                                className={`${styles.docItem} ${currentDocIndex === index ? styles.docItemActive : ''}`}
                                onClick={() => handleDocItemClick(index, item.docName)}
                            >
                                <div className={styles.docItemName}>{item.docName}</div>
                                <div
                                    className={styles.docItemDel}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const newDocumentList = documentList.filter((doc, i) => i !== index);
                                        setDocumentList(newDocumentList);
                                        if (currentDocIndex === index) {
                                            setCurrentDocIndex(0);
                                        }
                                    }}
                                >
                                    <img src={CancelIcon} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <UploadMedia
                        icon={UploadIcon}
                        successCallBack={(url, file) => {
                            readFileContent(file, url);
                        }}
                        btn_text="上传文档"
                        accept_str=".txt,.docx"
                        dir="docs"
                        isMultiple={false}
                        uploadProps={propsUpload}
                    />
                </div>
                {documentList.length === 0 && (
                    <div className={styles.noDocTip}>
                        请上传知识库文档
                    </div>
                )}
                {documentList.length > 0 && (
                    <>
                        {
                            isEditingDoc &&
                            <div className={styles.docEditAreaBox}>
                                <textarea
                                    className={styles.docEditArea}
                                    placeholder="请输入文档内容"
                                    value={documentList[currentDocIndex] ? documentList[currentDocIndex].docContent : ''}
                                    onChange={(e) => {
                                        const newDocumentList = cloneDeep(documentList);
                                        newDocumentList[currentDocIndex].docContent = e.target.value;
                                        setDocumentList(newDocumentList);
                                    }}
                                />
                                <div className={styles.btn}>
                                    <SpeechToText
                                        inputMessage={documentList[currentDocIndex] ? documentList[currentDocIndex].docContent : ''}
                                        setInputMessage={(value) => {
                                            const newDocumentList = cloneDeep(documentList);
                                            newDocumentList[currentDocIndex].docContent = value;
                                            setDocumentList(newDocumentList);
                                        }}
                                    />
                                </div>
                                <div
                                    className={styles.saveBtn}
                                    onClick={() => {
                                        setIsEditingDoc(false);
                                        handleFinish();
                                    }}
                                >
                                    保存
                                </div>
                            </div>
                        }
                        {
                            !isEditingDoc &&
                            <div
                                className={styles.docContentBox}
                            >
                                <div className={styles.docContent}>
                                    {documentList[currentDocIndex] ? documentList[currentDocIndex].docContent : ''}
                                </div>
                                <div
                                    className={styles.editBtn}
                                    onClick={() => setIsEditingDoc(true)}
                                >
                                    编辑
                                </div>
                            </div>
                        }
                    </>
                )}
            </div>
        </div>
    )
}

const SixthStepContent: React.FC<SixthStepContentProps> = ({
    presetQuestions, setPresetQuestions
}) => {
    const handleAddPresetQuestion = () => {
        const newPresetQuestion = new ChatMessage({});
        setPresetQuestions([...presetQuestions, newPresetQuestion]);
    }

    const handleDelPresetQuestion = (id: number) => {
        const newPresetQuestions = presetQuestions.filter((question) => question.id !== id);
        setPresetQuestions(newPresetQuestions);
    }
    return (
        <div className={styles.stepContent}>
            <div className={styles.addPresetQuestionsBox}>
                <div className={styles.addPresetQuestionsTitle}>
                    {`智能体创建 ----> 预设问题`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.addPresetQuestionsBtnBox}>
                    <div
                        className={styles.addPresetQuestionsBtn}
                        onClick={handleAddPresetQuestion}
                    >
                        <img src={AddPresetQuestionIcon} />
                        添加问题
                    </div>
                </div>
                <div className={styles.presetQuestionsList}>
                    {presetQuestions.map((question, index) => (
                        <div key={question.id} className={styles.presetQuestion}>
                            <div className={styles.presetQuestionTitle}>预设问题{index + 1}</div>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    className={styles.presetQuestionInput}
                                    placeholder="请输入问题"
                                    value={question.rawContent}
                                    onChange={(e) => {
                                        question.rawContent = e.target.value;
                                        setPresetQuestions([...presetQuestions]);
                                    }}
                                />
                                <div className={styles.microphoneBtn}>
                                    <SpeechToText
                                        inputMessage={question.rawContent}
                                        setInputMessage={(value) => {
                                            question.rawContent = value;
                                            setPresetQuestions([...presetQuestions]);
                                        }}
                                    />
                                </div>
                                <div
                                    className={styles.delPresetQuestion}
                                    onClick={() => handleDelPresetQuestion(question.id)}
                                >
                                    <img src={DelPresetQuestionIcon} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const SeventhStepContent: React.FC<SeventhStepContentProps> = ({
    headerText, setHeaderText,
    ttsVoice, setTtsVoice
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.welcomeTextBox}>
                <div className={styles.welcomeTextTitle}>
                    {`智能体创建 ----> 欢迎语和音色`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.welcomeAndVoiceBox}>
                    <div className={styles.welcomeTextInputBox}>
                        <textarea
                            className={styles.welcomeTextInput}
                            placeholder="请输入欢迎语"
                            value={headerText}
                            onChange={(e) => setHeaderText(e.target.value)}
                        />
                        <div className={styles.btnArea}>
                            <div className={styles.btn}>
                                <SpeechToText
                                    inputMessage={headerText}
                                    setInputMessage={setHeaderText}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.voiceSelectArea}>
                        <div className={styles.voiceSelectAreaTitle}>选择音色</div>
                        <Select
                            value={ttsVoice}
                            onChange={(value) => setTtsVoice(value)}
                            className={styles.voiceSelect}
                        >
                            {voiceList.map((item) => (
                                <Select.Option key={item.value} value={item.value}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>

            </div>
        </div >
    )
}

