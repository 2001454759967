import PropTypes from 'prop-types';
import TextPlayableRegion from '../TextRegion/TextPlayableRegion';
import ImageRegion from "../ImageRegion/ImageRegion";
import VideoRegion from "../VideoRegion/VideoRegion";
import ButtonRegion from "../ButtonRegion/ButtonRegion";
import AdvancedButtonRegion from '../ButtonRegion/AdvancedButtonRegion';
import BtnTextGroupRegion from "../BtnTextGroupRegion/BtnTextGroupRegion";
import BtnGenericGroupRegion from '../BtnGenericGroupRegion/BtnGenericGroupRegion';
import AudioRegion from '../AudioRegion/AudioRegion';
import SunoGen from '../SunoGen/SunoGen';
import AIChatRegion from '../AIChatRegion/AIChatRegion';
import TeacherWordsRegion from '../TeacherWordsRegion/TeacherWordsRegion';
import LineRegion from '../LineRegion/LineRegion';
import RatingRegion from '../RatingRegion/RatingRegion';
import CodeRegion from '../CodeRegion/CodeRegion';
import QRCodeRegion from '../QRCodeRegion/QRCodeRegion';
import DragClassifyRegion from '../DragClassifyRegion/DragClassifyRegion';
import ShowCodeRegion from '../ShowCodeRegion/ShowCodeRegion';
import FlashCardRegion from '../FlashCardRegion/FlashCardRegion';
import LineConnectorRegion from '../LineConnectorRegion/LineConnectorRegion';
import ShareWithSoundRegion from '../ShareWithSoundRegion/ShareWithSoundRegion';
import TreasureHuntRegion from '../TreasureHuntRegion/TreasureHuntRegion';
import RollingBallRegion from '../RollingBallRegion/RollingBallRegion';
import NeuronCalculatorRegion from '../NeuronCalculatorRegion/NeuronCalculatorRegion';
import NNVisRegion from '../NNVisRegion/NNVisRegion';
import TrainingLogRegion from '../TrainingLogRegion/TrainingLogRegion';
import BlindBoxRegion from '../BlindBoxRegion/BlindBoxRegion';
import TokenizerRegion from '../TokenizerRegion/TokenizerRegion';
import WordGeneratorRegion from '../WordGeneratorRegion/WordGeneratorRegion';
import GenCustomMusicRegion from '../GenCustomMusicRegion/GenCustomMusicRegion';
import WordCoordinateRegion from '../WordCoordinateRegion/WordCoordinateRegion';
import AIChatAgentRegion from '../AIChatAgentRegion/AIChatAgentRegion';
import CustomGamesRegion from '../CustomGamesRegion/CustomGamesRegion';
import { ElementContent, ElementData } from '@/base/ElementData/ElementData';
import TextRegionContent from '@/base/ElementData/TextRegionContent';
import ImageRegionContent from '@/base/ElementData/ImageRegionContent';
import ButtonRegionContent from '@/base/ElementData/ButtonRegionContent';
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';
import BtnTextGroupRegionContent from '@/base/ElementData/BtnTextGroupRegionContent';
import BtnGenericGroupRegionContent from '@/base/ElementData/BtnGenericGroupRegionContent';
import VideoRegionContent from '@/base/ElementData/VideoRegionContent';
import AudioRegionContent from '@/base/ElementData/AudioRegionContent';
import SunoGenContent from '@/base/ElementData/SunoGenContent';
import AIChatRegionContent from '@/base/ElementData/AIChatRegionContent';
import TeacherWordsRegionContent from '@/base/ElementData/TeacherWordsRegionContent';
import LineRegionContent from '@/base/ElementData/LineRegionContent';
import RatingRegionContent from '@/base/ElementData/RatingRegionContent';
import CodeRegionContent from '@/base/ElementData/CodeRegionContent';
import QRCodeRegionContent from '@/base/ElementData/QRCodeRegionContent';
import { DragClassifyRegionContent } from '@/base/ElementData/DragClassifyRegionContent';
import ShowCodeRegionContent from '@/base/ElementData/ShowCodeRegionContent';
import FlashCardRegionContent from '@/base/ElementData/FlashCardRegionContent';
import { LineConnectorRegionContent } from '@/base/ElementData/LineConnectorRegionContent';
import ShareWithSoundRegionContent from '@/base/ElementData/ShareWithSoundRegionContent';
import TreasureHuntRegionContent from '@/base/ElementData/TreasureHuntRegionContent';
import RollingBallRegionContent from '@/base/ElementData/RollingBallRegionContent';
import NeuronCalculatorRegionContent from '@/base/ElementData/NeuronCalculatorRegionContent';
import NNVisRegionContent from '@/base/ElementData/NNVisRegionContent';
import TrainingLogRegionContent from '@/base/ElementData/TrainingLogRegionContent';
import BlindBoxRegionContent from '@/base/ElementData/BlindBoxRegionContent';
import TokenizerRegionContent from '@/base/ElementData/TokenizerRegionContent';
import WordGeneratorRegionContent from '@/base/ElementData/WordGeneratorRegionContent';
import GenCustomMusicRegionContent from '@/base/ElementData/GenCustomMusicRegionContent';
import WordCoordinateRegionContent from '@/base/ElementData/WordCoordinateRegionContent';
import AIChatAgentRegionContent from '@/base/ElementData/AIChatAgentRegionContent';
import CustomGamesRegionContent from '@/base/ElementData/CustomGamesRegionContent';

const RenderElement = (props: any) => {
    // 这里接受的是一个特殊变量props
    let data: ElementData<ElementContent> = (props.elementData as ElementData<ElementContent>);
    let blockTraceData = props.blockTraceData;
    switch (data.type) {
        case 101:
            return (
                <TextPlayableRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<TextRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    handleContentChange={props.handleContentChange}
                    index={props.index}
                >
                </TextPlayableRegion>
            );
        case 102:
            return (
                <ImageRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<ImageRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}>
                </ImageRegion>
            );
        case 103:
            return (
                <ButtonRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<ButtonRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}>
                </ButtonRegion>
            );
        case 104:
            return (
                <AdvancedButtonRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<AdvancedButtonRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    handleContentChange={props.handleContentChange}
                    index={props.index}
                    ref={props.elementRef}
                >
                </AdvancedButtonRegion>
            );
        case 105:
            return (
                <BtnTextGroupRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<BtnTextGroupRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}
                    blockTraceData={blockTraceData}
                    ref={props.innerRef}
                    elementRef={props.elementRef}
                >
                </BtnTextGroupRegion>
            );
        case 106:
            return (
                <BtnGenericGroupRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<BtnGenericGroupRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}
                    blockTraceData={blockTraceData}
                    ref={props.innerRef}
                    elementRef={props.elementRef}
                >
                </BtnGenericGroupRegion>
            );
        case 107:
            return (
                <VideoRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<VideoRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}>
                </VideoRegion>
            );
        case 108:
            return (
                <AudioRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<AudioRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    lectureStore={props.lectureStore}
                    index={props.index}
                >
                </AudioRegion>
            );
        case 109:
            return (
                <SunoGen
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<SunoGenContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    lectureStore={props.lectureStore}
                    index={props.index}>
                </SunoGen>
            );
        case 110:
            return (
                <AIChatRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<AIChatRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}
                    blockTraceData={blockTraceData}
                    AIChatRef={props.AIChatRef}
                >
                </AIChatRegion>
            );
        case 111:
            return (
                <TeacherWordsRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<TeacherWordsRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}
                    ref={props.elementRef}
                    AIChatRef={props.AIChatRef}
                >
                </TeacherWordsRegion>
            );
        // case 112:
        //   目前空缺
        //   return ()
        case 113:
            return (
                <LineRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<LineRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    index={props.index}>
                </LineRegion>
            );
        case 114:
            return (
                <RatingRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<RatingRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    blockTraceData={blockTraceData}
                    index={props.index}>
                </RatingRegion>
            );
        case 115:
            return (
                <CodeRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<CodeRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </CodeRegion>
            );
        //case 116:
        //    return ();
        case 117:
            return (
                <QRCodeRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<QRCodeRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </QRCodeRegion>
            );
        case 118:
            return (
                <DragClassifyRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<DragClassifyRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    blockTraceData={blockTraceData}
                >
                </DragClassifyRegion>
            );
        case 119:
            return (
                <ShowCodeRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<ShowCodeRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </ShowCodeRegion>
            );
        case 120:
            return (
                <FlashCardRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<FlashCardRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </FlashCardRegion>
            );
        case 121:
            return (
                <LineConnectorRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<LineConnectorRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    blockTraceData={blockTraceData}
                >
                </LineConnectorRegion>
            );
        case 122:
            return (
                <ShareWithSoundRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<ShareWithSoundRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    blockTraceData={blockTraceData}
                >
                </ShareWithSoundRegion>
            );
        case 123:
            return (
                <TreasureHuntRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<TreasureHuntRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </TreasureHuntRegion>
            );
        case 124:
            return (
                <RollingBallRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<RollingBallRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </RollingBallRegion>
            );
        case 125:
            return (
                <NeuronCalculatorRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<NeuronCalculatorRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </NeuronCalculatorRegion>
            );
        case 126:
            return (
                <NNVisRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<NNVisRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </NNVisRegion>
            );
        case 127:
            return (
                <TrainingLogRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<TrainingLogRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </TrainingLogRegion>
            );
        case 128:
            return (
                <BlindBoxRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<BlindBoxRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    blockTraceData={blockTraceData}
                >
                </BlindBoxRegion>
            );
        case 129:
            return (
                <TokenizerRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<TokenizerRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </TokenizerRegion>
            );
        case 130:
            return (
                <WordGeneratorRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<WordGeneratorRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </WordGeneratorRegion>
            );
        case 131:
            return (
                <GenCustomMusicRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<GenCustomMusicRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    blockTraceData={blockTraceData}
                >
                </GenCustomMusicRegion>
            );
        case 132:
            return (
                <WordCoordinateRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<WordCoordinateRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </WordCoordinateRegion>
            );
        case 133:
            return (
                <AIChatAgentRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<AIChatAgentRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                    blockTraceData={blockTraceData}
                >
                </AIChatAgentRegion>
            );
        case 134:
            return (
                <CustomGamesRegion
                    key={data.id + blockTraceData?.id}
                    elementData={data as ElementData<CustomGamesRegionContent>}
                    isEditable={props.isEditable}
                    handleFocusItem={props.handleFocusItem}
                    handleResize={props.handleResize}
                    handleDragStop={props.handleDragStop}
                    handleDelete={props.handleDelete}
                >
                </CustomGamesRegion>
            );
        default:
            return null;
    }

}

export default RenderElement;

RenderElement.propTypes = {
    elementData: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    handleFocusItem: PropTypes.func,
    handleResize: PropTypes.func,
    handleDragStop: PropTypes.func,
    handleDelete: PropTypes.func,
    handleContentChange: PropTypes.func,
    index: PropTypes.number.isRequired,
    blockTraceData: PropTypes.object,
    lectureStore: PropTypes.object,
    innerRef: PropTypes.object,
    elementRef: PropTypes.object,
    AIChatRef: PropTypes.object,
}
