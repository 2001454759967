import { Modal, Input, InputNumber, Button, Radio } from "antd";
import { useState, useEffect } from "react";
import ProgressSegmentData from '@/base/ProgressSegmentData';
import UploadMedia from "../UploadMedia/UploadMedia";
import styles from "./createSectionModal.module.less";

interface CreateSectionModalProps {
    isModalOpen: boolean;
    handleSectionOk: (segments: ProgressSegmentData[]) => void;
    handleSectionCancel: () => void;
    inputSectionName: string;
    inputSectionIntro: string;
    sectionCoverUrl: string;
    sectionKeyPoints: string[];
    sectionPartSegments: ProgressSegmentData[];
    sectionVisibleToNormalUsers: boolean;
    sectionBGM: string;
    blockBackground: string;
    commonTeacherSystemPrompt: string;
    sectionNameChange: (e: any) => void;
    sectionCoverUrlChange: (url: string) => void;
    sectionIntroduceChange: (e: any) => void;
    sectionKeyPointChange: (index: number, value: string) => void;
    sectionPartSegmentsChange: (index: number, key: string, value: any) => void;
    sectionVisibleToNormalUsersChange: (value: boolean) => void;
    sectionBGMChange: (url: string) => void;
    sectionBlockBackgroundChange: (url: string) => void;
    setCommonTeacherSystemPrompt: (value: string) => void;

    totalBlocks: number;
    isCreating: boolean;
};


const CreateSectionModal: React.FC<CreateSectionModalProps> = ({
    isModalOpen,
    handleSectionOk,
    handleSectionCancel,
    inputSectionName,
    inputSectionIntro,
    sectionCoverUrl,
    sectionKeyPoints,
    sectionPartSegments,
    sectionVisibleToNormalUsers,
    sectionBGM,
    blockBackground,
    commonTeacherSystemPrompt,
    sectionNameChange,
    sectionCoverUrlChange,
    sectionIntroduceChange,
    sectionKeyPointChange,
    sectionPartSegmentsChange,
    sectionVisibleToNormalUsersChange,
    sectionBGMChange,
    sectionBlockBackgroundChange,
    setCommonTeacherSystemPrompt,
    totalBlocks,
    isCreating,
}) => {
    const [localPartSegments, setLocalPartSegments] = useState(sectionPartSegments || []);
    //const [isVisibleToNormalUsers, setIsVisibleToNormalUsers] = useState(courseVisibleToNormalUsers);
    useEffect(() => {
        setLocalPartSegments(sectionPartSegments || []);
    }, [sectionPartSegments]);

    const addPart = () => {
        const newPart = new ProgressSegmentData({
            endPos: 0,
            desc: ""
        });
        setLocalPartSegments([...localPartSegments, newPart]);
        sectionPartSegmentsChange(0, "new", null);
    };

    const delPart = (index: number) => {
        const newPartSegments = [...localPartSegments];
        newPartSegments.splice(index, 1);
        setLocalPartSegments(newPartSegments);
        sectionPartSegmentsChange(0, "del", index);
    };

    const onRadioChange = (e: any) => {
        sectionVisibleToNormalUsersChange(e.target.value);
    };

    return (
        <Modal
            title="课名称"
            open={isModalOpen}
            onOk={() => handleSectionOk(localPartSegments)}
            onCancel={handleSectionCancel}
            centered
            okText="确认"
            cancelText="取消"
            confirmLoading={isCreating} // 设置Modal的confirmLoading状态为isCreating的值
        >
            <UploadMedia
                successCallBack={sectionCoverUrlChange}
                btn_text={"上传封面"}
                accept_str={"image/*"}
                dir={"images"}
                isMultiple={false}
            />
            <div className={styles.cover}>
                {!sectionCoverUrl && <h4>暂无封面</h4>}
                {sectionCoverUrl && <img src={sectionCoverUrl} alt="" />}
            </div>
            <Input placeholder="请输入课名称" value={inputSectionName} onChange={sectionNameChange}></Input>
            <Input placeholder="请输入课介绍" value={inputSectionIntro} onChange={sectionIntroduceChange}></Input>
            <div className={styles.bgmBox}>
                <UploadMedia
                    successCallBack={sectionBGMChange}
                    btn_text={"修改背景音乐"}
                    accept_str={"audio/*"}
                    dir={"audio"}
                    isMultiple={false}
                />
                {sectionBGM ? <audio src={sectionBGM} controls /> : <div>暂无背景音乐</div>}
                {sectionBGM && <div
                    className={styles.clearBGM}
                    onClick={() => { sectionBGMChange('') }}
                >
                    清除背景音乐
                </div>}
            </div>
            <div>课程目标</div>
            <Input placeholder="请输入section目标 1" value={sectionKeyPoints[0]} onChange={e => sectionKeyPointChange(0, e.target.value)}></Input>
            <Input placeholder="请输入section目标 2" value={sectionKeyPoints[1]} onChange={e => sectionKeyPointChange(1, e.target.value)}></Input>
            <Input placeholder="请输入section目标 3" value={sectionKeyPoints[2]} onChange={e => sectionKeyPointChange(2, e.target.value)}></Input>
            <br />
            <div>总共{totalBlocks}个Blocks</div>
            <Radio.Group onChange={onRadioChange} value={sectionVisibleToNormalUsers}>
                <Radio value={true}>普通用户可见</Radio>
                <Radio value={false}>普通用户不可见</Radio>
            </Radio.Group>
            <br />
            <UploadMedia
                successCallBack={sectionBlockBackgroundChange}
                btn_text={"上传block背景"}
                accept_str={"image/*"}
                dir={"images"}
                isMultiple={false}
            />
            <div className={styles.cover}>
                {!blockBackground && <h4>暂无block背景</h4>}
                {blockBackground && <img src={blockBackground} alt="" />}
            </div>
            <div className={styles.aiTeacherPrompt}>
                <div className={styles.title}>AI老师系统Prompt的公共部分</div>
                <textarea
                    value={commonTeacherSystemPrompt}
                    onChange={e => setCommonTeacherSystemPrompt(e.target.value)}
                    className={styles.textAreaLarge}
                />
            </div>

            <div>
                <div>配置Part信息</div>
                <br />
                {localPartSegments.map((item, index) => (
                    <div key={"parts" + String(index)}>
                        <h4>part {index}</h4>
                        <div >endPos</div>
                        <InputNumber
                            value={item.endPos}
                            onChange={(value) => sectionPartSegmentsChange(index, 'endPos', value)} />
                        <div >Description</div>
                        <Input placeholder="Part描述"
                            value={item.desc}
                            onChange={e => sectionPartSegmentsChange(index, 'desc', e.target.value)}
                            className={styles.textareaVar} />
                        <Button onClick={() => delPart(index)}>删除</Button>
                    </div>)
                )}
                <Button onClick={addPart}>添加Part</Button>
            </div>
        </Modal>
    )
}

export default CreateSectionModal;