import React, { useEffect, useState } from 'react';
import styles from "./blindBoxRegion.module.less";
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import BlindBoxRegionContent from '@/base/ElementData/BlindBoxRegionContent';
import BlindBoxCover from '@/assets/img/user_center/blind_box_cover.svg';
import BlindBoxV2Cover from '@/assets/img/user_center/blind_box_v2_cover.svg';
import { getBlindBox, getBlindBoxV2 } from '@/api/userCenter';
import Lottie from 'lottie-react';
import blindAnimation from '@/assets/animation/hezi.json';
import service from '@/services/axios';
import { MysteryBox, Holding } from '@/base/ElementData/BlindBoxRegionContent';
import { cloneDeep } from 'lodash';
import { BlockTraceData } from '@/base/BlockData';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';

interface BlindBoxRegionProps extends IntrinsicElementProps<BlindBoxRegionContent> {
    blockTraceData: BlockTraceData;
}

const BlindBoxRegion: React.FC<BlindBoxRegionProps> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
    blockTraceData
}) => {
    const { lectureStore } = useStores();
    const [isAnimating, setIsAnimating] = useState(false);
    const [blindBoxImgUrl, setBlindBoxImgUrl] = useState<string | null>(null);
    const [mysteryBoxList, setMysteryBoxList] = useState<MysteryBox[]>(elementData.content.mysteryBoxList);
    const [currentMysteryBoxId, setCurrentMysteryBoxId] = useState<number>(elementData.content.currentMysteryBoxId);

    useEffect(() => {
        setMysteryBoxList(elementData.content.mysteryBoxList);
    }, [elementData.content.mysteryBoxList])

    const openBlindBox = async () => {
        if (isAnimating) return;
        setIsAnimating(true);
        const startTime = Date.now();

        try {
            const res: any = await getBlindBoxV2();
            const endTime = Date.now();

            if (res.status === "success") {
                setBlindBoxImgUrl(res.data.url);
                const duration = Math.max(2000 - (endTime - startTime), 0);

                setTimeout(() => {
                    const newMyMysteryBoxList = cloneDeep(mysteryBoxList); // 深拷贝到新的变量  

                    // 查找当前选中的盲盒  
                    const currentMysteryBox = newMyMysteryBoxList.find(box => box.mysteryBoxId === currentMysteryBoxId);

                    if (currentMysteryBox) {
                        const holdingIndex = currentMysteryBox.holdingList.findIndex(h => h.name === res.data.name);

                        // 更新holdingList的逻辑  
                        if (holdingIndex > -1) {
                            // 如果存在则数量 +1  
                            currentMysteryBox.holdingList[holdingIndex].number += 1;
                        } else {
                            // 不存在则添加新条目  
                            currentMysteryBox.holdingList.push(new Holding({
                                name: res.data.name,
                                imgUrl: res.data.url,
                                number: 1
                            }));
                        }
                    } else {
                        newMyMysteryBoxList.push(new MysteryBox({
                            mysteryBoxId: currentMysteryBoxId,
                            holdingList: [new Holding({
                                name: res.data.name,
                                imgUrl: res.data.url,
                                number: 1
                            })] // 将新获得的盲盒内容添加到 holdingList  
                        }));
                    }

                    // 更新狀態  
                    setMysteryBoxList(newMyMysteryBoxList);
                    setIsAnimating(false);
                    const newBlockTraceData = cloneDeep(blockTraceData);
                    const currentElementData = newBlockTraceData.traceInfo.find(item => item.id === elementData.id);
                    if (currentElementData && 'mysteryBoxList' in currentElementData.content) {
                        (currentElementData.content as BlindBoxRegionContent).mysteryBoxList = newMyMysteryBoxList
                    }
                    lectureStore.updateElement(blockTraceData.id, elementData.id, currentElementData!.content);
                    service.put('/block_trace', newBlockTraceData);
                }, duration);
            } else {
                setIsAnimating(false);
            }
        } catch (error) {
            console.error('Error getting blind box', error);
            setIsAnimating(false);
        }
    }
    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.blindBoxBox}>
                    <div className={styles.container}>
                        <div className={styles.main}>
                            {!isAnimating && (
                                <div className={styles.blindBox}>
                                    {blindBoxImgUrl && (
                                        <img src={blindBoxImgUrl} alt="盲盒内容" className={styles.resultImage} />
                                    )}
                                    {!blindBoxImgUrl && (
                                        <div className={styles.blindBoxCover}>
                                            <img
                                                src={BlindBoxV2Cover}
                                                alt="盲盒封面"
                                            />
                                        </div>
                                    )}
                                    <div className={styles.openBtn} onClick={openBlindBox}>
                                        开启盲盒
                                    </div>
                                </div>
                            )}
                            {isAnimating && (
                                <div className={styles.blindBoxAnimate}>
                                    <Lottie
                                        animationData={blindAnimation}
                                        loop={false}
                                        style={{ width: "700rem", height: '100%' }}
                                    />
                                </div>
                            )}


                        </div>
                        <div className={styles.haveGot}>
                            <div className={styles.haveGotList}>
                                {['Gold', 'Silver', 'Bronze', 'Iron'].map((type) => {
                                    const currentItem = mysteryBoxList
                                        ?.find((box) => box.mysteryBoxId === currentMysteryBoxId)
                                        ?.holdingList.find((holding) => holding.name === type);
                                    return currentItem?.imgUrl ? (
                                        <div className={styles.haveGotItem} key={type}>
                                            {Array.from({ length: currentItem.number }, (_, index) => (
                                                <img
                                                    key={index}
                                                    src={currentItem.imgUrl}
                                                    alt={`盲盒内容 ${currentItem.name}`}
                                                />
                                            ))}
                                        </div>
                                    ) : null;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default observer(BlindBoxRegion);