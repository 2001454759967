import AIChatRegionContent from './AIChatRegionContent';

class AIChatAgentRegionContent extends AIChatRegionContent {
    isCreatedChatAgent: boolean;
    agentMode: string;
    agentRole: string;
    agentSkill: string;
    agentRule: string;
    roleLectureVar: string;
    skillLectureVar: string;
    ruleLectureVar: string;
    agentLinkLectureVar: string;
    agentShareLink: string;
    bindResourceObj: Record<string, any> | null;

    constructor(options: Partial<AIChatAgentRegionContent> = {}) {
        const {
            isCreatedChatAgent = false,
            agentMode = 'basic',
            agentRole = '',
            agentSkill = '',
            agentRule = '',
            roleLectureVar = '',
            skillLectureVar = '',
            ruleLectureVar = '',
            agentLinkLectureVar = '',
            agentShareLink = '',
            bindResourceObj = null,
            ...restOptions
        } = options;
        super(restOptions);
        this.allowedFields.push('isCreatedChatAgent', 'agentMode', 'agentRole', 'agentSkill', 'agentRule',
            'roleLectureVar', 'skillLectureVar', 'ruleLectureVar', 'agentLinkLectureVar', 'agentShareLink',
            'bindResourceObj');
        this.isCreatedChatAgent = isCreatedChatAgent;
        this.agentMode = agentMode;
        this.agentRole = agentRole;
        this.agentSkill = agentSkill;
        this.agentRule = agentRule;
        this.roleLectureVar = roleLectureVar;
        this.skillLectureVar = skillLectureVar;
        this.ruleLectureVar = ruleLectureVar;
        this.agentLinkLectureVar = agentLinkLectureVar;
        this.agentShareLink = agentShareLink;
        this.bindResourceObj = bindResourceObj;
    }
}

export default AIChatAgentRegionContent;