import React, { useEffect, useState, useRef } from 'react';
import styles from "./aIChatAgentRegion.module.less";
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import AIChatAgentRegionContent from '@/base/ElementData/AIChatAgentRegionContent';
import { BlockTraceData } from '@/base/BlockData';
import CreateAgent from './CreateAgent/CreateAgent';
import CreateBasicAgent from './CreateBasicAgent/CreateBasicAgent';
import HiLogo from '@/assets/img/chat_region_hi.svg';
import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import ChatBubble from '../AIChatRegion/ChatBubble';
import useChat from '@/hooks/useChat';
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import SendDisableIcon from '@/assets/img/chat_component/send_disable.svg';
import SendAbleIcon from '@/assets/img/chat_component/send_able.svg';
import MoreOperationIcon from '@/assets/img/chat_component/more_operation.svg';
import { message, Popover } from 'antd';
import FingerClickIcon from '@/assets/img/playground/finger_click.svg';
import service from '@/services/axios';
import { ChatMessage } from "@/base/ChatMessage";
import autosize from 'autosize';
import AudioPlayingIcon from '@/assets/img/chat_component/audio_playing.svg';
import StopAudioIcon from '@/assets/img/chat_component/stop_audio.svg';
import PlayAudioIcon from '@/assets/img/chat_component/play_audio.svg';

interface AIChatAgentRegionProps extends IntrinsicElementProps<AIChatAgentRegionContent> {
    blockTraceData: BlockTraceData;
}

const AIChatAgentRegion: React.FC<AIChatAgentRegionProps> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
    blockTraceData,
}) => {
    const { ttsStore, commonStatusStore, lectureStore, audioStore } = useStores();
    const [editType, setEditType] = useState('create');
    const [isEditing, setIsEditing] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
    const isComponentMounted = useRef<boolean | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const { inputValue, setInputValue, handleSend, handleKeyDown } = useChat(blockTraceData, elementData);
    const { userMessageAvatar = "" } = elementData.content;
    const [isAtBottom, setIsAtBottom] = useState(true); // 跟踪用户是否在底部  
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [isHoveredAudioPlayingIcon, setIsHoveredAudioPlayingIcon] = useState(false);

    useEffect(() => {
        if (elementData.content.isCreatedChatAgent) {
            setIsEditing(false);
        } else {
            setIsEditing(true);
        }
    }, [elementData.content.isCreatedChatAgent]);

    const sendBtnSrc = () => {
        return inputValue === "" ? SendDisableIcon : SendAbleIcon;
    };

    useEffect(() => {
        const container = chatContainerRef.current;

        const handleScroll = () => {
            if (container) {
                const { scrollTop, scrollHeight, clientHeight } = container;
                setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 100); // 检查是否在底部  
            }
            setIsScrolling(true);
            // 清除之前的定时器  
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
            // 设置在滚动后 1 秒隐藏滚动条  
            timeoutId.current = setTimeout(() => {
                setIsScrolling(false);
            }, 1000); // 1秒后隐藏滚动条  
        };

        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [chatContainerRef.current]);

    //自动滚动
    const scrollChatContainer = () => {
        if (chatContainerRef.current) {
            const { current: container } = chatContainerRef;
            // 使用scrollTo方法进行平滑滚动
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth' // 这个设置能使滚动行为变得平滑
            });
        }
    }

    useEffect(() => {
        const playAudio = () => {
            if (ttsStore.audioSegments.length > 0) {
                audioRef.current = new Audio(`data:audio/mp3;base64,${ttsStore.audioSegments[0]}`);
                audioRef.current.play().then(() => {
                    ttsStore.setPlaying(true);
                }).catch((error) => {
                    console.log('播放音频时出错：', error);
                    ttsStore.removeAudioSegment();
                    ttsStore.setPlaying(false);
                });
                ttsStore.setPlaying(true);
                audioRef.current.onended = () => {
                    ttsStore.removeAudioSegment();
                    ttsStore.setPlaying(false);
                };
            }
        };

        if (!ttsStore.isPlaying && ttsStore.audioSegments.length > 0) {
            playAudio(); // only start a new audio when there is no audio currently playing
        }
    }, [ttsStore.audioSegments.length, ttsStore.isPlaying]);

    useEffect(() => {
        isComponentMounted.current = true;
        // 初始自动滚动到底部  
        scrollChatContainer();
        return () => {
            isComponentMounted.current = false;
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
            ttsStore.resetTTSStore();
        }
    }, [lectureStore.currentBlockIndex])

    useEffect(() => {
        if (audioStore.currentAudioEleId !== elementData.id && audioRef.current) {
            audioRef.current.pause();
            audioRef.current = null;
            ttsStore.resetTTSStore();
            ttsStore.setPlaying(false);//为了让自动播放终止
        }
    }, [audioStore.currentAudioEleId])

    useEffect(() => {
        if (ttsStore.userNeedPause) {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
                ttsStore.resetTTSStore();
                ttsStore.setPlaying(false);
            }
        }
    }, [ttsStore.userNeedPause])

    // 添加useEffect以处理自动滚动
    useEffect(() => {
        if (isComponentMounted.current && isAtBottom) {
            scrollChatContainer(); // 只有在用户在底部时才自动滚动  
        }
    }, [elementData, commonStatusStore.isTeacherSpeaking, commonStatusStore.isFetching]);

    useEffect(() => {
        if (textareaRef.current) {
            autosize(textareaRef.current);
        }

        return () => {
            if (textareaRef.current) {
                autosize.destroy(textareaRef.current);
            }
        };
    }, [inputValue]);

    const clearChatList = async () => {
        if (elementData.content.chatList.length === 0) {
            message.info('当前聊天记录为空');
            setPopoverVisible(false);
            return;
        }
        const newBlockTraceData = cloneDeep(blockTraceData);
        const currentElementData = newBlockTraceData.traceInfo.find(item => item.id === elementData.id);
        if (currentElementData) {
            (currentElementData.content as AIChatAgentRegionContent).chatList = [];
            try {
                const res: any = await service.put('/block_trace', newBlockTraceData);
                if (res.status === "success") {
                    lectureStore.updateElement(blockTraceData.id, elementData.id, currentElementData.content);
                    setPopoverVisible(false);
                }
            } catch (error) {
                message.error('清空聊天记录失败');
                console.error(error);
            }
        }
    }

    const moreOperation = () => {
        // 更多操作,包括回到编辑状态和清空聊天记录
        return (
            <div className={styles.moreOperationsList}>
                <div className={styles.operationItem} onClick={() => {
                    setIsEditing(true);
                    setEditType('edit');
                    setPopoverVisible(false);
                }}>
                    编辑智能体
                </div>
                <div className={styles.operationItem} onClick={() => {
                    clearChatList();
                }}>
                    清空聊天记录
                </div>
            </div>
        );
    }

    const showAudioIcon = () => {
        if (ttsStore.isPlaying || commonStatusStore.isFetching && elementData.content.shouldDoTts && !ttsStore.userNeedPause) {
            return <img
                src={isHoveredAudioPlayingIcon ? StopAudioIcon : AudioPlayingIcon}
                alt="audioPlaying"
                className={styles.audioPlayingIcon}
                onClick={() => {
                    ttsStore.setUserNeedPause(true);
                    setIsHoveredAudioPlayingIcon(false);
                }}
                onMouseEnter={() => setIsHoveredAudioPlayingIcon(true)}
                onMouseLeave={() => setIsHoveredAudioPlayingIcon(false)}
            />;
        } else if (!ttsStore.isPlaying && commonStatusStore.isFetching && ttsStore.userNeedPause) {
            return <></>;
        } else if (!ttsStore.isPlaying && !commonStatusStore.isFetching && ttsStore.userNeedPause) {
            return <img
                src={StopAudioIcon}
                alt="audioPlaying"
                className={styles.audioPlayingIcon}
                onClick={() => { ttsStore.setUserNeedPause(false) }}
            />;
        } else if (!ttsStore.isPlaying && !commonStatusStore.isFetching && !ttsStore.userNeedPause) {
            return <img
                src={PlayAudioIcon}
                alt="audioPlaying"
                className={styles.audioPlayingIcon}
                onClick={() => { ttsStore.setUserNeedPause(true) }}
            />;
        }
    }

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.aIChatAgentBox}>
                    {isEditing && elementData.content.agentMode === 'basic' &&
                        <div className={styles.createAgentBox}>
                            <CreateBasicAgent
                                editType={editType}
                                blockTraceData={blockTraceData}
                                elementData={elementData}
                                setIsEditing={setIsEditing}
                            />
                        </div>
                    }
                    {
                        isEditing && elementData.content.agentMode === 'advanced' &&
                        <div className={styles.createAgentBox}>
                            <CreateAgent
                                editType={editType}
                                blockTraceData={blockTraceData}
                                elementData={elementData}
                                setIsEditing={setIsEditing}
                            />
                        </div>
                    }
                    {!isEditing &&
                        <div className={styles.chatBox}>
                            {showAudioIcon()}
                            <div className={styles.chatHead}>
                                <img src={HiLogo} className={styles.chatLogo} />
                                <div
                                    className={styles.chatTitle}
                                    style={{
                                        color: `${elementData.content.titleColor}`,
                                        fontSize: `${elementData.content.titleFontSize}px`
                                    }}
                                >
                                    {elementData.content.agentName || "大语言模型对话"}
                                </div>
                                <Popover
                                    placement="bottom"
                                    content={moreOperation()}
                                    trigger="click"
                                    overlayClassName={styles.moreOperationsPopover}
                                    open={popoverVisible} // 使用状态控制Popover的显示
                                    onOpenChange={(visible) => setPopoverVisible(visible)}
                                >
                                    <div className={styles.moreOperationBtn}>
                                        <img src={MoreOperationIcon} />
                                    </div>
                                </Popover>
                            </div>
                            <div className={`${styles.chatContentBox} ${isScrolling ? styles.scrolling : ''}`} ref={chatContainerRef}>
                                <div className={styles.aiChat} >
                                    {elementData.content?.chatList.length === 0 && (
                                        <>
                                            {elementData.content?.presetMessages.map((item: any, index: number) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={styles.presetQuestion}
                                                        onClick={() => {
                                                            const newChatMessage = new ChatMessage({
                                                                ...item,
                                                                type: 3,
                                                                responseMethod: "gpt",
                                                                stream: elementData.content.isStream,

                                                            });
                                                            handleSend(newChatMessage, -1, -1);
                                                        }}
                                                    >
                                                        <div>{item.rawContent}</div>
                                                        <div>
                                                            <img
                                                                src={FingerClickIcon}
                                                                className={styles.fingerClickIcon}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )}
                                    {
                                        elementData.content?.chatList?.map((item, index) => {
                                            const newItem = cloneDeep(item);
                                            return (
                                                <ChatBubble
                                                    key={index}
                                                    item={newItem}
                                                    role="gpt"
                                                    userMessageAvatar={userMessageAvatar}
                                                    gptAvatar={elementData.content.agentAvatar}
                                                    //如果是最后一个消息，并且ttsStore的isPlaying为true，则显示正在播放的图标
                                                    isThisMsgPlaying={index === elementData.content.chatList.length - 1
                                                        && (ttsStore.isPlaying || commonStatusStore.isFetching && elementData.content.shouldDoTts && !ttsStore.userNeedPause)
                                                        && elementData.content.chatTools?.length === 0
                                                    }
                                                    scene={commonStatusStore.isFetching && index === elementData.content.chatList.length - 1 ? "playground_loading" : ""}
                                                />
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className={styles.inputAreaBox}>
                                <div
                                    className={`${styles.box} ${commonStatusStore.buttonFlashing ? styles.borderFlashing : ''}`}
                                >
                                    <textarea
                                        className={` ${styles.autoInputArea}`}
                                        placeholder="输入消息..."
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        ref={textareaRef}
                                    />

                                    <div className={styles.btnBox}>
                                        <div className={styles.microphoneBtn}>
                                            <SpeechToText
                                                setInputMessage={(message: string) => setInputValue(message)}
                                                inputMessage={inputValue}
                                            />
                                        </div>
                                        <div className={`${styles.sendBtn} ${sendBtnSrc() === SendAbleIcon ? styles.sendBtnFlashing : ''}`}>
                                            <img
                                                src={sendBtnSrc()}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const newChatMessage = new ChatMessage({
                                                        type: 1,
                                                        rawContent: inputValue,
                                                        responseMethod: "gpt",
                                                        stream: elementData.content.isStream
                                                    });
                                                    handleSend(newChatMessage, -1, -1);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default observer(AIChatAgentRegion);