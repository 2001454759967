import { useRef, useState, useEffect } from 'react';
import styles from "./customGamesRegion.module.less";
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement, { IntrinsicElementProps } from '../common/BaseDragableElement';
import CustomGamesRegionContent from '@/base/ElementData/CustomGamesRegionContent';
import { useMediaQuery } from 'react-responsive';

const CustomGamesRegion: React.FC<IntrinsicElementProps<CustomGamesRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete
}) => {
    const isPad = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1280px)' });
    const isPC = useMediaQuery({ query: '(min-width: 1281px)' });
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const [modifiedHtml, setModifiedHtml] = useState(elementData.content.originalCode);

    useEffect(() => {
        const scaleFactor = isPad ? 0.78 : (isPC ? 1 : 1); // Default to 1 if neither isPad nor isPC
        const modifiedCode = scalePxValues(elementData.content.originalCode, scaleFactor);
        setModifiedHtml(modifiedCode);
    }, [isPad, isPC, elementData.content.originalCode]);

    const handleIframeLoad = () => {
        const iframe = iframeRef.current;
        if (!iframe) return;

        const contentWindow = iframe.contentWindow;
        if (!contentWindow) return;

        // 发送当前 isPad 值
        contentWindow.postMessage({ isPad }, '*');
    };

    const scalePxValues = (html: string, scaleFactor: number): string => {
        const styleTagRegex = /<style[^>]*>([\s\S]*?)<\/style>/i;
        const styleMatch = html.match(styleTagRegex);

        if (styleMatch && styleMatch[1]) {
            const originalStyles = styleMatch[1];
            const scaledStyles = originalStyles.replace(/(\d+)px/g, (match, p1) => {
                const scaledValue = parseFloat(p1) * scaleFactor;
                return `${scaledValue}px`;
            });

            const modifiedHtml = html.replace(styleTagRegex, `<style>${scaledStyles}</style>`);
            return modifiedHtml;
        }

        return html;
    };

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
                {...(isEditable && { 'data-findparent': 'findparent' })}
            >
                <div className={styles.customGamesBox}>
                    <iframe
                        ref={iframeRef}
                        className={styles.iframe}
                        srcDoc={modifiedHtml}
                        onLoad={handleIframeLoad}
                    />
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default CustomGamesRegion;