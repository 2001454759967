import CMSAPI from '@/api/cms';
import { ElementContent, ElementData } from "@/base/ElementData/ElementData";
import TextRegionContent from "@/base/ElementData/TextRegionContent";
import ImageRegionContent from "@/base/ElementData/ImageRegionContent";
import ButtonRegionContent from "@/base/ElementData/ButtonRegionContent";
import AdvancedButtonRegionContent from "@/base/ElementData/AdvancedButtonRegionContent";
import BtnTextGroupRegionContent from "@/base/ElementData/BtnTextGroupRegionContent";
import BtnGenericGroupRegionContent from "@/base/ElementData/BtnGenericGroupRegionContent";
import VideoRegionContent from "@/base/ElementData/VideoRegionContent";
import AudioRegionContent from "@/base/ElementData/AudioRegionContent";
import SunoGenContent from "@/base/ElementData/SunoGenContent";
import AIChatRegionContent from "@/base/ElementData/AIChatRegionContent";
import TeacherWordsRegionContent from "@/base/ElementData/TeacherWordsRegionContent";
import LineRegionContent from "@/base/ElementData/LineRegionContent";
import RatingRegionContent from "@/base/ElementData/RatingRegionContent";
import CodeRegionContent from "@/base/ElementData/CodeRegionContent";
import QRCodeRegionContent from "@/base/ElementData/QRCodeRegionContent";
import {DragClassifyRegionContent} from "@/base/ElementData/DragClassifyRegionContent";
import ShowCodeRegionContent from "@/base/ElementData/ShowCodeRegionContent";
import FlashCardRegionContent from "@/base/ElementData/FlashCardRegionContent";
import { LineConnectorRegionContent } from '@/base/ElementData/LineConnectorRegionContent';
import ShareWithSoundRegionContent from '@/base/ElementData/ShareWithSoundRegionContent';
import TreasureHuntRegionContent from '@/base/ElementData/TreasureHuntRegionContent';
import RollingBallRegionContent from '@/base/ElementData/RollingBallRegionContent';
import NeuronCalculatorRegionContent from '@/base/ElementData/NeuronCalculatorRegionContent';
import NNVisRegionContent from '@/base/ElementData/NNVisRegionContent';
import TrainingLogRegionContent from '@/base/ElementData/TrainingLogRegionContent';
import BlindBoxRegionContent from '@/base/ElementData/BlindBoxRegionContent';
import TokenizerRegionContent from '@/base/ElementData/TokenizerRegionContent';
import WordGeneratorRegionContent from '@/base/ElementData/WordGeneratorRegionContent';
import GenCustomMusicRegionContent from '@/base/ElementData/GenCustomMusicRegionContent';
import WordCoordinateRegionContent from '@/base/ElementData/WordCoordinateRegionContent';
import AIChatAgentRegionContent from '@/base/ElementData/AIChatAgentRegionContent';
import CustomGamesRegionContent from '@/base/ElementData/CustomGamesRegionContent';
import AdvancedButtonRegion from "../ButtonRegion/AdvancedButtonRegion";
import DataDefinition from '@/base/DataDefinition';
import { initElementContentFromTemplate } from '@/base/DataObjectGenerator';


// 这个应该只用于新建
export function createElementContent(type: number, props: any) {
    switch (type) {
        case 101:
            // 全部使用默认值
            return new TextRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 102:
            return new ImageRegionContent({positionX: props.positionX, positionY: props.positionY, src: props.imgUrl});
        case 103:
            return new ButtonRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 104:
            return new AdvancedButtonRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 105:
            return new BtnTextGroupRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 106:
            return new BtnGenericGroupRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 107:
            return new VideoRegionContent({positionX: props.positionX, positionY: props.positionY, src: props.videoUrl});
        case 108:
            return new AudioRegionContent({positionX: props.positionX, positionY: props.positionY, src: props.audioUrl});
        case 109:
            return new SunoGenContent({positionX: props.positionX, positionY: props.positionY});
        case 110:
            return new AIChatRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 111:
            return new TeacherWordsRegionContent({positionX: props.positionX, positionY: props.positionY});
        //case 112:
        //    return new MagicInputRegionContent();
        case 113:
            return new LineRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 114:
            return new RatingRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 115:
            return new CodeRegionContent({positionX: props.positionX, positionY: props.positionY});
        //case 116:
        //    return new FillInTheBlankRegionContent();
        case 117:
            return new QRCodeRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 118:
            return new DragClassifyRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 119:
            return new ShowCodeRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 120:
            return new FlashCardRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 121:
            return new LineConnectorRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 122:
            return new ShareWithSoundRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 123:
            return new TreasureHuntRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 124:
            return new RollingBallRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 125:
            return new NeuronCalculatorRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 126:
            return new NNVisRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 127:
            return new TrainingLogRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 128:
            return new BlindBoxRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 129:
            return new TokenizerRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 130:
            return new WordGeneratorRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 131:
            return new GenCustomMusicRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 132:
            return new WordCoordinateRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 133:
            return new AIChatAgentRegionContent({positionX: props.positionX, positionY: props.positionY});
        case 134:
            return new CustomGamesRegionContent({positionX: props.positionX, positionY: props.positionY});
        default:
            return new ElementContent(0,0,0,0,0);
    }
}

export async function initElementFromTemplateAsync(templateUrl: string, 
                            defaultItemProps: any, 
                            curMaxZIndex: number, 
                            itemId: number) {
    // type目前只处理103/104/105, 目前不处理其他类型
    const api = new CMSAPI();
    const templateJson = await api.readJson(templateUrl);
    let elementData: ElementData<ElementContent>;
    templateJson.template = DataDefinition.toCamelCaseObj(templateJson.template);

    switch (templateJson.type) {
        case 103:
            elementData = new ElementData<ButtonRegionContent>({
                id: itemId,
                type: 103,
                content: ButtonRegionContent.duplicate(templateJson.template as ButtonRegionContent, 
                                            defaultItemProps.positionX, defaultItemProps.positionY, 
                                            curMaxZIndex + 1),
                isFocus: true
            });
            return elementData;
        case 104:
            elementData = new ElementData<AdvancedButtonRegionContent>({
                id: itemId,
                type: 104,
                content: initElementContentFromTemplate(templateJson.template as AdvancedButtonRegionContent,  AdvancedButtonRegionContent,
                                            defaultItemProps.positionX, defaultItemProps.positionY, 
                                            curMaxZIndex + 1),
                isFocus: true
            });
            return elementData;
        case 105:
            elementData = new ElementData<BtnTextGroupRegionContent>({
                id: itemId,
                type: 105,
                content: initElementContentFromTemplate(templateJson.template as BtnTextGroupRegionContent,  BtnTextGroupRegionContent,
                                            defaultItemProps.positionX, defaultItemProps.positionY, 
                                            curMaxZIndex + 1),
                isFocus: true
            });
            return elementData;
        case 106:
            elementData = new ElementData<BtnGenericGroupRegionContent>({
                id: itemId,
                type: 106,
                content: initElementContentFromTemplate(templateJson.template as BtnGenericGroupRegionContent,  BtnGenericGroupRegionContent,
                                            defaultItemProps.positionX, defaultItemProps.positionY, 
                                            curMaxZIndex + 1),
                isFocus: true
            });
            return elementData;
    }
}

