import service from "@/services/axios";

const ApiPath = {
    BLIND_BOX: '/blind_box',
    BLIND_BOX_V2: '/blind_box_v2',
    CHECK_PAYMENT_BY_PHONE: (phone: number) => `/check_payment_by_phone/${phone}`,
}

export const getBlindBox = (): Promise<any> => {
    return service.post(ApiPath.BLIND_BOX);
}

export const getBlindBoxV2 = (): Promise<any> => {
    return service.post(ApiPath.BLIND_BOX_V2);
}

export const getUserAllPayments = (phone: number): Promise<any> => {
    return service.get(ApiPath.CHECK_PAYMENT_BY_PHONE(phone));
}