import React from 'react';
import styles from "./wordGeneratorModifier.module.less";
import { InputNumber, Button, Select, Input } from 'antd';

interface WordGeneratorModifierProps {
    focusedItem: any;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
}

const { Option } = Select;
const { TextArea } = Input;

const WordGeneratorModifier: React.FC<WordGeneratorModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {
    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>类型</div>
                <Select
                    value={focusedItem.content.type}
                    style={{ width: 180 }}
                    onChange={(value) => onInputChange(focusedItem.id, 'type', value)}
                >
                    <Option value="basic">basic</Option>
                    <Option value="selectable">生成的文字可以选择</Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>默认文字</div>
                <TextArea
                    value={focusedItem.content.defaultText}
                    onChange={(e) => onInputChange(focusedItem.id, 'defaultText', e.target.value)}
                    className={styles.widthInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>温度</div>
                <InputNumber
                    value={focusedItem.content.temperature}
                    onChange={value => onInputChange(focusedItem.id, 'temperature', value)}
                    className={styles.widthInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>
        </div>
    )
}

export default WordGeneratorModifier;
