import React from 'react';
import styles from "./tokenizerRegionModifier.module.less";
import { InputNumber, Button } from 'antd';

interface TokenizerRegionModifierProps {
    focusedItem: any;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
}

const TokenizerRegionModifier: React.FC<TokenizerRegionModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {
    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>
        </div>
    )
}

export default TokenizerRegionModifier;
