import React, { useEffect, useState } from 'react';
import styles from "./wordCoordinateRegion.module.less";
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import WordCoordinateRegionContent from "@/base/ElementData/WordCoordinateRegionContent";
import service from '@/services/axios';
import { Button, message } from 'antd';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, CategoryScale, LinearScale } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation'; // 导入注解插件  
import CancelIcon from '@/assets/img/chat_component/cancel_fill_in_blank.svg';

// 注册 Chart.js 组件  
ChartJS.register(Title, Tooltip, Legend, PointElement, CategoryScale, LinearScale, annotationPlugin);  // 注册插件  

const WordCoordinateRegion: React.FC<IntrinsicElementProps<WordCoordinateRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
}) => {
    const [inputText, setInputText] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [coordinates, setCoordinates] = useState<number[][]>([]);
    const [termsArray, setTermsArray] = useState<string[]>(["你", "我", "猫", "狗"]);
    const [genTermsArray, setGenTermsArray] = useState<string[]>([]);

    // 生成坐标并请求后端  
    const handleGenerate = async () => {
        if (termsArray.length < 2) {
            message.error('请至少输入两个词语');
            return;
        }
        try {
            setIsFetching(true);
            const res: any = await service.post('/embeddings', termsArray);
            if (res.status === 'success') {
                setCoordinates(res.data);  // 更新坐标数据  
                setGenTermsArray(termsArray);
                setIsFetching(false);
            } else {
                console.error('API Error:', res.data);
                setIsFetching(false);
            }
        } catch (error) {
            console.error('API Error:', error);
            setIsFetching(false);
        }
    };

    // 配置 Chart.js 数据  
    const chartData = {
        datasets: [
            {
                label: '词语坐标',
                data: coordinates.map((coord, index) => ({
                    x: coord[0], // x 坐标  
                    y: coord[1], // y 坐标  
                    label: `字符 ${index + 1}`, // 显示字符位置  
                })),
                backgroundColor: 'rgba(0, 123, 255, 0.6)',
                borderColor: 'rgba(0, 123, 255, 1)',
                borderWidth: 1,
                pointStyle: 'circle',  // 设置数据点样式
                pointRadius: 5,        // 数据点半径
                pointHoverRadius: 7,   // 悬停时半径
            },
        ],
    };

    // 配置图表选项  
    const chartOptions = {
        responsive: true,
        aspectRatio: 1,  // 设置宽高比为 1，确保是正方形   
        plugins: {
            tooltip: {
                usePointStyle: true,
                boxWidth: 10,
                boxPadding: 4,
                callbacks: {
                    label: (context: any) => {
                        const point = context.raw;
                        return `(${point.x.toFixed(2)}, ${point.y.toFixed(2)})`;
                    }
                }
            },
            legend: { // 添加图例配置
                labels: {
                    usePointStyle: true,
                    boxWidth: 10,
                    pointStyleWidth: 15,
                    pointStyleHeight: 15,
                }
            },
            annotation: { // 使用注解插件显示文本  
                annotations: coordinates.map((coord, index) => ({
                    type: 'label' as const,
                    xValue: coord[0],
                    yValue: coord[1],
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    content: genTermsArray[index],  // 显示字符  
                    font: {
                        size: 14,
                        family: 'Arial',
                    },
                    color: 'black',
                    xAdjust: 0,      // 水平居中
                    yAdjust: -15,    // 向上偏移15像素
                })),
            },
        },
        scales: {
            x: {
                min: -0.8, // 设置 x 轴范围  
                max: 0.8,
            },
            y: {
                min: -0.8, // 设置 y 轴范围  
                max: 0.8,
            },
        },
    };

    const deleteTerm = (index: number) => {
        const newTermsArray = [...termsArray];
        newTermsArray.splice(index, 1);
        setTermsArray(newTermsArray);
    }

    const addTerm = (term: string) => {
        if (term) {
            setTermsArray([...termsArray, term]);
            setInputText('');
        }
    }

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.wordCoordinateBox}>
                    <div className={styles.genCoordinate}>
                        <div className={styles.termBox}>
                            {
                                termsArray.length === 0 && (
                                    <div className={styles.noTermsTip}>
                                        至少输入两个词语
                                    </div>
                                )
                            }
                            {termsArray.map((term, index) => (
                                <span key={index} className={styles.termItem}>
                                    {term}
                                    <div
                                        className={styles.cancelIcon}
                                        onClick={() => deleteTerm(index)}
                                    >
                                        <img src={CancelIcon} alt="cancel" />
                                    </div>
                                </span>
                            ))}
                        </div>
                        <div className={styles.inputAreaBox}>
                            <input
                                className={styles.inputArea}
                                placeholder="请输入词语"
                                value={inputText}
                                onChange={e => setInputText(e.target.value)}
                            />
                            <Button
                                className={styles.addBtn}
                                onClick={() => addTerm(inputText)}
                            >
                                添加词语
                            </Button>
                        </div>
                        <div className={styles.btnBox}>
                            <Button
                                className={styles.genBtn}
                                onClick={handleGenerate}
                                loading={isFetching}
                            >
                                可视化位置关系
                            </Button>
                        </div>
                    </div>
                    <div className={styles.coordinateBox}>
                        <Scatter
                            data={chartData}
                            options={chartOptions}
                        />
                    </div>
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default WordCoordinateRegion;