import React, { useState } from 'react';
import styles from "./aiChatAgentModifier.module.less";
import { InputNumber, Button, Input, Select, Modal, message } from 'antd';
import { cloneDeep } from 'lodash';
import { ChatMessage } from '@/base/ChatMessage';
import DocumentData from '@/base/DocumentData';
import mammoth from 'mammoth';

const { TextArea } = Input;

interface AIChatAgentModifierProps {
    focusedItem: any;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
}

const AIChatAgentModifier: React.FC<AIChatAgentModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {
    const [presetMessages, setPresetMessages] = useState(cloneDeep(focusedItem.content?.presetMessages || []));
    const [documentList, setDocumentList] = useState(cloneDeep(focusedItem.content?.documentList || []));
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputDocumentUrl, setInputDocumentUrl] = useState('');
    const [inputDocumentName, setInputDocumentName] = useState('');
    //绑定数据
    const [bindResourceStr, setBindResourceStr] = useState(focusedItem.content.bindResourceObj ?
        JSON.stringify(focusedItem.content.bindResourceObj, null, 4) : '');

    const handleBindResource = (value: string) => {
        if (!value) {
            onInputChange(focusedItem.id, 'bindResourceObj', null);
            return;
        }
        try {
            const bindResourceObj = JSON.parse(value);
            onInputChange(focusedItem.id, 'bindResourceObj', bindResourceObj);
        } catch (error) {
            message.error('JSON格式错误');
        }
    }

    //添加预设消息
    const addPresetMessage = () => {
        const newPresetMessages = cloneDeep(presetMessages);
        newPresetMessages.push(new ChatMessage({
            type: 2,
            rawContent: "预设问题",
            responseMethod: "gpt",
            stream: true,
        }));
        setPresetMessages(newPresetMessages);
        onInputChange(focusedItem.id, 'presetMessages', newPresetMessages);
    }

    //删除预设消息
    const deletePresetMessage = (messageId: number) => {
        const newPresetMessages = cloneDeep(presetMessages);
        const messageIndex = newPresetMessages.findIndex((item: any) => item.id === messageId);
        newPresetMessages.splice(messageIndex, 1);
        setPresetMessages(newPresetMessages);
        onInputChange(focusedItem.id, 'presetMessages', newPresetMessages);
    }

    //更新预设消息
    const updatePresetMessage = (messageId: number, key: keyof ChatMessage, value: any) => {
        const newPresetMessages = cloneDeep(presetMessages);
        const messageIndex = newPresetMessages.findIndex((item: any) => item.id === messageId);
        newPresetMessages[messageIndex].setField(key, value);
        setPresetMessages(newPresetMessages);
        onInputChange(focusedItem.id, 'presetMessages', newPresetMessages);
    }

    // 从链接中获取文档名
    const getDocNameFromUrl = (url: string) => {
        return url.split('/').pop();
    };

    // 从链接中获取文档内容
    const getDocContentFromUrl = async (url: string) => {
        try {
            if (url.endsWith('.txt')) {
                const response = await fetch(url);
                const text = await response.text();
                return text;
            } else if (url.endsWith('.docx')) {
                const response = await fetch(url);
                const arrayBuffer = await response.arrayBuffer();
                const result = await mammoth.extractRawText({ arrayBuffer });
                return result.value
            } else {
                message.error('不支持的文档格式');
                return '';
            }
        } catch (error) {
            message.error('获取文档内容失败');
            console.error('获取文档内容失败:', error);
            return;
        }
    };

    // 添加知识库文档
    const addDocument = async () => {
        if (!inputDocumentUrl) return;
        const docName = inputDocumentName || getDocNameFromUrl(inputDocumentUrl);
        const docContent = await getDocContentFromUrl(inputDocumentUrl);
        if (!docContent) return;
        const newDocument = new DocumentData({
            docName,
            docContent,
            docUrl: inputDocumentUrl
        });
        const newDocumentList = cloneDeep(documentList);
        newDocumentList.push(newDocument);
        setDocumentList(newDocumentList);
        onInputChange(focusedItem.id, 'documentList', newDocumentList);
        setIsModalVisible(false);
        setInputDocumentUrl('');
    };

    //删除知识库文档
    const deleteDocument = (documentId: number) => {
        const newDocumentList = cloneDeep(documentList);
        const documentIndex = newDocumentList.findIndex((item: any) => item.id === documentId);
        newDocumentList.splice(documentIndex, 1);
        setDocumentList(newDocumentList);
        onInputChange(focusedItem.id, 'documentList', newDocumentList);
    }

    //更新知识库文档
    const updateDocument = (documentId: number, key: keyof DocumentData, value: any) => {
        const newDocumentList = cloneDeep(documentList);
        const documentIndex = newDocumentList.findIndex((item: any) => item.id === documentId);
        newDocumentList[documentIndex].setField(key, value);
        setDocumentList(newDocumentList);
        onInputChange(focusedItem.id, 'documentList', newDocumentList);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        setInputDocumentUrl('');
        setInputDocumentName('');
    }
    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>创建智能体模式</div>
                <Select
                    value={focusedItem.content.agentMode}
                    onChange={value => onInputChange(focusedItem.id, 'agentMode', value)}
                    style={{ width: 120 }}
                >
                    <Select.Option value="basic">基础模式</Select.Option>
                    <Select.Option value="advanced">高级模式</Select.Option>
                </Select>
            </div>
            {
                focusedItem.content.agentMode === 'basic' && (
                    <>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>角色描述</div>
                            <TextArea
                                value={focusedItem.content.agentRole}
                                onChange={e => onInputChange(focusedItem.id, 'agentRole', e.target.value)}
                                className={styles.inputArea}
                            />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>技能描述</div>
                            <TextArea
                                value={focusedItem.content.agentSkill}
                                onChange={e => onInputChange(focusedItem.id, 'agentSkill', e.target.value)}
                                className={styles.inputArea}
                            />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>规范描述</div>
                            <TextArea
                                value={focusedItem.content.agentRule}
                                onChange={e => onInputChange(focusedItem.id, 'agentRule', e.target.value)}
                                className={styles.inputArea}
                            />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>set role lectureVar</div>
                            <TextArea
                                value={focusedItem.content.roleLectureVar || ''}
                                onChange={e => onInputChange(focusedItem.id, 'roleLectureVar', e.target.value)}
                                className={styles.textareaVar} />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>set skill lectureVar</div>
                            <TextArea
                                value={focusedItem.content.skillLectureVar || ''}
                                onChange={e => onInputChange(focusedItem.id, 'skillLectureVar', e.target.value)}
                                className={styles.textareaVar} />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>set rule lectureVar</div>
                            <TextArea
                                value={focusedItem.content.ruleLectureVar || ''}
                                onChange={e => onInputChange(focusedItem.id, 'ruleLectureVar', e.target.value)}
                                className={styles.textareaVar} />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>设置分享链接 lectureVar</div>
                            <TextArea
                                value={focusedItem.content.agentLinkLectureVar || ''}
                                onChange={e => onInputChange(focusedItem.id, 'agentLinkLectureVar', e.target.value)}
                                className={styles.textareaVar} />
                        </div>
                    </>
                )
            }
            {
                focusedItem.content.agentMode === 'advanced' && (
                    <>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>智能体名称</div>
                            <Input
                                value={focusedItem.content.agentName}
                                onChange={e => onInputChange(focusedItem.id, 'agentName', e.target.value)}
                            />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>角色描述</div>
                            <TextArea
                                value={focusedItem.content.agentRole}
                                onChange={e => onInputChange(focusedItem.id, 'agentRole', e.target.value)}
                                className={styles.inputArea}
                            />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>技能描述</div>
                            <TextArea
                                value={focusedItem.content.agentSkill}
                                onChange={e => onInputChange(focusedItem.id, 'agentSkill', e.target.value)}
                                className={styles.inputArea}
                            />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>规范描述</div>
                            <TextArea
                                value={focusedItem.content.agentRule}
                                onChange={e => onInputChange(focusedItem.id, 'agentRule', e.target.value)}
                                className={styles.inputArea}
                            />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>标题（欢迎词）</div>
                            <TextArea
                                value={focusedItem.content.headerText}
                                onChange={e => onInputChange(focusedItem.id, 'headerText', e.target.value)}
                                className={styles.inputArea}
                            />
                        </div>
                        <div className={styles.documentBox}>
                            <div className={styles.documentBoxTitle}>
                                知识库文档列表
                                <Button onClick={() => setIsModalVisible(true)}>添加文档</Button>
                            </div>
                            {
                                documentList.map((document: DocumentData) => (
                                    <div key={document.id} className={styles.documentItem}>
                                        <div
                                            className={styles.documentName}
                                        >
                                            <span className={styles.title}>文档名称:</span>
                                            <Input
                                                value={document.docName}
                                                onChange={e => updateDocument(document.id, 'docName', e.target.value)}
                                            />
                                        </div>
                                        <div className={styles.documentUrl}>
                                            <span className={styles.title}>文档链接:</span>
                                            <Input
                                                value={document.docUrl}
                                                onChange={e => updateDocument(document.id, 'docUrl', e.target.value)}
                                            />
                                        </div>
                                        <div className={styles.documentContent}>
                                            <span className={styles.title}>文档内容:</span>
                                            <TextArea
                                                value={document.docContent}
                                                onChange={e => updateDocument(document.id, 'docContent', e.target.value)}
                                            />
                                        </div>
                                        <Button onClick={() => deleteDocument(document.id)}>删除</Button>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={styles.title}>预设问题</div>
                        <div className={styles.modifyItem}>
                            <div className={styles.presetMessageList}>
                                <Button onClick={addPresetMessage}>添加预设消息</Button>
                                {
                                    presetMessages.map((message: ChatMessage) => (
                                        <div key={message.id} className={styles.presetMessage}>
                                            <div>
                                                <Input
                                                    value={message.rawContent}
                                                    onChange={e => updatePresetMessage(message.id, 'rawContent', e.target.value)}
                                                />
                                                <Button onClick={() => deletePresetMessage(message.id)}>删除</Button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={styles.title}>绑定json数据</div>
                        <div className={styles.modifyItem}>
                            <TextArea
                                value={bindResourceStr}
                                onChange={e => setBindResourceStr(e.target.value)}
                                className={styles.bindResourceArea}
                            />
                            <Button onClick={() => handleBindResource(bindResourceStr)}>绑定</Button>
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>set role lectureVar</div>
                            <TextArea
                                value={focusedItem.content.roleLectureVar || ''}
                                onChange={e => onInputChange(focusedItem.id, 'roleLectureVar', e.target.value)}
                                className={styles.textareaVar} />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>set skill lectureVar</div>
                            <TextArea
                                value={focusedItem.content.skillLectureVar || ''}
                                onChange={e => onInputChange(focusedItem.id, 'skillLectureVar', e.target.value)}
                                className={styles.textareaVar} />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>set rule lectureVar</div>
                            <TextArea
                                value={focusedItem.content.ruleLectureVar || ''}
                                onChange={e => onInputChange(focusedItem.id, 'ruleLectureVar', e.target.value)}
                                className={styles.textareaVar} />
                        </div>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>设置分享链接 lectureVar</div>
                            <TextArea
                                value={focusedItem.content.agentLinkLectureVar || ''}
                                onChange={e => onInputChange(focusedItem.id, 'agentLinkLectureVar', e.target.value)}
                                className={styles.textareaVar} />
                        </div>
                        {/* <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>是否流式对话</div>
                            <Select
                                value={focusedItem.content.isStream}
                                onChange={value => onInputChange(focusedItem.id, 'isStream', value)}
                                style={{ width: 120 }}
                            >
                                <Select.Option value={true}>是</Select.Option>
                                <Select.Option value={false}>否</Select.Option>
                            </Select>
                        </div> */}
                    </>
                )
            }
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>
            <Modal
                title="添加文档"
                open={isModalVisible}
                onOk={addDocument}
                onCancel={handleCancel}
                centered
            >
                <h3>支持txt、docx格式</h3>
                <div>文档名称:</div>
                <Input
                    placeholder="请输入文档名称"
                    value={inputDocumentName}
                    onChange={e => setInputDocumentName(e.target.value)}
                />
                <div>文档链接:</div>
                <Input
                    placeholder="请输入文档链接"
                    value={inputDocumentUrl}
                    onChange={e => setInputDocumentUrl(e.target.value)}
                />

            </Modal>
        </div>
    )
}

export default AIChatAgentModifier;
