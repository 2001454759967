import PropTypes from 'prop-types';
import BtnTextGroupModifier from './components/BtnTextGroupModifier/BtnTextGroupModifier';
import BtnGenericGroupModifier from './components/BtnGenericGroupModifier/BtnGenericGroupModifier';
import AdvancedButtonRegionModifier from './components/ButtonRegionModifier/AdvancedButtonRegionModifier';
import TextRegionModifier from './components/TextRegionModifier/TextRegionModifier';
import ButtonRegionModifier from './components/ButtonRegionModifier/ButtonRegionModifier';
import ImageRegionModifier from './components/ImageRegionModifier/ImageRegionModifier';
import VideoRegionModifier from './components/VideoRegionModifier/VideoRegionModifier';
import AIChatModifier from './components/AIChatModifier/AIChatModifier';
import TeacherWordsModifier from './components/TeacherWordsModifier/TeacherWordsModifier';
import AudioRegionModifier from './components/AudioRegionModifier/AudioRegionModifier';
import LineRegionModifier from './components/LineRegionModifier/LineRegionModifier';
import SunoGenModifier from './components/SunoGenModifier/SunoGenModifier';
import RatingRegionModifier from './components/RatingRegionModifier/RatingRegionModifier';
import CodeRegionModifier from './components/CodeRegionModifier/CodeRegionModifier';
import QRCodeRegionModifier from './components/QRCodeRegionModifier/QRCodeRegionModifier';
import DragClassifyModifier from './components/DragClassifyModifier/DragClassifyModifier';
import ShowCodeModifier from './components/ShowCodeModifier/ShowCodeModifier';
import FlashCardModifier from './components/FlashCardModifier/FlashCardModifier';
import LineConnectorModifier from './components/LineConnectorModifier/LineConnectorModifier';
import ShareWithSoundModifier from './components/ShareWithSoundModifier/ShareWithSoundModifier';
import TreasureHuntModifier from './components/TreasureHuntModifier/TreasureHuntModifier';
import RollingBallModifier from './components/RollingBallModifier/RollingBallModifier';
import NeuronCalculatorModifier from './components/NeuronCalculatorModifier/NeuronCalculatorModifier';
import NNVisRegionModifier from './components/NNVisRegionModifier/NNVisRegionModifier';
import TrainingLogModifier from './components/TrainingLogModifier/TrainingLogModifier';
import BlindBoxRegionModifier from './components/BlindBoxRegionModifier/BlindBoxRegionModifier';
import TokenizerRegionModifier from './components/TokenizerRegionModifier/TokenizerRegionModifier';
import WordGeneratorModifier from './components/WordGeneratorModifier/WordGeneratorModifier';
import GenCustomMusicModifier from './components/GenCustomMusicModifier/GenCustomMusicModifier';
import WordCoordinateModifier from './components/WordCoordinateModifier/WordCoordinateModifier';
import AIChatAgentModifier from './components/AIChatAgentModifier/AIChatAgentModifier';
import CustomGamesModifier from './components/CustomGamesModifier/CustomGamesModifier';
import { ElementData, ElementContent } from '@/base/ElementData/ElementData';
import TextRegionContent from '@/base/ElementData/TextRegionContent';
import ImageRegionContent from '@/base/ElementData/ImageRegionContent';
import ButtonRegionContent from '@/base/ElementData/ButtonRegionContent';
import BtnTextGroupRegionContent from '@/base/ElementData/BtnTextGroupRegionContent';
import BtnGenericGroupRegionContent from '@/base/ElementData/BtnGenericGroupRegionContent';
import VideoRegionContent from '@/base/ElementData/VideoRegionContent';
import AIChatRegionContent from '@/base/ElementData/AIChatRegionContent';
import AudioRegionContent from '@/base/ElementData/AudioRegionContent';
import LineRegionContent from '@/base/ElementData/LineRegionContent';
import SunoGenContent from '@/base/ElementData/SunoGenContent';
import RatingRegionContent from '@/base/ElementData/RatingRegionContent';
import CodeRegionContent from '@/base/ElementData/CodeRegionContent';
import QRCodeRegionContent from '@/base/ElementData/QRCodeRegionContent';
import { DragClassifyRegionContent } from '@/base/ElementData/DragClassifyRegionContent';
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';
import TeacherWordsRegionContent from '@/base/ElementData/TeacherWordsRegionContent';
import ShowCodeRegionContent from '@/base/ElementData/ShowCodeRegionContent';
import FlashCardRegionContent from '@/base/ElementData/FlashCardRegionContent';
import { LineConnectorRegionContent } from '@/base/ElementData/LineConnectorRegionContent';
import ShareWithSoundRegionContent from '@/base/ElementData/ShareWithSoundRegionContent';
import TreasureHuntRegionContent from '@/base/ElementData/TreasureHuntRegionContent';
import RollingBallRegionContent from '@/base/ElementData/RollingBallRegionContent';
import NeuronCalculatorRegionContent from '@/base/ElementData/NeuronCalculatorRegionContent';
import NNVisRegionContent from '@/base/ElementData/NNVisRegionContent';
import TrainingLogRegionContent from '@/base/ElementData/TrainingLogRegionContent';
import BlindBoxRegionContent from '@/base/ElementData/BlindBoxRegionContent';
import TokenizerRegionContent from '@/base/ElementData/TokenizerRegionContent';
import WordGeneratorRegionContent from '@/base/ElementData/WordGeneratorRegionContent';
import GenCustomMusicRegionContent from '@/base/ElementData/GenCustomMusicRegionContent';
import WordCoordinateRegionContent from '@/base/ElementData/WordCoordinateRegionContent';
import AIChatAgentRegionContent from '@/base/ElementData/AIChatAgentRegionContent';
import CustomGamesRegionContent from '@/base/ElementData/CustomGamesRegionContent';

interface ElementModifierProps {
    focusedItem: ElementData<ElementContent>;
    onInputChange: (id: number, key: string, value: string) => void;
    onOptionChange: (id: number, key: string, btnId: number, value: string) => void;
    onAddOption: (key: number, value: number) => void;
    changeZIndex: (index: number, direction: string) => void;
    onSaveAsTemplate: (id: number, url: string) => void;
    containerRef: React.RefObject<HTMLDivElement>;
    currentOptionIndex?: number;
}


const ElementModifier: React.FC<ElementModifierProps> = ({
    focusedItem,
    onInputChange,
    onOptionChange,
    onAddOption,
    changeZIndex,
    onSaveAsTemplate,
    containerRef,
    currentOptionIndex
}) => {
    if (!focusedItem) return null;

    return (
        <div>
            {focusedItem?.type === 101 && (
                <TextRegionModifier
                    focusedItem={focusedItem as ElementData<TextRegionContent>}
                    onInputChange={onInputChange}
                    changeZIndex={changeZIndex} />
            )}
            {
                focusedItem?.type === 102 && (
                    <ImageRegionModifier
                        focusedItem={focusedItem as ElementData<ImageRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 103 && (
                    <ButtonRegionModifier
                        focusedItem={focusedItem as ElementData<ButtonRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                        onSaveAsTemplate={onSaveAsTemplate}
                        containerRef={containerRef}
                    />
                )
            }
            {
                focusedItem?.type === 104 && (
                    <AdvancedButtonRegionModifier
                        focusedItem={focusedItem as ElementData<AdvancedButtonRegionContent>}
                        onInputChange={onInputChange}
                        onOptionChange={onOptionChange}
                        changeZIndex={changeZIndex}
                        onSaveAsTemplate={onSaveAsTemplate}
                    />
                )
            }
            {
                focusedItem?.type === 105 && (
                    <BtnTextGroupModifier
                        focusedItem={focusedItem as ElementData<BtnTextGroupRegionContent>}
                        onInputChange={onInputChange}
                        onAddOption={onAddOption}
                        containerRef={containerRef}
                        changeZIndex={changeZIndex}
                        onSaveAsTemplate={onSaveAsTemplate}
                    />
                )
            }
            {
                focusedItem?.type === 106 && (
                    <BtnGenericGroupModifier
                        focusedItem={focusedItem as ElementData<BtnGenericGroupRegionContent>}
                        onInputChange={onInputChange}
                        onOptionChange={onOptionChange}
                        onAddOption={onAddOption}
                        changeZIndex={changeZIndex}
                        currentOptionIndex={currentOptionIndex}
                        onSaveAsTemplate={onSaveAsTemplate}
                    />
                )
            }
            {
                focusedItem?.type === 107 && (
                    <VideoRegionModifier
                        focusedItem={focusedItem as ElementData<VideoRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 108 && (
                    <AudioRegionModifier
                        focusedItem={focusedItem as ElementData<AudioRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 109 && (
                    <SunoGenModifier
                        focusedItem={focusedItem as ElementData<SunoGenContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 110 && (
                    <AIChatModifier
                        focusedItem={focusedItem as ElementData<AIChatRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 111 && (
                    <TeacherWordsModifier
                        focusedItem={focusedItem as ElementData<TeacherWordsRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 113 && (
                    <LineRegionModifier
                        focusedItem={focusedItem as ElementData<LineRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 114 && (
                    <RatingRegionModifier
                        focusedItem={focusedItem as ElementData<RatingRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 115 && (
                    <CodeRegionModifier
                        focusedItem={focusedItem as ElementData<CodeRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 117 && (
                    <QRCodeRegionModifier
                        focusedItem={focusedItem as ElementData<QRCodeRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 118 && (
                    <DragClassifyModifier
                        focusedItem={focusedItem as ElementData<DragClassifyRegionContent>}
                        onInputChange={onInputChange}
                        onOptionChange={onOptionChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 119 && (
                    <ShowCodeModifier
                        focusedItem={focusedItem as ElementData<ShowCodeRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 120 && (
                    <FlashCardModifier
                        focusedItem={focusedItem as ElementData<FlashCardRegionContent>}
                        onInputChange={onInputChange}
                        onOptionChange={onOptionChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 121 && (
                    <LineConnectorModifier
                        focusedItem={focusedItem as ElementData<LineConnectorRegionContent>}
                        onInputChange={onInputChange}
                        onOptionChange={onOptionChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 122 && (
                    <ShareWithSoundModifier
                        focusedItem={focusedItem as ElementData<ShareWithSoundRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 123 && (
                    <TreasureHuntModifier
                        focusedItem={focusedItem as ElementData<TreasureHuntRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 124 && (
                    <RollingBallModifier
                        focusedItem={focusedItem as ElementData<RollingBallRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 125 && (
                    <NeuronCalculatorModifier
                        focusedItem={focusedItem as ElementData<NeuronCalculatorRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 126 && (
                    <NNVisRegionModifier
                        focusedItem={focusedItem as ElementData<NNVisRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 127 && (
                    <TrainingLogModifier
                        focusedItem={focusedItem as ElementData<TrainingLogRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 128 && (
                    <BlindBoxRegionModifier
                        focusedItem={focusedItem as ElementData<BlindBoxRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 129 && (
                    <TokenizerRegionModifier
                        focusedItem={focusedItem as ElementData<TokenizerRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 130 && (
                    <WordGeneratorModifier
                        focusedItem={focusedItem as ElementData<WordGeneratorRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 131 && (
                    <GenCustomMusicModifier
                        focusedItem={focusedItem as ElementData<GenCustomMusicRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 132 && (
                    <WordCoordinateModifier
                        focusedItem={focusedItem as ElementData<WordCoordinateRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 133 && (
                    <AIChatAgentModifier
                        focusedItem={focusedItem as ElementData<AIChatAgentRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
            {
                focusedItem?.type === 134 && (
                    <CustomGamesModifier
                        focusedItem={focusedItem as ElementData<CustomGamesRegionContent>}
                        onInputChange={onInputChange}
                        changeZIndex={changeZIndex}
                    />
                )
            }
        </div>
    )
}

export default ElementModifier;
