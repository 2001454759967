
import { useState } from "react";
import { Button, Upload, message, Modal } from 'antd';
import service from "@/services/axios";

import PropTypes from 'prop-types';

const UploadMedia = ({ successCallBack,
    icon,
    btn_text,
    accept_str,
    dir,
    isMultiple,
    uploadProps,
}) => {
    // 两种状态，一种是图片按钮icon, 一种是普通文字按钮
    const [uploading, setUpLoading] = useState(false);

    const beforeUpload = async () => {
        setUpLoading(false);
    }
    const customRequest = async (options) => {
        setUpLoading(true)
        const data = new FormData();
        data.append('file', options.file);
        const endpoint = `/uploadfile?dir=${encodeURIComponent(dir)}`
        try {
            const res = await service.post(endpoint, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (res.status === 'success') {
                message.success(`${options.file.name} uploaded successfully`);
                if (successCallBack && uploadProps?.itemId) {
                    successCallBack(res.data.file_url, uploadProps.itemId);
                } else if (successCallBack && uploadProps?.isDoc) {
                    successCallBack(res.data.file_url, options.file);
                } else {
                    successCallBack(res.data.file_url);
                }
                setUpLoading(false)
            }
        } catch (error) {
            message.error(`${options.file.name} file upload failed`);
            console.error(error);
        }
        setUpLoading(false)
    }
    const propsUpload = {
        accept: accept_str,
        beforeUpload,
        customRequest,
        //showUploadList: false,
        multiple: isMultiple,
        ...uploadProps,
    };

    //console.log('propsUpload', propsUpload);

    return (
        <>
            <Upload
                className="avatar-uploader"
                showUploadList={false}
                {...propsUpload}
            >
                {/* {icon ? <img src={icon} /> : <Button>{btn_text}</Button>} */}
                {icon && btn_text && <Button icon={<img src={icon} />}>{btn_text}</Button>}
                {!icon && btn_text && <Button>{btn_text}</Button>}
                {icon && !btn_text && <img src={icon} />}
            </Upload>
            <Modal open={uploading} footer={null} closable={false}>
                <p>File is uploading...</p>
            </Modal>
        </>
    );
};

UploadMedia.propTypes = {
    successCallBack: PropTypes.func.isRequired,
    icon: PropTypes.string,
    btn_text: PropTypes.string,
    accept_str: PropTypes.string.isRequired,
    isMultiple: PropTypes.bool.isRequired,
    uploadProps: PropTypes.object,
    dir: PropTypes.string,
};


export default UploadMedia;
