import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import React from 'react';

interface VisibleToStudentProps {
    children: React.ReactNode;
};

const VisibleToStudent: React.FC<VisibleToStudentProps> = observer(({ children }) => {
    const { userInfoStore } = useStores();
    if (userInfoStore.userInfoData.userRole === 2 ||
        (userInfoStore.userInfoData.userRole === 1 && userInfoStore.userInfoData.userView === 'student') ||
        userInfoStore.userInfoData.userRole === 3 ||
        userInfoStore.userInfoData.userRole === 4 ||
        userInfoStore.userInfoData.userRole === 5
    ) {
        return <>{children}</>;
    }

    return null;
})

export default VisibleToStudent;
