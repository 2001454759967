import { useState, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { InputNumber, Input, Button, Checkbox, Radio, Select, Switch } from 'antd';
import styles from "./btnGenericGroupModifier.module.less";
import ImageRegionModifier from '../ImageRegionModifier/ImageRegionModifier';
import AdvancedButtonRegionModifier from '../ButtonRegionModifier/AdvancedButtonRegionModifier';
import ResourceFileSelector from '@/components/ResourceFileSelector/ResourceFileSelector';
import MediaFileSelector from '@/components/MediaFileSelector/MediaFileSelector';
import MultimediaAPI from '@/api/multimedia';
import TextToSpeech from '@/components/TextToSpeech/TextToSpeech';
import { ElementData } from '@/base/ElementData/ElementData';
import BtnGenericGroupRegionContent from '@/base/ElementData/BtnGenericGroupRegionContent';
import { PromptSetVarConfig } from '@/base/ElementData/UtilTypes'
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';
import ImageRegionContent from '@/base/ElementData/ImageRegionContent';
import { simplifyFileName } from '@/utils/utils';
import { ButtonWrapper } from '@/base/ElementData/UtilTypes';
import UploadMedia from '@/components/UploadMedia/UploadMedia';
import { initElementFromTemplateAsync } from '@/components/Element/common/ElementPropsFactory';
import { genObjectIDbyTS } from '@/utils/id_generator';
import useCommonFunction from '@/hooks/useCommonFunction';

type ElementIdOption = { value: any, label: any };

interface BtnGenericGroupModifierProps {
    focusedItem: ElementData<BtnGenericGroupRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    onOptionChange: (id: number, key: string, btnId: number, value: any) => void;
    onAddOption: (id: number, btnNum: number) => void;
    changeZIndex: (id: number, direction: string) => void;
    currentOptionIndex?: number;
    onSaveAsTemplate: (itemId: number, url: string) => void;
};

const BtnGenericGroupModifier: React.FC<BtnGenericGroupModifierProps> = ({
    focusedItem,
    onInputChange,
    onOptionChange,
    onAddOption,
    changeZIndex,
    currentOptionIndex,
    onSaveAsTemplate
}) => {
    const ttsAPI = new MultimediaAPI();
    const { Option } = Select;
    const [fileSelectorOpenCorrect, setFileSelectorOpenCorrect] = useState(false);
    const [fileSelectorOpenWrong, setFileSelectorOpenWrong] = useState(false);
    const [fileSelectorOpen, setFileSelectorOpen] = useState(false);
    //const [mediaType, setMediaType] = useState('');
    const [currentBtnId, setCurrentBtnId] = useState(0);
    //动态绑定数据
    const [bindResourceStr, setBindResourceStr] = useState(focusedItem.content.bindResourceObj ?
        JSON.stringify(focusedItem.content.bindResourceObj, null, 4) : '');
    const [bindKey, setBindKey] = useState(focusedItem.content.bindKey || '');

    const [postprocessHandlers, setPostprocessHandlers] = useState(cloneDeep(
        focusedItem.content.postProcessHandlers ? focusedItem.content.postProcessHandlers : []));

    // 创建按钮的 ref 数组  
    const buttonRefs = useRef<(HTMLDivElement | null)[]>([]);

    const { generateSelectOptionsData, generateElementIdOptions } = useCommonFunction();
    const optionsData = generateSelectOptionsData();
    const elementIdOptions: ElementIdOption[] = generateElementIdOptions();

    const showFileSelector = (btnId: number) => {
        //console.log("BtnGenricGroupModifier -- showFileSelector", type, btnId)
        //setMediaType(type);
        setFileSelectorOpen(true);
        setCurrentBtnId(btnId);
        //console.log("BtnGenricGroupModifier -- mediaType", mediaType)
    }

    // 设置状态来存储列和按钮个数的值
    const [col, setCol] = useState(focusedItem.content.col || 1); // 初始值为 focusedItem.content.col
    const [row, setRow] = useState(focusedItem.content.row || 1); // 初始值为 focusedItem.content.row
    const [btnNum, setBtnNum] = useState(focusedItem.content.btnNum || 1); // 初始值为 focusedItem.content.btn_num
    const [disableColInput, setDisableColInput] = useState(false);
    const [disableRowInput, setDisableRowInput] = useState(false);
    const [disableBtnNumInput, setDisableBtnNumInput] = useState(false);

    let oldBtnWidth = (focusedItem.content.width - (10 * (focusedItem.content.col - 1))) / focusedItem.content.col;
    let oldBtnHeight = (focusedItem.content.height - (10 * (focusedItem.content.row - 1))) / focusedItem.content.row;
    const handleColChange = (value: number | null) => {
        if (value !== null) {
            setCol(value);
        }
    };

    const handleRowChange = (value: number | null) => {
        if (value !== null) {
            setRow(value);
        }
    };

    const handleBtnNumChange = (value: number | null) => {
        if (value !== null) {
            setBtnNum(value);
        }
    };

    const handleBindKey = (e: any) => {
        setBindKey(e.target.value);
        if (e.target.value) {
            //setDisableColInput(true);
            setDisableBtnNumInput(true);
        } else if (!bindResourceStr) {
            // bindKey, bindResourceStr都为空时
            setDisableColInput(false);
            setDisableBtnNumInput(false);
        }
    }

    const handleBindResourceStr = (e: any) => {
        setBindResourceStr(e.target.value);
        if (e.target.value) {
            //setDisableColInput(true);
            setDisableBtnNumInput(true);
        } else if (!bindKey) {
            // bindKey, bindResourceStr都为空时
            setDisableColInput(false);
            setDisableBtnNumInput(false);
        }
    }

    // 获取行和列的值
    const handleSubmit = () => {
        if (bindResourceStr) {
            console.log("handleSubmit -- bindDataStr", JSON.parse(bindResourceStr));
            const bindResourceObj = JSON.parse(bindResourceStr);
            onInputChange(focusedItem.id, 'bindResourceObj', bindResourceObj);
            onInputChange(focusedItem.id, 'row', row);
            onInputChange(focusedItem.id, 'col', col);
            setCol(col);
            setRow(row);
            onInputChange(focusedItem.id, 'page', 0);
        }
        if (bindKey) {
            onInputChange(focusedItem.id, 'bindKey', bindKey);
        }



        // row目前是提前给定，我们会翻页
        if (!bindResourceStr && !bindKey) {
            //let page = Math.ceil(btnNum / col);
            onInputChange(focusedItem.id, 'row', row);
            onInputChange(focusedItem.id, 'col', col);
            setCol(col);
            setRow(row);
            // 设置page, 默认page 0
            onInputChange(focusedItem.id, 'page', 0);
            onInputChange(focusedItem.id, 'btnNum', btnNum);
            onAddOption(focusedItem.id, btnNum);
            let newWidth = oldBtnWidth * col + 10 * (col - 1);
            let newHeight = oldBtnHeight * row + 10 * (row - 1);
            onInputChange(focusedItem.id, 'width', newWidth);
            onInputChange(focusedItem.id, 'height', newHeight);
        }
    }

    const updateButtonsItem = (index: number, key: keyof ButtonWrapper, value: any) => {
        const newButtons = cloneDeep(focusedItem.content.buttons);
        newButtons[index].setField(key, value);
        onInputChange(focusedItem.id, 'buttons', newButtons);
    }

    const updateButtonsItemObj = async (index: number, obj: any) => {
        try {
            const newButtons = cloneDeep(focusedItem.content.buttons);
            // 生成一个新的 ID，用于新按钮对象  
            const newId = genObjectIDbyTS();

            if (obj.mode === 1) {
                const newBtnObj = new ElementData<ImageRegionContent>({
                    id: newId,
                    type: 102,
                    content: new ImageRegionContent(),
                });
                newBtnObj.content.src = obj.src; // 直接使用解构后的 src  
                newButtons[index].setField("btnObj", newBtnObj);
            } else if (obj.mode === 2 && obj.btnObj) {
                const result = await initElementFromTemplateAsync(obj.btnObj, { positionX: 0, positionY: 0 }, 0, newId) as ElementData<ImageRegionContent | AdvancedButtonRegionContent>;
                newButtons[index].setField("btnObj", result);
            }

            // 更新按钮  
            onInputChange(focusedItem.id, 'buttons', newButtons);
        } catch (error) {
            console.error('Error updating button item:', error);
        }
    }

    const addPostProcessHandler = () => {
        const newPostProcessHandlers = cloneDeep(postprocessHandlers);
        newPostProcessHandlers.push(
            new PromptSetVarConfig({
                prompt: "",
                destVar: "",
            }));
        setPostprocessHandlers(newPostProcessHandlers);
        onInputChange(focusedItem.id, 'postProcessHandlers', newPostProcessHandlers);
    }

    const updatePostProcessHandler = (index: number, key: any, value: any) => {
        const newPostProcessHandlers = cloneDeep(postprocessHandlers);
        newPostProcessHandlers[index].setField(key, value);
        setPostprocessHandlers(newPostProcessHandlers);
        onInputChange(focusedItem.id, 'postProcessHandlers', newPostProcessHandlers);
    }

    const deletePostProcessHandler = (index: number) => () => {
        const newPostProcessHandlers = cloneDeep(postprocessHandlers);
        newPostProcessHandlers.splice(index, 1);
        setPostprocessHandlers(newPostProcessHandlers);
        onInputChange(focusedItem.id, 'postProcessHandlers', newPostProcessHandlers);
    }

    const changeFuncName = (value: string) => {
        const newFunc = { ...focusedItem.content.postFunctionCall, funcName: value };
        onInputChange(focusedItem.id, 'postFunctionCall', newFunc);
    }

    const updateDoneCallFuncParams = (key: string, value: any) => {
        const newDoneCallFunc = cloneDeep(focusedItem?.content?.postFunctionCall || null);
        if (newDoneCallFunc !== null) {
            const funcParams = newDoneCallFunc.funcParams || {};
            const newFuncParams = { ...funcParams, [key]: value };
            newDoneCallFunc.funcParams = newFuncParams;
        }
        onInputChange(focusedItem.id, 'postFunctionCall', newDoneCallFunc);

    }

    // 使用 useEffect 在 currentOptionIndex 变化时滚动到相应的按钮  
    useEffect(() => {
        if (currentOptionIndex !== undefined && currentOptionIndex !== -1 && buttonRefs.current[currentOptionIndex]) {
            buttonRefs.current[currentOptionIndex].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [currentOptionIndex]);

    // 保存为模板
    const successCallBack = (url: string, itemId: number) => {
        onSaveAsTemplate!(itemId, url);

    };

    const propsUpload = {
        itemId: focusedItem.id,
    };

    return (
        <div>
            <div className={styles.mainModify}>
                <UploadMedia
                    successCallBack={successCallBack}
                    icon={null}
                    btn_text="存为模板 (先上传Thumbnail)"
                    accept_str="image/png,image/jpg,image/jpeg,image/gif,image/svg"
                    dir="images"
                    isMultiple={false}
                    uploadProps={propsUpload}
                />
                <h2>对于整体部分的编辑</h2>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>X</div>
                    <InputNumber
                        value={focusedItem.content.positionX}
                        onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                    />
                    <div className={styles.modifyItemTitle}>Y</div>
                    <InputNumber
                        value={focusedItem.content.positionY}
                        onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>宽度</div>
                    <InputNumber
                        value={focusedItem.content.width}
                        onChange={value => onInputChange(focusedItem.id, 'width', value)}
                        className={styles.widthInput}
                    />
                    <div className={styles.modifyItemTitle}>高度</div>
                    <InputNumber
                        value={focusedItem.content.height}
                        onChange={value => onInputChange(focusedItem.id, 'height', value)}
                        className={styles.heightInput}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>单选还是多选</div>
                    <Radio.Group
                        value={focusedItem.content.selectionType}
                        onChange={e => onInputChange(focusedItem.id, 'selectionType', e.target.value)}
                    >
                        <Radio value='single'>单选</Radio>
                        <Radio value='multiple'>多选</Radio>
                    </Radio.Group>
                </div>
                <div className={styles.modifyItem}>
                    <span>正确答案Eval文本</span>
                    <textarea
                        style={{ height: '200px' }}
                        value={focusedItem.content.evalTextCorrect || ''}
                        onChange={e => onInputChange(focusedItem.id, 'evalTextCorrect', e.target.value)}
                        className={styles.textareaLarge} />
                </div>
                <div className={styles.modifyItem}>
                    <TextToSpeech
                        text={focusedItem.content.evalTextCorrect || ''}
                        btnText='生成正确答案音频'
                        successCallback={url => onInputChange(focusedItem.id, 'evalTextCorrectAudio', url)}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>正确文本音频链接</div>
                    <div className={styles.audioUrlBox}>
                        {focusedItem.content.evalTextCorrectAudio ? focusedItem.content.evalTextCorrectAudio : '暂无'}
                    </div>
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>正确答案音效</div>
                    <Button type='primary' onClick={() => setFileSelectorOpenCorrect(true)}>选择音效</Button>
                    <MediaFileSelector
                        mediaType="audios"
                        afterSelectionCallback={url => onInputChange(focusedItem.id, 'correctAnswerSoundUrl', url)}
                        fileSelectorOpen={fileSelectorOpenCorrect}
                        setFileSelectorOpen={setFileSelectorOpenCorrect}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>正确音效文件名</div>
                    <div className={styles.fileName}>
                        {simplifyFileName(focusedItem.content.correctAnswerSoundUrl)}
                    </div>
                </div>
                <br />
                <div className={styles.modifyItem}>
                    <span>错误答案Eval文本</span>
                    <textarea
                        style={{ height: '200px' }}
                        value={focusedItem.content.evalTextWrong || ''}
                        onChange={e => onInputChange(focusedItem.id, 'evalTextWrong', e.target.value)}
                        className={styles.textareaLarge} />
                </div>
                <div className={styles.modifyItem}>
                    <TextToSpeech
                        text={focusedItem.content.evalTextWrong || ''}
                        btnText='生成错误答案音频'
                        successCallback={url => onInputChange(focusedItem.id, 'evalTextWrongAudio', url)}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>错误文本音频链接</div>
                    <div className={styles.audioUrlBox}>
                        {focusedItem.content.evalTextWrongAudio ? focusedItem.content.evalTextWrongAudio : '暂无'}
                    </div>
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>错误答案音效</div>
                    <Button type='primary' onClick={() => setFileSelectorOpenWrong(true)}>选择音效</Button>
                    <MediaFileSelector
                        mediaType="audios"
                        afterSelectionCallback={url => onInputChange(focusedItem.id, 'wrongAnswerSoundUrl', url)}
                        fileSelectorOpen={fileSelectorOpenWrong}
                        setFileSelectorOpen={setFileSelectorOpenWrong}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>错误音效文件名</div>
                    <div className={styles.fileName}>
                        {simplifyFileName(focusedItem.content.wrongAnswerSoundUrl)}
                    </div>
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>是否自动播放评价文本</div>
                    <Switch
                        checked={focusedItem.content.autoplayEvalText}
                        onChange={value => onInputChange(focusedItem.id, 'autoplayEvalText', value)}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>Eval文本颜色</div>
                    <input
                        type='color'
                        value={focusedItem.content.evalTextColor}
                        onChange={e => onInputChange(focusedItem.id, 'evalTextColor', e.target.value)}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>Eval文本字体粗细</div>
                    <Select
                        value={focusedItem.content.evalTextFontWeight}
                        onChange={value => onInputChange(focusedItem.id, 'evalTextFontWeight', value)}
                    >
                        <Option value={400}>400</Option>
                        <Option value={500}>500</Option>
                        <Option value={600}>600</Option>
                    </Select>
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>答对奖励的金币数量</div>
                    <InputNumber
                        value={focusedItem.content.rewardedCoinsNum}
                        onChange={value => onInputChange(focusedItem.id, 'rewardedCoinsNum', value)}
                        className={styles.showSpeedWordInput}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>每页最大字数</div>
                    <InputNumber
                        value={focusedItem.content.characterPerPage}
                        onChange={value => onInputChange(focusedItem.id, 'characterPerPage', value)}
                        className={styles.showSpeedWordInput}
                    />
                </div>
                <br />
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>是否展示输入框</div>
                    <Checkbox
                        checked={focusedItem.content.inputable}
                        onChange={e => {
                            onInputChange(focusedItem.id, 'inputable', e.target.checked);
                            if (e.target.checked) {
                                onInputChange(focusedItem.id, 'correctAnswer', []);
                            }
                        }}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>是否展示提交按钮</div>
                    <Checkbox
                        checked={focusedItem.content.showSubmitBtn}
                        onChange={e => onInputChange(focusedItem.id, 'showSubmitBtn', e.target.checked)}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>element是否展示</div>
                    <Switch
                        checked={focusedItem.content.visible}
                        onChange={value => onInputChange(focusedItem.id, 'visible', value)}
                    />
                </div>
                {focusedItem.content.selectionType === 'single' && (
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>点击选项是否跳转到下一个block</div>
                        <Checkbox
                            checked={focusedItem.content.goNext}
                            onChange={e => onInputChange(focusedItem.id, 'goNext', e.target.checked)}
                        />
                    </div>
                )}
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>宽度</div>
                    <InputNumber
                        value={focusedItem.content.width}
                        onChange={value => onInputChange(focusedItem.id, 'width', value)}
                        className={styles.widthInput}
                    />
                    <div className={styles.modifyItemTitle}>高度</div>
                    <InputNumber
                        value={focusedItem.content.height}
                        onChange={value => onInputChange(focusedItem.id, 'height', value)}
                        className={styles.heightInput}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>层级</div>
                    <div>
                        <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                        <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                    </div>
                </div>
                <div className={styles.verticalLayout} >
                    <label>请输入列数</label>
                    <InputNumber
                        disabled={disableColInput}
                        value={col}
                        onChange={handleColChange}
                        className={styles.colInput}
                    />
                    <label>请输入行数</label>
                    <InputNumber
                        disabled={disableRowInput}
                        value={row}
                        onChange={handleRowChange}
                        className={styles.colInput}
                    />
                    <label>按钮个数</label>
                    <InputNumber
                        disabled={disableBtnNumInput || bindKey !== '' || bindResourceStr !== ''}
                        value={btnNum}
                        onChange={handleBtnNumChange}
                    />
                    <label>绑定数据Json</label>
                    <Input.TextArea style={{ width: '90%', height: '120px' }}
                        value={bindResourceStr}
                        onChange={handleBindResourceStr} />
                    <label>绑定数据Key</label>
                    <Input style={{ width: '70%', height: '30px' }}
                        value={bindKey}
                        onChange={handleBindKey} />
                    <Button type='primary' onClick={handleSubmit}>确定</Button>
                </div>
            </div>
            <div>
                <h2>按钮组</h2>
                <div>
                    {focusedItem.content.buttons.map((btn, index) => (
                        <div key={btn.btnObj!.id} className={styles.buttonBox} ref={ref => (buttonRefs.current[index] = ref)}>
                            <h2>按钮{index + 1}</h2>
                            <Button type="primary" onClick={() => showFileSelector(btn.btnObj!.id)}>
                                选择图片或模板
                            </Button>
                            <ResourceFileSelector
                                fileSelectorOpen={fileSelectorOpen}
                                setFileSelectorOpen={setFileSelectorOpen}
                                afterSelectionCallback={obj => updateButtonsItemObj(index, obj)}
                                resourceType={106}
                            />
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>Button Value</div>
                                <textarea
                                    value={btn.value || ''}
                                    onChange={e => updateButtonsItem(index, 'value', e.target.value)}
                                    className={styles.textarea} />
                            </div>
                            {btn.btnObj!.type === 104 ?
                                <AdvancedButtonRegionModifier
                                    focusedItem={btn.btnObj! as ElementData<AdvancedButtonRegionContent>}
                                    onInputChange={onInputChange}
                                    onOptionChange={onOptionChange}
                                    changeZIndex={changeZIndex}>
                                </AdvancedButtonRegionModifier>
                                :
                                <ImageRegionModifier
                                    focusedItem={btn.btnObj! as ElementData<ImageRegionContent>}
                                    onInputChange={onInputChange}
                                    changeZIndex={changeZIndex}>
                                </ImageRegionModifier>
                            }
                            {
                                !focusedItem.content.inputable && (
                                    <div>
                                        <label>正确答案是否包含这一项</label>
                                        {/* 根据elementData的content的correct_answer数组，里面存储的是正确选项的下标*/}
                                        <Checkbox
                                            checked={focusedItem.content.correctAnswer.indexOf(index) !== -1}
                                            // 如果选中，就把当前选项的下标加入到correct_answer数组中,否则就删除,使用onInputChange方法
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    onInputChange(focusedItem.id, 'correctAnswer', [...focusedItem.content.correctAnswer, index]);
                                                } else {
                                                    onInputChange(focusedItem.id, 'correctAnswer', focusedItem.content.correctAnswer.filter(item => item !== index));
                                                }
                                            }
                                            }
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.verticalLayout}>
                <label>Btn Group lecture var</label>
                <textarea
                    value={focusedItem.content.lectureVar || ''}
                    onChange={e => onInputChange(focusedItem.id, 'lectureVar', e.target.value)}
                    className={styles.textareaVar} />
            </div>
            <hr></hr>
            <label>Postprocess Handlers</label>
            <hr></hr>

            {postprocessHandlers.map((item, index) => (
                <div className={styles.verticalLayout} key={String(focusedItem.id) + String(index + 1)}>
                    <label>prompt {index + 1}</label>
                    <textarea
                        value={item.prompt}
                        onChange={e => updatePostProcessHandler(index, 'prompt', e.target.value)}
                        className={styles.textareaPrompt} />
                    <label>destination Lecture var {index + 1} </label>
                    <textarea
                        value={item.destVar}
                        onChange={e => updatePostProcessHandler(index, 'destVar', e.target.value)}
                        className={styles.textareaVar} />
                    <br></br>
                    <Button onClick={deletePostProcessHandler(index)}>删除</Button>
                </div>)
            )}
            <hr></hr>
            <div className={styles.modifyItem}>
                <span>Post Prompt</span>
                <textarea
                    style={{ height: '200px' }}
                    value={focusedItem.content.postPrompt || ''}
                    onChange={e => onInputChange(focusedItem.id, 'postPrompt', e.target.value)}
                    className={styles.textareaLarge} />
            </div>
            <div>
                <label>绑定函数</label>
                <Select
                    value={focusedItem.content.postFunctionCall?.funcName}
                    onChange={value => changeFuncName(value)}
                    className={styles.functionSelect}
                >
                    {optionsData.map(option => (
                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                    ))}
                </Select>
            </div>
            {(focusedItem.content.postFunctionCall?.funcName === 'showSpecifiedElement' ||
                focusedItem.content.postFunctionCall?.funcName === 'showSpecifiedElementAndHide' ||
                focusedItem.content.postFunctionCall?.funcName === 'changeTeacherWords') && (
                    <div className={styles.modifyItemCol}>
                        <div className={styles.modifyItemTitle}>elementId</div>
                        <Select
                            value={focusedItem.content.postFunctionCall?.funcParams?.elementId}
                            onChange={value => updateDoneCallFuncParams('elementId', value)}
                            className={styles.functionSelect}
                        >
                            {elementIdOptions.map(option => (
                                <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                            ))}
                        </Select>
                    </div>
                )}
            {focusedItem.content.postFunctionCall?.funcName === 'changeTeacherWords' && (
                <>
                    <div className={styles.modifyItemCol}>
                        <div className={styles.modifyItemTitle}>参数:new words</div>
                        <textarea
                            value={focusedItem.content.postFunctionCall?.funcParams?.newWords}
                            onChange={e => updateDoneCallFuncParams('newWords', e.target.value)}
                            className={styles.textareaSmall}
                        />
                    </div>
                    <div className={styles.modifyItemCol}>
                        <div className={styles.modifyItemTitle}>参数:audio url</div>
                        <TextToSpeech
                            text={focusedItem.content.postFunctionCall?.funcParams?.newWords}
                            btnText='生成音频'
                            successCallback={url => updateDoneCallFuncParams('audioUrl', url)}
                        />
                    </div>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>参数音频链接:</div>
                        {focusedItem.content.postFunctionCall?.funcParams?.audioUrl ? focusedItem.content.postFunctionCall?.funcParams?.audioUrl : "暂无"}
                    </div>
                </>
            )}
            <Button onClick={addPostProcessHandler}>添加预设Postprocess Handler</Button>
        </div>
    )
}
export default BtnGenericGroupModifier;