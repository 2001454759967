import { ElementContent } from './ElementData';

class GenCustomMusicRegionContent extends ElementContent {
    songTitle: string;
    songLyric: string;
    songStyle: string;
    songCoverUrl: string;
    songUrl: string;
    songUrlLectureVar: string;
    elementMode: string;//song_making和melody_making
    songDescription: string;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 920,
        height = 515,
        songTitle = "",
        songLyric = "",
        songStyle = "",
        songCoverUrl = "",
        songUrl = "",
        songUrlLectureVar = "",
        elementMode = "song_making",
        songDescription = "",
    }: Partial<GenCustomMusicRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex', 'songTitle', 'songLyric',
            'songStyle', 'songCoverUrl', 'songUrl', 'songUrlLectureVar', 'elementMode', 'songDescription'];
        this.songTitle = songTitle;
        this.songLyric = songLyric;
        this.songStyle = songStyle;
        this.songCoverUrl = songCoverUrl;
        this.songUrl = songUrl;
        this.songUrlLectureVar = songUrlLectureVar;
        this.elementMode = elementMode;
        this.songDescription = songDescription;
    }
};

export default GenCustomMusicRegionContent;