import { QRCode } from 'antd';
import { useEffect, useState } from 'react';
import styles from './qRCodeRegion.module.less';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { convertUrl } from '@/utils/utils';
import { getSongInfo } from '@/api/userLikeObject';
import { createUserWork } from '@/api/userWork';
import { generateMD5 } from '@/utils/utils';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { message } from 'antd';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import QRCodeRegionContent from '@/base/ElementData/QRCodeRegionContent';
import MultimediaAPI from '@/api/multimedia';
import service from '@/services/axios';

const QRCodeRegion: React.FC<IntrinsicElementProps<QRCodeRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete }) => {
    const ttsAPI = new MultimediaAPI();
    const { src = '', qrCodeType, shareType, payload = {} } = elementData.content;
    const { userInfoStore, lectureStore } = useStores();
    const isTeacher = userInfoStore.isTeacherView();
    const [targetUrl, setTargetUrl] = useState('');
    const [qrLoading, setQrLoading] = useState(false);

    // 处理视频封面
    const handleVideoCover = async (url: string) => {
        try {
            // 1. 创建视频元素截取第一帧  
            const video = document.createElement('video');
            video.crossOrigin = 'anonymous';
            video.src = url;

            // 等待视频元数据加载  
            await new Promise((resolve, reject) => {
                video.addEventListener('loadedmetadata', resolve);
                video.addEventListener('error', reject);
            });

            // 定位到第一帧  
            video.currentTime = 0.1;

            // 等待定位完成  
            await new Promise((resolve) => video.addEventListener('seeked', resolve));

            // 2. 绘制到 canvas  
            const canvas = document.createElement('canvas');
            const targetWidth = 512;  // 目标宽度  
            const targetHeight = 512;  // 目标高度  

            canvas.width = targetWidth;
            canvas.height = targetHeight;
            const ctx = canvas.getContext('2d');

            // 计算缩放比例  
            const scale = Math.min(targetWidth / video.videoWidth, targetHeight / video.videoHeight);
            const scaledWidth = video.videoWidth * scale;
            const scaledHeight = video.videoHeight * scale;
            const x = (targetWidth - scaledWidth) / 2; // 使图像居中  
            const y = (targetHeight - scaledHeight) / 2; // 使图像居中  

            // 绘制缩放后的图片  
            ctx!.drawImage(video, x, y, scaledWidth, scaledHeight);

            // 3. 转换为 Blob  
            const blob = await new Promise<Blob | null>((resolve) =>
                canvas.toBlob(resolve, 'image/jpeg', 0.8)
            );

            if (!blob) throw new Error('Failed to capture frame');

            // 4. 上传到服务器  
            const formData = new FormData();
            formData.append('file', blob, 'cover.jpg');

            const uploadRes: any = await service.post(
                `/uploadfile?dir=${encodeURIComponent('share_covers')}`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            if (uploadRes.status !== 'success') throw new Error('Upload failed');

            return uploadRes.data.file_url;
        } catch (error) {
            console.error('视频封面处理失败:', error);
            return url; // 失败时返回原始 URL  
        }
    };

    const generateMusicQRCode = async () => {
        try {
            setQrLoading(true);
            const getSongRes = await getSongInfo(userInfoStore.userInfoData.id, lectureStore.getLectureVar("sono_song_id"));
            if (getSongRes.status === 'success') {
                const songData = { ...getSongRes.data, author_name: userInfoStore.userInfoData.name };
                const concatenatedString = `${songData.author_id}${songData.id}${songData.name}`;
                const MD5 = generateMD5(concatenatedString);
                const shareLinkData = {
                    id: 0,
                    md5: MD5,
                    user_id: userInfoStore.userInfoData.id,
                    user_name: userInfoStore.userInfoData.name,
                    work_type: "music",
                    work_info: songData,
                    create_time: 0
                };
                const shareLinkRes: any = await createUserWork(shareLinkData);
                if (shareLinkRes.status === 'success') {
                    setTargetUrl(`${import.meta.env.VITE_FE_URL}/share/${MD5}`);
                    setQrLoading(false);
                } else {
                    setQrLoading(false);
                    message.error('生成分享链接失败');
                }
            } else {
                throw new Error('获取歌曲信息失败');
            }
        } catch (error) {
            setQrLoading(false);
            message.error((error as Error).message || '出错了');
        }
    };

    //生成除了音乐以外的二维码
    const generateOtherQRCode = async (shareType: string) => {
        let newPayload: any = {};
        Object.keys(payload).forEach(key => {
            if (containsVariablePattern(payload[key])) {
                newPayload[key] = rewriteText(payload[key]);
            } else {
                newPayload[key] = payload[key];
            }
        });

        if (shareType === 'basicWithSound') {
            if (newPayload.content) {
                // 生成音频
                let res: any;
                try {
                    setQrLoading(true);
                    res = await ttsAPI.genVoiceAndUpload(
                        {
                            text: newPayload.content,
                            voice_type: 'BV700_streaming'
                        }, 'tts');
                    // 更新音频源和状态  
                    newPayload.contentVoiceUrl = res.file_url; // 存储生成的音频链接  
                } catch (error) {
                    setQrLoading(false);
                    message.error('语音生成失败');
                }
            }
            if (newPayload.imgUrl?.endsWith('.mp4')) {
                let coverUrl = await handleVideoCover(newPayload.imgUrl);
                newPayload.shareCoverUrl = coverUrl;
            }
        }
        if (shareType === 'multiImages') {
            // 创建一个包含所有内容文本的数组  
            const contents = [
                newPayload.content1,
                newPayload.content2,
                newPayload.content3,
                newPayload.content4,
                newPayload.content5,
                newPayload.content6
            ];
            // 创建一个数组用于存储生成的音频链接  
            const contentVoiceUrls = ['', '', '', '', '', ''];
            // 创建一个异步函数来生成音频并存储结果  
            const generateAudio = async (text: string, index: number) => {
                // 如果内容为空字符串，则不生成音频  
                if (!text) {
                    contentVoiceUrls[index] = ""; // 如果是空内容，存储空字符串  
                    return;
                }

                try {
                    const res = await ttsAPI.genVoiceAndUpload(
                        {
                            text: text,
                            voice_type: 'BV700_streaming'
                        },
                        'tts'
                    );

                    // 将生成的音频链接存储到对应的数组中  
                    contentVoiceUrls[index] = res.file_url;
                } catch (error) {
                    setQrLoading(false);
                    message.error(`内容${index + 1}的语音生成失败`);
                }
            };
            setQrLoading(true);

            // 使用 Promise.all 来并行生成所有音频  
            await Promise.all(contents.map((content, index) => generateAudio(content, index)));

            // 将生成的音频链接添加到 newPayload 中  
            newPayload.contentVoiceUrl1 = contentVoiceUrls[0] || '';
            newPayload.contentVoiceUrl2 = contentVoiceUrls[1] || '';
            newPayload.contentVoiceUrl3 = contentVoiceUrls[2] || '';
            newPayload.contentVoiceUrl4 = contentVoiceUrls[3] || '';
            newPayload.contentVoiceUrl5 = contentVoiceUrls[4] || '';
            newPayload.contentVoiceUrl6 = contentVoiceUrls[5] || '';
        }

        try {
            let concatenatedString = '';
            if (shareType === 'code') {
                concatenatedString = `${userInfoStore.userInfoData.id}${newPayload.srcCode}${newPayload.language}`;
                newPayload.title = `${userInfoStore.userInfoData.name}的游戏`;
                newPayload.author_name = userInfoStore.userInfoData.name;
            } else if (shareType === 'cyberAvatar') {
                concatenatedString = `${userInfoStore.userInfoData.id}${newPayload.avatar_url}${newPayload.name}${newPayload.tagline}`;
            } else if (shareType === 'basicPost') {
                concatenatedString = `${userInfoStore.userInfoData.id}${newPayload.title}${newPayload.body}${newPayload.imgUrl}${newPayload.backgroundStyle}`;
            } else if (shareType === 'basicWithSound') {
                concatenatedString = `${userInfoStore.userInfoData.id}${newPayload.title}${newPayload.content}${newPayload.imgUrl}${newPayload.shareDescription}${newPayload.contentBackgroundColor}${newPayload.contentTextAlign}${newPayload.shareCoverUrl}`;
            } else if (shareType === 'chatAgent') {
                concatenatedString = `${userInfoStore.userInfoData.id}${newPayload.systemPrompt}${newPayload.agentAvatar}${newPayload.headerText}${newPayload.presetMessage}${newPayload.shareDescription}`;
            } else if (shareType === 'multiImages') {
                let payloadValues: string | number[] = [];
                for (const key in newPayload) {
                    if (newPayload.hasOwnProperty(key)) {
                        payloadValues.push(newPayload[key]);
                    }
                }
                payloadValues.unshift(userInfoStore.userInfoData.id);
                concatenatedString = payloadValues.join('');
            }
            const MD5 = generateMD5(concatenatedString);
            const shareLinkData = {
                id: 0,
                md5: MD5,
                user_id: userInfoStore.userInfoData.id,
                user_name: userInfoStore.userInfoData.name,
                work_type: shareType,
                work_info: newPayload,
                create_time: 0
            };
            const shareLinkRes: any = await createUserWork(shareLinkData);
            if (shareLinkRes.status === 'success') {
                setTargetUrl(`${import.meta.env.VITE_FE_URL}/share/${MD5}`);
                setQrLoading(false);
            } else {
                setQrLoading(false);
                message.error('生成分享链接失败');
            }
        } catch (error) {
            setQrLoading(false);
            message.error((error as Error).message || '出错了');
        }
    };

    // useEffect(() => {
    //     if (containsVariablePattern(elementData.content.src)) {
    //         if (elementData.content.src === "{{suno_song}}") {
    //             if (lectureStore.getLectureVar("sono_song_id")) {
    //                 generateShareInfo();
    //             }
    //         }
    //     } else {
    //         setTargetUrl(elementData.content.src);
    //     }

    // }, [elementData.content.src]);
    useEffect(() => {
        if (qrCodeType === 'preset') {
            setTargetUrl(containsVariablePattern(src) ? rewriteText(src) : src);
        } else if (qrCodeType === 'generate') {
            if (isTeacher) {
                setTargetUrl('这是老师视角');
                return;
            }
            if (shareType === 'music' && lectureStore.getLectureVar("sono_song_id")) {
                generateMusicQRCode();
            } else {
                generateOtherQRCode(shareType);
            }
        }
    }, [])

    return (<BaseDragableElement elementData={elementData}
        isEditable={isEditable}
        handleFocusItem={handleFocusItem}
        handleResize={handleResize}
        handleDragStop={handleDragStop}
        handleDelete={handleDelete}>
        <div
            style={{ ...commonStyle }}
            onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
            className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            {...(isEditable && { 'data-findparent': 'findparent' })}
        >
            <QRCode
                value={convertUrl(targetUrl) || "暂无内容"}
                className={styles.qrCode}
                status={qrLoading ? 'loading' : 'active'}
            />
        </div>
    </BaseDragableElement>);
};

export default observer(QRCodeRegion);
