import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './teacherChatModal.module.less';
import CloseIcon from '@/assets/img/cuowu.svg';
import SendDisableIcon from '@/assets/img/send_disable.svg';
import SendAbleIcon from '@/assets/img/send_able.svg';
import useChat from '@/hooks/useChat';
import ChatBubble from '../Element/AIChatRegion/ChatBubble';
import SpeechToText from '../SpeechToText/SpeechToText';
import { BlockTraceData } from '@/base/BlockData';
import { useStores } from '@/store/useStores';
import { ChatMessage } from '@/base/ChatMessage';
import { cloneDeep } from 'lodash';
import { ElementData } from '@/base/ElementData/ElementData';
import AIChatRegionContent from '@/base/ElementData/AIChatRegionContent';
import { containsVariablePattern, rewriteText } from '@/utils/utils';

interface TeacherChatModalProps {
    handleCloseChat: () => void;
    blockTraceData: BlockTraceData;
}

const TeacherChatModal: React.FC<TeacherChatModalProps> = ({
    handleCloseChat,
    blockTraceData }) => {

    const { lectureStore, ttsStore, commonStatusStore } = useStores();
    console.log('TeacherChatModal', lectureStore.teacherChat);
    const chatContainerRef = useRef<HTMLDivElement>(null);

    // 需要生成一个wrapper Elem
    const wrapperElem = new ElementData<AIChatRegionContent>({
        id: 10086,
        type: 110,
        content: lectureStore.teacherChat
    })
    const { inputValue, setInputValue, handleSend, handleKeyDown } = useChat(blockTraceData, wrapperElem);

    useEffect(() => {
        // 每次重置AI老师的system prompt
        let systemPrompt = lectureStore.commonTeacherSystemPrompt + lectureStore.originBlockList[lectureStore.currentBlockIndex].extras?.aiTeacherSystemPrompt;
        const newTeacherChat = cloneDeep(lectureStore.teacherChat);
        if (containsVariablePattern(systemPrompt)) {
            systemPrompt = rewriteText(systemPrompt);
        }
        newTeacherChat.systemPrompt = systemPrompt;
        lectureStore.setTeacherChat(newTeacherChat);
    }, [lectureStore.currentBlockIndex]); // 依赖于currentBlockIndex的变化

    useEffect(() => {
        if (chatContainerRef.current) {
            const { current: container } = chatContainerRef;
            // 使用scrollTo方法进行平滑滚动
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth' // 这个设置能使滚动行为变得平滑
            });
        }

    }, [lectureStore.teacherChat.chatList]); // 依赖于chat_list的变化

    return (
        <div className={styles.chatBox}>
            <div className={styles.head}>
                <div className={styles.titleBox}>
                    <div className={styles.point}></div>
                    <div className={styles.title}>与叶子老师对话中...</div>
                </div>
                <div className={styles.closeChat} onClick={handleCloseChat}>
                    <img src={CloseIcon} alt="close" />
                </div>
            </div>
            <div className={styles.aiChat} ref={chatContainerRef}>
                {lectureStore.teacherChat.chatList.map((item, index) => {
                    return (
                        <ChatBubble
                            key={index}
                            item={item}
                            role="teacher"
                            //如果是最后一个消息，并且ttsStore的isPlaying为true，则显示正在播放的图标
                            isThisMsgPlaying={index === lectureStore.teacherChat.chatList.length - 1
                                && (ttsStore.isPlaying || commonStatusStore.isFetching && wrapperElem.content.shouldDoTts && !ttsStore.userNeedPause)
                            }
                        />
                    )
                })}
            </div>
            <div className={styles.inputAreaBox}>
                <div className={styles.audioBtn}>
                    <SpeechToText setInputMessage={setInputValue} inputMessage={inputValue} />
                </div>
                <div className={styles.inputBox}>
                    <textarea
                        className={styles.inputArea}
                        placeholder="输入消息..."
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div >
                <div className={styles.sendBtn}>
                    <img
                        src={inputValue.trim() === '' ? SendDisableIcon : SendAbleIcon}
                        onClick={(e) => {
                            e.preventDefault();
                            handleSend(null, -1, -1);
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default observer(TeacherChatModal);