import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import styles from "./createBasicAgent.module.less";
import { BlockTraceData } from '@/base/BlockData';
import { ElementData } from '@/base/ElementData/ElementData';
import AIChatAgentRegionContent from '@/base/ElementData/AIChatAgentRegionContent';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { cloneDeep } from 'lodash';
import service from '@/services/axios';
import GoBackIcon from '@/assets/img/user_center/go_back.svg';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import InspirationIcon from '@/assets/img/inspiration.svg';
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import { generateMD5 } from '@/utils/utils';
import { createUserWork } from '@/api/userWork';
import ChatAgentInfo from '@/base/ShareData/ChatAgentInfo';
import ChatAgentIcon from '@/assets/img/chat_component/chat_agent_icon.svg';
import { ChatMessage } from '@/base/ChatMessage';

interface CreateBasicAgentProps {
    editType: string;
    blockTraceData: BlockTraceData;
    elementData: ElementData<AIChatAgentRegionContent>;
    setIsEditing: (value: boolean) => void;
}

interface FirstStepContentProps {
    agentRole: string;
    setAgentRole: (value: string) => void;
}

interface SecondStepContentProps {
    agentSkill: string;
    setAgentSkill: (value: string) => void;
}

interface ThirdStepContentProps {
    agentRule: string;
    setAgentRule: (value: string) => void;
}

const CreateBasicAgent: React.FC<CreateBasicAgentProps> = ({
    editType,
    blockTraceData,
    elementData,
    setIsEditing
}) => {
    const { lectureStore, userInfoStore } = useStores();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [agentRole, setAgentRole] = useState<string>('');
    const [agentSkill, setAgentSkill] = useState<string>('');
    const [agentRule, setAgentRule] = useState<string>('');

    const convertPromptToJson = () => {
        const data = {
            "角色": agentRole,
            "技能": agentSkill,
            "规范": agentRule
        };
        const prompt = JSON.stringify(data);
        return prompt;
    };

    const convertJsonToPrompt = (prompt: string) => {
        try {
            const data = JSON.parse(prompt);
            setAgentRole(data.角色);
            setAgentSkill(data.技能);
            setAgentRule(data.规范);
        } catch (error) {
            setAgentRole('');
            setAgentSkill('');
            setAgentRule('');
        }
    }

    useEffect(() => {
        if (editType === 'edit') {
            convertJsonToPrompt(elementData.content.systemPrompt);
        } else {
            setAgentRole(containsVariablePattern(elementData.content.agentRole) ? rewriteText(elementData.content.agentRole) : elementData.content.agentRole);
            setAgentSkill(containsVariablePattern(elementData.content.agentSkill) ? rewriteText(elementData.content.agentSkill) : elementData.content.agentSkill);
            setAgentRule(containsVariablePattern(elementData.content.agentRule) ? rewriteText(elementData.content.agentRule) : elementData.content.agentRule);
        }
    }, [elementData.content.systemPrompt, editType, elementData.content.agentRole, elementData.content.agentSkill, elementData.content.agentRule]);

    const steps = [
        {
            title: 'First',
            content: <FirstStepContent
                agentRole={agentRole}
                setAgentRole={setAgentRole}
            />,
        },
        {
            title: 'Second',
            content: <SecondStepContent
                agentSkill={agentSkill}
                setAgentSkill={setAgentSkill}
            />,
        },
        {
            title: 'Third',
            content: <ThirdStepContent
                agentRule={agentRule}
                setAgentRule={setAgentRule}
            />,
        },
    ];

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    }

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    }

    const genShareLink = async (content: AIChatAgentRegionContent) => {
        let shareContent = new ChatAgentInfo({
            systemPrompt: content.systemPrompt,
            headerText: content.headerText,
            agentAvatar: content.agentAvatar,
            presetMessageList: [new ChatMessage({
                type: 2,
                rawContent: "开启体验之旅",
                responseMethod: "gpt",
            })
            ],
            shareDescription: `${userInfoStore.userInfoData.name}创作的智能体`,
            shareScene: 'careerSimulator'
        });
        let concatenatedString = '';
        let timestamp = new Date().getTime();
        concatenatedString = `${userInfoStore.userInfoData.id}${shareContent.systemPrompt}${shareContent.headerText}${shareContent.agentAvatar}${shareContent.shareDescription}${shareContent.shareScene}${timestamp}`;
        const MD5 = generateMD5(concatenatedString);
        const shareLinkData = {
            id: 0,
            md5: MD5,
            user_id: userInfoStore.userInfoData.id,
            user_name: userInfoStore.userInfoData.name,
            work_type: "chatAgent",
            work_info: shareContent,
            create_time: 0
        };
        const shareLinkRes: any = await createUserWork(shareLinkData);
        if (shareLinkRes.status === 'success') {
            return `${import.meta.env.VITE_FE_URL}/share/${MD5}`;
        } else {
            return '';
        }

    }

    const handleFinish = async () => {
        console.log("convertPromptToJson", convertPromptToJson());
        if (agentRole.trim() === '' || agentSkill.trim() === '' || agentRule.trim() === '') {
            message.error('请填写完整');
            return;
        }

        const newChatAgentContent = new AIChatAgentRegionContent({
            width: elementData.content.width,
            height: elementData.content.height,
            positionX: elementData.content.positionX,
            positionY: elementData.content.positionY,
            chatList: elementData.content.chatList,
            systemPrompt: convertPromptToJson(),
            agentRole: agentRole,
            agentSkill: agentSkill,
            agentRule: agentRule,
            agentMode: 'basic',
            isCreatedChatAgent: true,
            roleLectureVar: elementData.content.roleLectureVar,
            skillLectureVar: elementData.content.skillLectureVar,
            ruleLectureVar: elementData.content.ruleLectureVar,
            agentLinkLectureVar: elementData.content.agentLinkLectureVar,
            headerText: "职业模拟器",
            isStream: true
        });

        if (elementData.content.agentLinkLectureVar) {
            newChatAgentContent.agentShareLink = await genShareLink(newChatAgentContent);
        }

        const newBlockTraceData = cloneDeep(blockTraceData);
        const currentElementData = newBlockTraceData.traceInfo.find(item => item.id === elementData.id);
        if (currentElementData) {
            currentElementData.content = newChatAgentContent;
        }

        try {
            const res: any = await service.put('/block_trace', newBlockTraceData);
            if (res.status === "success") {
                lectureStore.updateElement(blockTraceData.id, elementData.id, newChatAgentContent);
                if (editType === 'edit') {
                    setIsEditing(false);
                }
            }
        } catch (error) {
            console.log(error);
            message.error('创建失败');
        }
    }
    return (
        <div className={styles.createAgentContainer}>
            {
                elementData.content.isCreatedChatAgent && (
                    <div className={styles.goBack} onClick={() => setIsEditing(false)}>
                        <img src={GoBackIcon} />
                    </div>
                )
            }
            <div className={styles.stepsContentBox}>
                {steps[currentStep].content}
            </div>
            <div className={styles.stepsAction}>
                {currentStep > 0 && (
                    <Button onClick={() => prevStep()} className={styles.prevBtn}>
                        上一步
                    </Button>
                )}
                {currentStep < steps.length - 1 && (
                    <Button type="primary" onClick={() => nextStep()} className={styles.nextBtn}>
                        下一步
                    </Button>
                )}
                {currentStep === steps.length - 1 && (
                    <Button type="primary" onClick={() => handleFinish()} className={styles.nextBtn}>
                        {editType === 'edit' ? '更新' : '创建'}
                    </Button>
                )}
            </div>
        </div>
    )
}

export default observer(CreateBasicAgent);


const FirstStepContent: React.FC<FirstStepContentProps> = ({
    agentRole, setAgentRole
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 角色描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>
                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入角色描述"
                        value={agentRole}
                        onChange={(e) => setAgentRole(e.target.value)}
                    />
                    <div className={styles.btn}>
                        <SpeechToText
                            inputMessage={agentRole}
                            setInputMessage={setAgentRole}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const SecondStepContent: React.FC<SecondStepContentProps> = ({
    agentSkill, setAgentSkill
}) => {

    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 技能描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>
                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入技能描述"
                        value={agentSkill}
                        onChange={(e) => setAgentSkill(e.target.value)}
                    />
                    <div className={styles.btnArea}>
                        <div className={styles.btn}>
                            <SpeechToText
                                inputMessage={agentSkill}
                                setInputMessage={setAgentSkill}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ThirdStepContent: React.FC<ThirdStepContentProps> = ({
    agentRule, setAgentRule
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 规范描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>

                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入规范描述"
                        value={agentRule}
                        onChange={(e) => setAgentRule(e.target.value)}
                    />
                    <div className={styles.btnArea}>
                        <div className={styles.btn}>
                            <SpeechToText
                                inputMessage={agentRule}
                                setInputMessage={setAgentRule}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

