import React, { useEffect, useState, useRef } from 'react';
import styles from "./tokenizerRegion.module.less";
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import TokenizerRegionContent from '@/base/ElementData/TokenizerRegionContent';
import { Button, message } from 'antd';
import service from '@/services/axios';

const TokenizerRegion: React.FC<IntrinsicElementProps<TokenizerRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
}) => {
    const [inputValue, setInputValue] = useState('我爱北京天安门');
    const [tokensList, setTokensList] = useState<string[]>([]);
    const [tokenIdsList, setTokenIdsList] = useState<number[]>([]);
    const [tokenizerLoading, setTokenizerLoading] = useState(false);

    const handleGenerate = async () => {
        if (inputValue.trim() === '') {
            message.error('请输入内容');
            return;
        }
        try {
            setTokenizerLoading(true);
            const res: any = await service.post('/tokenize', null, {
                params: {
                    text: inputValue
                }
            });
            console.log(res);
            if (res.status === 'success') {
                setTokensList(res.data.tokens);
                setTokenIdsList(res.data.token_ids);
            } else {
                setTokensList([]);
                setTokenIdsList([]);
            }
            setTokenizerLoading(false);
        } catch (error) {
            setTokenizerLoading(false);
        }
    }

    const handleClear = () => {
        setInputValue('');
        setTokensList([]);
        setTokenIdsList([]);
    }

    const getColor = (index: number) => {
        const colors = ['#ffcccc', '#ccffcc', '#ccccff', '#ffccff', '#ccffff'];
        return colors[index % colors.length];
    };

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.tokenizerBox}>
                    <div className={styles.inputBox}>
                        <div className={styles.inputArea}>
                            <textarea
                                className={styles.input}
                                placeholder="请输入内容"
                                value={inputValue}
                                onChange={e => setInputValue(e.target.value)}
                            />
                        </div>
                        <div className={styles.btnBox}>
                            <Button
                                className={styles.btn}
                                onClick={handleClear}
                            >
                                清空
                            </Button>
                            <Button
                                className={styles.btn}
                                loading={tokenizerLoading}
                                onClick={handleGenerate}
                            >
                                切分token
                            </Button>
                        </div>
                    </div>
                    <div className={styles.tokenBox}>
                        <div className={styles.tokenTitle}>
                            结果:
                        </div>
                        <div className={styles.resultBox}>
                            <div className={styles.tokensList}>
                                {tokensList.map((token, index) => (
                                    <span
                                        key={`token-${index}`}
                                        className={styles.tokenItem}
                                        style={{ backgroundColor: getColor(index) }}
                                    >
                                        {token}
                                    </span>
                                ))}
                            </div>
                            <div className={styles.tokenIdsList}>
                                {tokensList.map((token, index) => (
                                    <div
                                        className={styles.tokenItemBox}
                                    >
                                        <span
                                            key={`token-${index}`}
                                            className={styles.tokenItemText}
                                        >
                                            {token}
                                        </span>
                                        <span
                                            key={`token-${index}`}
                                            className={styles.tokenItem}
                                            style={{ backgroundColor: getColor(index) }}
                                        >
                                            {tokenIdsList[index]}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default TokenizerRegion;