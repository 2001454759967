import React, { useEffect, useRef, useState } from 'react';
import styles from "./genCustomMusicRegion.module.less";
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import GenCustomMusicRegionContent from '@/base/ElementData/GenCustomMusicRegionContent';
import YinFuIcon from '@/assets/img/yinfu.svg';
import { Button, message, Select, Tooltip, QRCode } from 'antd';
import MultimediaAPI from '@/api/multimedia';
import { createSong, musicGenV2, addSongToAlbum } from '@/api/playgroundMusic';
import LLMCallRequestData from '@/base/LLMCallRequestData';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { requestLLMCallV2 } from '@/services/requestLLMCall';
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import MicrophoneIcon from '@/assets/img/playground/microphone.svg';
import { checkLyricsLength } from '@/utils/utils';
import AudioPlayer from '@/components/AudioPlayer/AudioPlayer';
import { BlockTraceData } from '@/base/BlockData';
import service from '@/services/axios';
import { cloneDeep } from 'lodash';
import MusicRhythmIcon from '@/assets/img/music_rhythm.svg';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import ShareIcon from '@/assets/img/playground/share.svg';
import { userShareObject } from '@/api/userLikeObject';
import SongInfo from '@/base/ShareData/SongInfo';
import { generateMD5 } from '@/utils/utils';
import { createUserWork } from '@/api/userWork';

const { Option } = Select;

interface GenCustomMusicRegionProps extends IntrinsicElementProps<GenCustomMusicRegionContent> {
    blockTraceData: BlockTraceData;
}

const GenCustomMusicRegion: React.FC<GenCustomMusicRegionProps> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
    blockTraceData
}) => {
    const { userInfoStore, lectureStore } = useStores();
    const api = new MultimediaAPI();
    const [inputTitle, setInputTitle] = useState(containsVariablePattern(elementData.content.songTitle) ? rewriteText(elementData.content.songTitle) : elementData.content.songTitle);
    const [inputLyric, setInputLyric] = useState(containsVariablePattern(elementData.content.songLyric) ? rewriteText(elementData.content.songLyric) : elementData.content.songLyric);
    const [inputDescription, setInputDescription] = useState(containsVariablePattern(elementData.content.songDescription) ? rewriteText(elementData.content.songDescription) : elementData.content.songDescription);
    const [inputStyle, setInputStyle] = useState(elementData.content.songStyle || "流行");
    const [songTitle, setSongTitle] = useState(elementData.content.songTitle);
    const [songLyric, setSongLyric] = useState(elementData.content.songLyric);
    const [songDescription, setSongDescription] = useState(elementData.content.songDescription);
    const [apiStatus, setApiStatus] = useState(0); // 0: not started, 1: generating,
    const [songCoverUrl, setSongCoverUrl] = useState(elementData.content.songCoverUrl);
    const [songUrl, setSongUrl] = useState(elementData.content.songUrl);
    const [isPlaying, setIsPlaying] = useState(false);
    const [formattedCurrentTime, setFormattedCurrentTime] = useState("00:00");
    const [formattedDuration, setFormattedDuration] = useState("00:00");
    const [qrUrl, setQrUrl] = useState('null');
    const [qrLoading, setQrLoading] = useState(false);
    const [songId, setSongId] = useState(0);

    useEffect(() => {
        setSongTitle(elementData.content.songTitle);
        setSongLyric(elementData.content.songLyric);
        setSongCoverUrl(elementData.content.songCoverUrl);
        setSongUrl(elementData.content.songUrl);
        setSongDescription(elementData.content.songDescription);
        setInputStyle(elementData.content.songStyle || "流行");
        setInputTitle(containsVariablePattern(elementData.content.songTitle) ? rewriteText(elementData.content.songTitle) : elementData.content.songTitle);
        setInputLyric(containsVariablePattern(elementData.content.songLyric) ? rewriteText(elementData.content.songLyric) : elementData.content.songLyric);
        setInputDescription(containsVariablePattern(elementData.content.songDescription) ? rewriteText(elementData.content.songDescription) : elementData.content.songDescription);
    }, [elementData.content.songTitle, elementData.content.songLyric, elementData.content.songCoverUrl, elementData.content.songUrl, elementData.content.songStyle]);

    let genreList = [
        { value: "流行", label: "流行" },
        { value: "民谣", label: "民谣" },
        { value: "电子", label: "电子" },
        { value: "摇滚", label: "摇滚" },
        { value: "儿歌", label: "儿歌" },
        { value: "情歌", label: "情歌" },
        { value: "古典", label: "古典" },
        { value: "爵士", label: "爵士" },
        { value: "嘻哈", label: "嘻哈" },
        { value: "节奏布鲁斯", label: "节奏布鲁斯" },
        { value: "说唱", label: "说唱" },
        { value: "金属", label: "金属" },
        { value: "蓝调", label: "蓝调" },
        { value: "古风", label: "古风" },
        { value: "中国风", label: "中国风" },
        { value: "戏曲", label: "戏曲" },
        { value: "校园", label: "校园" },
        { value: "Kpop", label: "Kpop" },
        { value: "ACG", label: "ACG" },
        { value: "宗教歌曲", label: "宗教歌曲" },
        { value: "Bossa Nova", label: "Bossa Nova" },
    ];
    //将生成的歌曲信息存入数据库
    const saveSong = async (name: string, lyrics: string, musicUrl: string,
        coverUrl: string, style: string) => {
        try {
            const songData = {
                id: 0,
                name: name,
                author_id: userInfoStore.userInfoData.id,
                lyrics: lyrics,
                music_url: musicUrl,
                cover_url: coverUrl,
                tags: style,
                play_count: 0,
                like_count: 0,
                update_time: 0
            };

            // 保存歌曲  
            const songResponse: any = await createSong(songData);
            if (songResponse.status === 'success') {
                console.log('save song success:', songResponse);

                // 将歌曲添加到专辑  
                const albumResponse: any = await addSongToAlbum(userInfoStore.userInfoData.id, songResponse.data.id);
                if (albumResponse.status === 'success') {
                    setSongId(songResponse.data.id);
                    setApiStatus(0);
                    message.success('歌曲创作完成，快去听听吧~');
                } else {
                    console.error('add song to album failed');
                }
            } else {
                console.error('save song failed');
            }
        } catch (error) {
            console.error('save song error:', error);
        }
    };
    const pollForSongUrl = async (taskID: string) => {
        let isPolling = false;
        let totalTry = 0;
        const intervalId = setInterval(async () => {
            if (isPolling) return;
            totalTry += 1;
            try {
                isPolling = true;
                const res: any = await api.getSongByTaskId(taskID);
                console.log('getSongByTaskId', res);
                if (res && res['status'] === 'success' && res['data']['song_status'] === 'done') {
                    console.log('song is done');
                    clearInterval(intervalId);
                    saveSong(res['data']['song']['title'], res['data']['song']['lyrics'].replace(/\[.*?\]/g, ' '), res['data']['song']['audio_url'], res['data']['song']['song_cover_url'], "");
                    setSongUrl(res['data']['song']['audio_url']);
                    setSongCoverUrl(res['data']['song']['song_cover_url']);
                    setSongTitle(res['data']['song']['title']);
                    setSongLyric(res['data']['song']['lyrics'].replace(/\[.*?\]/g, ' '));
                    setApiStatus(0);
                    const newBlockTraceData = cloneDeep(blockTraceData);
                    const currentElementData = newBlockTraceData.traceInfo.find(item => item.id === elementData.id);
                    if (currentElementData) {
                        (currentElementData.content as GenCustomMusicRegionContent).songCoverUrl = res['data']['song']['song_cover_url'];
                        (currentElementData.content as GenCustomMusicRegionContent).songTitle = res['data']['song']['title'];
                        (currentElementData.content as GenCustomMusicRegionContent).songLyric = res['data']['song']['lyrics'].replace(/\[.*?\]/g, ' ');
                        (currentElementData.content as GenCustomMusicRegionContent).songUrl = res['data']['song']['audio_url'];
                        (currentElementData.content as GenCustomMusicRegionContent).songStyle = inputStyle;
                        (currentElementData.content as GenCustomMusicRegionContent).songDescription = inputDescription;
                    }
                    lectureStore.updateElement(blockTraceData.id, elementData.id, currentElementData!.content);
                    service.put('/block_trace', newBlockTraceData);
                } else if (res && res['status'] === 'success' && res['data']['song_status'] === 'processing') {
                    console.error('song is still being generated');
                } else {
                    console.error('song pool request error');
                }
            } catch (error) {
                // setApiStatus(0);
                //clearInterval(intervalId);
                //console.error('getSongById:', error);
            } finally {
                isPolling = false; // 请求完成后，标志位恢复，允许下一次请求
                console.log('totalTry:', totalTry);
                if (totalTry >= 100) {
                    clearInterval(intervalId);
                    handleError();
                }
            }
        }, 3000); // 每3秒轮询一次  
    };

    const handleGenerate = async () => {
        if (elementData.content.elementMode === 'song_making') {
            if (inputTitle.trim() === "") {
                message.warning('请输入歌名~');
                return;
            }
            if (inputLyric.trim() === "") {
                message.warning('请输入歌词~');
                return;
            }
            if (!checkLyricsLength(inputLyric)) {
                message.warning('歌词长度过长，请删减~');
                return;
            }
            if (inputStyle.trim() === "") {
                message.warning('请输入曲风~');
                return;
            }
        } else {
            if (inputDescription.trim() === "") {
                message.warning('请输入描述~');
                return;
            }
        }
        if (apiStatus === 1) {
            message.warning('正在生成中，请稍等');
            return;
        }
        setApiStatus(1);
        const requestData: any = {
            request_id: "string",
        };

        if (elementData.content.elementMode === 'song_making') {
            requestData['title'] = inputTitle;
            requestData['lyrics'] = inputLyric;
            requestData['genre'] = inputStyle;
        } else {
            requestData['description'] = inputDescription;
            requestData['make_instrumental'] = true;
        }

        try {
            const res: any = await api.nfGenSong(requestData);
            if (res && res['status'] == 'success') {
                const taskID = res['task_id'];
                pollForSongUrl(taskID);
            } else {
                setApiStatus(0);
                message.error('无法获取歌曲生成的task_id');
            }
        } catch (error) {
            console.error('genMusic:', error);
            handleError();
        }
    };

    const handleError = () => {
        message.error('生成歌曲失败，请重试~');
        setApiStatus(0);
    };

    function formatTime(timeInSeconds: number) {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);

        // 手动补零处理
        const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;

        return `${minutesStr}:${secondsStr}`;
    }

    const clickShareIcon = async () => {
        setQrLoading(true);
        const data = {
            user_id: userInfoStore.userInfoData.id,
            object_type: "music",
            object_id: songId,
            channel: "course",
            extras: {},
            create_time: 0
        };
        try {
            const res = await userShareObject(data);
            if (res.status === 'success') {
                generateShareInfo();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const generateShareInfo = async () => {
        const songData = new SongInfo({
            id: songId,
            name: songTitle,
            authorId: userInfoStore.userInfoData.id,
            authorName: userInfoStore.userInfoData.name,
            lyrics: songLyric,
            musicUrl: songUrl,
            coverUrl: songCoverUrl,
            tags: inputStyle,
        });
        const concatenatedString = `${songData.authorId}${songData.id}${songData.name}${songData.lyrics}${songData.musicUrl}${songData.coverUrl}${songData.tags}`;
        const MD5 = generateMD5(concatenatedString);
        const shareLinkData = {
            id: 0,
            md5: MD5,
            user_id: userInfoStore.userInfoData.id,
            user_name: userInfoStore.userInfoData.name,
            work_type: "music",
            work_info: songData,
            create_time: 0
        };
        const shareLinkRes: any = await createUserWork(shareLinkData);
        if (shareLinkRes.status === 'success') {
            setQrUrl(`${import.meta.env.VITE_FE_URL}/share/${MD5}`);
            setQrLoading(false);
        } else {
            setQrLoading(false);
            message.error('生成分享链接失败');
        }
    };


    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.genCustomMusicBox}>
                    <div className={styles.customGen}>
                        <div className={styles.customHeader}>
                            <div className={styles.boboMusicIcon}>
                                <img src={YinFuIcon} />
                            </div>
                            <div className={styles.headerTitle}>歌曲大模型</div>
                        </div>
                        {
                            elementData.content.elementMode === 'song_making' && (
                                <>
                                    <div className={styles.songTitle}>
                                        <input
                                            className={styles.input}
                                            value={inputTitle}
                                            onChange={(e) => setInputTitle(e.target.value)}
                                            placeholder='请输入歌名'
                                        />
                                        <div className={styles.magicGenBtn}>
                                            <SpeechToText
                                                setInputMessage={setInputTitle}
                                                inputMessage={inputTitle}
                                                microphoneIcon={MicrophoneIcon}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.songLyric}>
                                        <textarea
                                            className={styles.textarea}
                                            value={inputLyric}
                                            onChange={(e) => setInputLyric(e.target.value)}
                                            placeholder='请输入歌词'
                                        />
                                        <div className={styles.magicGenBtn}>
                                            <SpeechToText
                                                setInputMessage={setInputLyric}
                                                inputMessage={inputLyric}
                                                microphoneIcon={MicrophoneIcon}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.songStyle}>
                                        <Select
                                            className={styles.select}
                                            value={inputStyle}
                                            onChange={(value) => setInputStyle(value)}
                                            placeholder="请选择曲风"
                                        >
                                            {genreList.map((item) => (
                                                <Option key={item.value} value={item.value}>{item.label}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                </>
                            )
                        }
                        {
                            elementData.content.elementMode === 'melody_making' && (
                                <>
                                    <div className={styles.songDescription}>
                                        <textarea
                                            className={styles.textarea}
                                            value={inputDescription}
                                            onChange={(e) => setInputDescription(e.target.value)}
                                            placeholder='请输入描述'
                                        />
                                        <div className={styles.magicGenBtn}>
                                            <SpeechToText
                                                setInputMessage={setInputDescription}
                                                inputMessage={inputDescription}
                                                microphoneIcon={MicrophoneIcon}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        <Button
                            className={styles.genMusicButton}
                            onClick={handleGenerate}
                            loading={apiStatus === 1}
                            icon={<img src={YinFuIcon} />}
                        >
                            {["开始创作", "创作中（大概需要2~3分钟）"][apiStatus]}
                        </Button>
                    </div>
                    <div className={styles.musicPlayerBox}>
                        {songUrl && (
                            <div className={styles.autoVerticalAudioBox}>
                                <div className={styles.smallCover}>
                                    {songCoverUrl ? (
                                        <img
                                            src={songCoverUrl}
                                            alt="song cover"
                                            className={styles.coverImg} />
                                    ) : (
                                        <div className={styles.defaultCover}>
                                            <img src={YinFuIcon} />
                                        </div>
                                    )}
                                    {
                                        isPlaying && (
                                            <div className={styles.musicRhythm}>
                                                <img src={MusicRhythmIcon} className={styles.musicRhythmIcon} />
                                            </div>
                                        )
                                    }
                                    <div className={styles.playBtnBox}>
                                        <div className={styles.playBtn}>
                                            <AudioPlayer
                                                audioSrc={songUrl}
                                                playerType="small"
                                                isPlaying={isPlaying}
                                                setIsPlaying={setIsPlaying}
                                                currentAudioEleId={elementData.id}
                                                onTimeUpdate={(currentTime: number) => {
                                                    setFormattedCurrentTime(formatTime(currentTime));
                                                }}
                                                onDurationSet={(duration: number) => {
                                                    setFormattedDuration(formatTime(duration));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.title}>
                                    {songTitle}
                                </div>
                                <div className={styles.time}>
                                    <div className={styles.timeBar}>
                                        {formattedCurrentTime} / {formattedDuration}
                                    </div>
                                    <div className={styles.shareBtn}>
                                        <div className={styles.shareSong} onClick={clickShareIcon}>
                                            <Tooltip
                                                color='white'
                                                trigger='click'
                                                title={<QRCode
                                                    value={qrUrl} size={150}
                                                    status={qrLoading ? "loading" : "active"} />
                                                }

                                            >
                                                <img src={ShareIcon} alt="" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!songUrl && (
                            <div className={styles.noSong}>
                                <div className={styles.noSongText}>
                                    {`快去创作一首属于你的${elementData.content.elementMode==='song_making'?'歌曲':'旋律'}吧~`}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default observer(GenCustomMusicRegion);