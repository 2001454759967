import { ElementContent } from './ElementData';

class CustomGamesRegionContent extends ElementContent {
    originalCode: string;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 850,
        height = 450,
        originalCode = "",
    }: Partial<CustomGamesRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex', 'originalCode'];
        this.originalCode = originalCode;
    }
};

export default CustomGamesRegionContent;