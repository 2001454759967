import DataDefinition from "./DataDefinition";
import { genObjectIDbyTS } from '../utils/id_generator';

class DocumentData extends DataDefinition {
    id: number;
    docName: string;
    docContent: string;
    docUrl: string;

    constructor({
        id = genObjectIDbyTS(),
        docName = '',
        docContent = '',
        docUrl = ''

    }: Partial<DocumentData> = {}) {
        super();
        this.allowedFields = ['id','docName', 'docContent', 'docUrl'];
        this.id = id;
        this.docName = docName;
        this.docContent = docContent;
        this.docUrl = docUrl;
    }
};

export default DocumentData;
