import DataDefinition from "../DataDefinition";
import { ChatMessage } from '../ChatMessage';

class ChatAgentInfo extends DataDefinition {
    systemPrompt: string;
    headerText: string;
    agentAvatar: string;
    presetMessage: string;
    shareDescription: string;
    presetMessageList: ChatMessage[];
    shareScene: string; //分享场景,是答题还是职业模拟,默认是答题

    constructor({
        systemPrompt = '',
        headerText = '',
        agentAvatar = '',
        presetMessage = '',
        shareDescription = '',
        presetMessageList = [],
        shareScene = 'answerQuestion'
    }: Partial<ChatAgentInfo> = {}) {
        super();
        this.allowedFields = ['systemPrompt', 'headerText', 'agentAvatar', 'presetMessage', 'shareDescription',
            'presetMessageList', 'shareScene'];
        this.systemPrompt = systemPrompt;
        this.headerText = headerText;
        this.agentAvatar = agentAvatar;
        this.presetMessage = presetMessage;
        this.shareDescription = shareDescription;
        this.presetMessageList = presetMessageList;
        this.shareScene = shareScene;
    }
}

export default ChatAgentInfo;