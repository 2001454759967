import PropTypes from 'prop-types';
import { InputNumber, Switch, Select, Button } from 'antd';
import styles from "./advancedButtonRegionModifier.module.less";
import TextRegionModifier from '../TextRegionModifier/TextRegionModifier';
import ButtonRegionModifier from './ButtonRegionModifier';
import ImageRegionModifier from '../ImageRegionModifier/ImageRegionModifier';
import UploadMedia from '@/components/UploadMedia/UploadMedia';
import useCommonFunction from '@/hooks/useCommonFunction';
import MediaFileSelector from '@/components/MediaFileSelector/MediaFileSelector';
import { useState } from 'react';
import MultimediaAPI from '@/api/multimedia';
import { ElementData } from '@/base/ElementData/ElementData';
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';

const { Option } = Select;

interface AdvancedButtonRegionModifierProps {
    focusedItem: ElementData<AdvancedButtonRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    onOptionChange: (id: number, key: string, btnId: number, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
    onSaveAsTemplate?: (itemId: number, url: string) => void;
};



const AdvancedButtonRegionModifier: React.FC<AdvancedButtonRegionModifierProps>= ({
    focusedItem,
    onInputChange,
    onOptionChange,
    changeZIndex,
    onSaveAsTemplate,
}) => {
    const ttsAPI = new MultimediaAPI();
    const { generateSelectOptionsData, generateElementIdOptions } = useCommonFunction();
    const optionsData = generateSelectOptionsData();
    const elementIdOptions = generateElementIdOptions();
    const [fileSelectorOpen, setFileSelectorOpen] = useState(false);
    const [isGeneratingNewWordAudio, setIsGeneratingNewWordAudio] = useState(false);

     // 生成新的教师说的音频
     const generateNewWordAudio = async (newWords: string) => {
        setIsGeneratingNewWordAudio(true);
        try {
            const res = await ttsAPI.genVoiceAndUpload({ text: newWords }, 'tts');
            changeParams('audioUrl', res.file_url);
        } catch (error) {
            console.error('Error generating audio:', error);
        } finally {
            setIsGeneratingNewWordAudio(false);
        }
    }

    const changeFuncName = (value: string) => {
        const newFunc = { ...focusedItem.content.onclickFunc, funcName: value };
        onInputChange(focusedItem.id, 'onclickFunc', newFunc);
    }

    const changeParams = (paramName: string, value: any) => {
        const newParams = { ...focusedItem.content.onclickFunc?.funcParams, [paramName]: value };
        const newFunc = { ...focusedItem.content.onclickFunc, funcParams: newParams };
        onInputChange(focusedItem.id, 'onclickFunc', newFunc);
    }

    // 保存为模板
    const successCallBack = (url: string, itemId: number) => {
        onSaveAsTemplate!(itemId, url);

    };

    const propsUpload = {
        itemId: focusedItem.id,
    };


    return (
        <div>
            <UploadMedia
                successCallBack={successCallBack}
                icon={null}
                btn_text="存为模板 (先上传Thumbnail)"
                accept_str="image/png,image/jpg,image/jpeg,image/gif,image/svg"
                dir="images"
                isMultiple={false}
                uploadProps={propsUpload}
            />
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否展示播放音频图标</div>
                <Switch
                    checked={focusedItem.content.showAudioIcon}
                    onChange={value => onInputChange(focusedItem.id, 'showAudioIcon', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否自动播放(从隐藏到显示会触发)</div>
                <Switch
                    checked={focusedItem.content.autoPlay}
                    onChange={value => onInputChange(focusedItem.id, 'autoPlay', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>绑定函数</div>
                <Select
                    value={focusedItem.content.onclickFunc?.funcName}
                    onChange={value => changeFuncName(value)}
                    className={styles.functionSelect}
                >
                    {optionsData.map(option => (
                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                    ))}
                </Select>
            </div>
            {focusedItem.content.onclickFunc &&
                (focusedItem.content.onclickFunc?.funcName === 'showSpecifiedElement' ||
                    focusedItem.content.onclickFunc?.funcName === 'showSpecifiedElementAndHide' ||
                    focusedItem.content.onclickFunc?.funcName === 'changeTeacherWords') && (
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>elementId参数</div>
                        <Select
                            value={focusedItem.content.onclickFunc?.funcParams?.elementId}
                            onChange={value => changeParams('elementId', value)}
                            className={styles.functionSelect}
                        >
                            {elementIdOptions?.map(option => (
                                <Option key={option.value} value={option.value}>{option.label}</Option>
                            ))}
                        </Select>
                    </div>
                )}
                {
                focusedItem.content?.onclickFunc?.funcName === 'changeTeacherWords' && (
                    <>
                        <div className={styles.divVerticalLayout}>
                            <label>参数:new teacher words</label>
                            <textarea
                                value={focusedItem.content.onclickFunc?.funcParams?.newWords}
                                onChange={e => changeParams('newWords', e.target.value)}
                                className={styles.textarea_small}
                            />
                        </div>
                        <div className={styles.divVerticalLayout}>
                            <label>参数:audio url</label>
                            <Button
                                onClick={() => generateNewWordAudio(focusedItem.content?.onclickFunc?.funcParams?.newWords)}
                                loading={isGeneratingNewWordAudio}
                            >
                                生成音频
                            </Button>
                        </div>
                        <div className={styles.divVerticalLayout}>
                            <label>当前参数音频的链接:</label>
                            {focusedItem.content?.onclickFunc?.funcParams?.audioUrl ? focusedItem.content?.onclickFunc?.funcParams?.audioUrl : "暂无"}
                        </div>
                    </>
                )
            }
            {focusedItem.content?.onclickFunc?.funcName === 'navigate' && (
                <div>
                    <label>url</label>

                    <textarea
                        value={focusedItem.content.onclickFunc.funcParams?.url}
                        onChange={e => changeParams('url', e.target.value)}
                        className={styles.textareaVar} />
                </div>
            )}
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>element是否展示</div>
                <Switch
                    checked={focusedItem.content.visible}
                    onChange={value => onInputChange( focusedItem.id, 'visible', value)}
                />
            </div>
            <div>
                <label>按钮点击音效</label>
                <Button type='primary' onClick={() => setFileSelectorOpen(true)}>选择音效</Button>
                <MediaFileSelector
                    mediaType="audios"
                    afterSelectionCallback={url => onInputChange(focusedItem.id, 'clickSoundUrl', url)}
                    fileSelectorOpen={fileSelectorOpen}
                    setFileSelectorOpen={setFileSelectorOpen}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
            </div>
            <hr />
            <div>
                <h2>Text Regions</h2>
                <hr />
                {focusedItem.content.children.textRegions.map((textRegion, index) => (
                    <div key={textRegion.id}>
                        <h3>Text region {index + 1}</h3>
                        <TextRegionModifier
                            focusedItem={textRegion}
                            onInputChange={onInputChange}
                            changeZIndex={changeZIndex}
                        />
                    </div>
                ))}
            </div>
            <hr />
            <div>
                <h2>Image Regions</h2>
                <hr />
                {focusedItem.content.children.imgRegions.map((imgRegion, index) => (
                    <div key={imgRegion.id}>
                        <h3>image regions {index + 1} </h3>
                        <ImageRegionModifier
                            focusedItem={imgRegion}
                            onInputChange={onInputChange}
                            changeZIndex={changeZIndex}
                        />
                    </div>
                ))}
            </div>
            <div>
                <h2>Buttons</h2>
                <hr />
                {focusedItem.content.children.buttons.map((btn, index) => (
                    <div key={btn.id}>
                        <h3>button {index + 1} </h3>
                        <ButtonRegionModifier
                            focusedItem={btn}
                            onInputChange={onInputChange}
                            changeZIndex={changeZIndex}
                        />
                    </div>
                ))}
            </div>
            <hr />
        </div>
    )
}
export default AdvancedButtonRegionModifier;