import { ElementContent } from './ElementData';

class WordCoordinateRegionContent extends ElementContent {

    constructor({
        positionX = 0,
        positionY = 0,
        width = 850,
        height = 500,
    }: Partial<WordCoordinateRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex'];
    }
};

export default WordCoordinateRegionContent;