import { ElementContent } from './ElementData';

class WordGeneratorRegionContent extends ElementContent {
    type: string;
    defaultText: string;
    temperature: number;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 700,
        height = 500,
        type = "basic",//basic,selectable
        defaultText = "我喜欢吃",
        temperature = 1.4,
    }: Partial<WordGeneratorRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex', 'type', 'defaultText', 'temperature'];
        this.type = type;
        this.defaultText = defaultText;
        this.temperature = temperature;
    }
};

export default WordGeneratorRegionContent;