import { ElementContent } from './ElementData';
import { ChatMessage } from '../ChatMessage';
import { PromptSetVarConfig } from './UtilTypes';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import { userInfoStore, UserInfoStore } from '@/store/userInfoStore';
import DocumentData from '../DocumentData';

class AIChatRegionContent extends ElementContent {
    backgroundColor: string;
    showInputArea: boolean;
    systemPrompt: string;
    useContext: boolean;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ChatMessage)
    chatList: ChatMessage[];
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ChatMessage)
    presetMessages: ChatMessage[];
    finishedPresetIndex: number;
    readPresets: boolean;
    needFullAnswer: boolean;
    shouldDoTts: boolean;
    chatTools: string;
    targetVarsStr: string;
    userMessageAvatar: string;
    ttsVoice: string;
    numMsgToContinue: number;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', PromptSetVarConfig)
    postProcessHandlers: PromptSetVarConfig[];
    showFirstMsgAfterTeacherSpeak: boolean;
    shouldReadQuestion: boolean = false;//发送时是否需要读问题
    // mode 0： 正常的AIChat组件
    // mode 1:  用于侧边TeacherChat
    mode: number;
    //组件标题部分
    titleContent: string;
    titleColor: string;
    titleFontSize: number;
    //playground部分用到的
    agentName: string;
    agentAvatar: string;
    headerText: string;
    isStream: boolean = false;
    knowledgeBaseDoc = "";
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', DocumentData)
    documentList: DocumentData[];//知识库文档列表
    currentDocIndex: number;//当前用的第几个文档
    numMsgToDisableInput: number; //发送几条消息后禁止输入
    isPresetAgent: boolean = false;//是否预设智能体，游乐场用到
    canAdjustTemperature: boolean = false;//是否展示temperature调节
    llmVendor: string;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 810,
        height = 440,
        backgroundColor = "#ffffff",
        showInputArea = false,
        systemPrompt = "",
        useContext = true,
        chatList = [],
        presetMessages = [],
        finishedPresetIndex = -1,
        readPresets = true,
        needFullAnswer = true,
        shouldDoTts = true,
        chatTools = "",
        targetVarsStr = "",
        userMessageAvatar = "user",
        ttsVoice = "BV033_streaming",
        numMsgToContinue = 1,
        postProcessHandlers = [],
        showFirstMsgAfterTeacherSpeak = false,
        shouldReadQuestion = false,
        mode = 0,
        titleContent = "大语言模型对话",
        titleColor = "#000000",
        titleFontSize = 20,
        agentName = "",
        agentAvatar = "",
        headerText = "",
        isStream = false,
        knowledgeBaseDoc = "",
        documentList = [],
        currentDocIndex = 0,
        numMsgToDisableInput = 0,
        isPresetAgent = false,
        canAdjustTemperature = false,
        llmVendor = "",
    }: Partial<AIChatRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex', 'backgroundColor',
            'showInputArea', 'systemPrompt', 'useContext', 'chatList', 'presetMessages', 'finishedPresetIndex',
            'readPresets', 'needFullAnswer', 'shouldDoTts', 'chatTools', 'targetVarsStr', 'userMessageAvatar',
            'ttsVoice', 'numMsgToContinue', 'postProcessHandlers', 'showFirstMsgAfterTeacherSpeak', 'shouldReadQuestion',
            'mode', 'titleContent', 'titleColor', 'titleFontSize', 'agentName', 'agentAvatar', 'headerText', 'isStream',
            'knowledgeBaseDoc', 'documentList', 'currentDocIndex', 'numMsgToDisableInput', 'isPresetAgent', 'canAdjustTemperature',
            'llmVendor'];
        this.backgroundColor = backgroundColor;
        this.showInputArea = showInputArea;
        this.systemPrompt = systemPrompt;
        this.useContext = useContext;
        this.chatList = chatList;
        this.presetMessages = presetMessages;
        this.finishedPresetIndex = finishedPresetIndex;
        this.readPresets = readPresets;
        this.needFullAnswer = needFullAnswer;
        this.shouldDoTts = shouldDoTts;
        this.chatTools = chatTools;
        this.targetVarsStr = targetVarsStr;
        this.userMessageAvatar = userMessageAvatar;
        this.ttsVoice = ttsVoice;
        this.numMsgToContinue = numMsgToContinue;
        this.postProcessHandlers = postProcessHandlers;
        this.showFirstMsgAfterTeacherSpeak = showFirstMsgAfterTeacherSpeak;
        this.shouldReadQuestion = shouldReadQuestion;
        this.mode = mode;
        this.titleContent = titleContent;
        this.titleColor = titleColor;
        this.titleFontSize = titleFontSize;
        this.agentName = agentName;
        this.agentAvatar = agentAvatar;
        this.headerText = headerText;
        this.isStream = isStream;
        this.knowledgeBaseDoc = knowledgeBaseDoc;
        this.documentList = documentList;
        this.currentDocIndex = currentDocIndex;
        this.numMsgToDisableInput = numMsgToDisableInput;
        this.isPresetAgent = isPresetAgent;
        this.canAdjustTemperature = canAdjustTemperature;
        this.llmVendor = llmVendor;
    }

    getReadableContent(): string {
        let content: string = "";
        for (let i = 0; i < this.chatList.length; i++) {
            if (this.chatList[i].type === 1) {
                content += "AI说：“";
            } else if (this.chatList[i].type === 2 || this.chatList[i].type === 3) {
                if (userInfoStore.userInfoData.name) {
                    content += userInfoStore.userInfoData.name + "说：“";
                } else {
                    content += "用户说：“";
                }
            }
            if (containsVariablePattern(this.chatList[i].rawContent)) {
                content += rewriteText(this.chatList[i].rawContent) + "”\n\n";
            } else {
                content += this.chatList[i].rawContent + "”\n\n";
            }
        }
        return content;
    }
};

export default AIChatRegionContent;